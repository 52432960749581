<template>
    <input type="range" min="0" max="100"
           :value="percentage"
           @mousedown="onMouseDown" @mouseup="onMouseUp" @change="onChange">
</template>

<script>
    export default {
        props: ['percentage'],
        methods: {
            onChange(event) {
                this.$emit('change', event.target.value);
            },
            onMouseDown() {
                this.$emit('mousedown');
            },
            onMouseUp() {
                this.$emit('mouseup');
            }
        }
    };
</script>
