import Vue from 'vue';
import LookAndFeel from './look-and-feel.vue';

export default {
    load(branding) {
        return new Vue({
            el: '#lookAndFeelApp',
            template: `<look-and-feel :branding="branding"></look-and-feel>`,
            data: {
                branding
            },
            components: { LookAndFeel }
        });
    }
};
