<template>
    <search-box @search="search">
        <div class="form" slot="filters">
            <h6>{{capitalize(title)}}</h6>
            <div class="cform__group cform__group--no-margin">
                <div class="grid">
                    <div class="col-1-2">
                        <label>
                            <span class="cform__label">{{messages.from}}</span>
                            <span class="cform__input-group">
                            <input type="text" v-model="amount_from">
                        </span>
                        </label>
                    </div>
                    <div class="col-1-2">
                        <label>
                            <span class="cform__label">{{messages.to}}</span>
                            <span class="cform__input-group">
                            <input type="text" v-model="amount_to">
                        </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </search-box>
</template>

<script>
    import SearchBox from './search-box.vue';
    import { _T } from '../globals';

    export default {
        props: ['title'],
        components: { SearchBox },
        data() {
            return {
                messages: {
                    from: _T('from_to_from'),
                    to: _T('from_to_to')
                },
                amount_from: '',
                amount_to: ''
            };
        },
        methods: {
            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            search(query) {
                this.$emit('search', query, {
                    amount: {
                        from: this.amount_from,
                        to: this.amount_to
                    }
                });
            }
        }
    };
</script>
