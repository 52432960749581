import Bus from '~phoenix/js/bus';
import UserRepository from '~phoenix/js/models/user/user-repository';

export default {
    show() {
        const globalContract = UserRepository.getGlobalContract();
        const storedContract = UserRepository.getStoredContract();

        if (globalContract.isEmpty()) {
            return;
        }

        if (globalContract.equals(storedContract)) {
            return;
        }

        UserRepository.getContract(globalContract.user_id)
            .then((contract) => {
                if (!contract.equalVersions(globalContract)) {
                    Bus.$emit('open.global.modal', 'terms');
                } else {
                    UserRepository.storeContract(globalContract);
                }
            });
    },

    accept() {
        UserRepository.acceptTerms()
            .then((contract) => {
                Bus.$emit('open.global.modal', 'terms-end');
                UserRepository.storeContract(contract);
            });
    }
};
