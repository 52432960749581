/**
 * Simple Autocomplete.js
 * Use: <div id=[YourRootId]></div>
 * All arguments are mandatory.
 *
 * @param object htmlOptions: (Element root , string placeHolderText).
 * @function renderOption: Use to render each of the options from the response.
 * @function onOptionSelect: What to do when user clicks the above option.
 * @function getInputValue: Get the desired value to the input element.
 * @function fetchData:[Promise] Provide the full control of getting your data.
 *
 * @constructor
 */
const Autocomplete = ({
    htmlOptions,
    renderOption,
    onOptionSelect,
    getInputValue,
    fetchData
}) => {

    const WAITING_TIME = 1500;
    const NOT_FOUND_TIME = 3000;
    const { root, placeholderText } = htmlOptions;

    let isOnAjax = false;

    try {
        root.innerHTML = `
        <div class="inputcontainer">
            <input placeholder="${placeholderText}" class="input" />
        </div>

        <div class="dropdown">
            <div class="dropdown-content autocomplete-results"></div>
        </div>
    `;
    } catch (e) {
        throw new Error('Autocomplete root element not found.');
    }

    if (!root.classList.contains('autocomplete')) {
        root.classList.add('autocomplete');
    }

    const input = root.querySelector('input');
    const resultsWrapper = root.querySelector('.autocomplete-results');

    const toggleBusyState = () => {
        isOnAjax = !isOnAjax;
        toggleDisabled();
        toggleLoader();
    }

    const toggleLoader = () => {

        const loaderContainer = root.getElementsByClassName('loader-container-js');
        const inputContainer = root.getElementsByClassName('inputcontainer')[0];

        if (loaderContainer.length === 0) {
            const loaderDiv = document.createElement('div');
            loaderDiv.classList.add('icon-container', 'loader-container-js');
            const icon = document.createElement('i');
            icon.classList.add('loader');
            loaderDiv.appendChild(icon);
            inputContainer.append(loaderDiv);
        } else {
            inputContainer.removeChild(loaderContainer[0]);
        }
    }

    const toggleDisabled = () => {
        input.disabled = !input.disabled;
    }

    const showNotFound = () => {
        resultsWrapper.style.display = 'block';
        const option = document.createElement('a');
        option.classList.add('dropdown-item');
        option.innerHTML = 'No results Found!';
        resultsWrapper.appendChild(option);
        setTimeout(() => {
            resultsWrapper.style.display = 'none';
        }, NOT_FOUND_TIME);
    }

    const handle = (promise) => {
        return promise
            .then(data => ([data, undefined]))
            .catch(error => [undefined, error]);
    }

    const onInput = async ({target}) => {

        resultsWrapper.innerHTML = '';

        if (!target.value) {

            root.dispatchEvent(
                new CustomEvent('au-clear', {
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                    detail:{input}
                })
            );

            return;
        }
        toggleBusyState();
        const [items, itemsErr] = await handle(fetchData(target.value));

        if (itemsErr) {
            toggleBusyState();

            return;
        }

        toggleBusyState();

        if (!items.length) {
            showNotFound();

            return;
        }

        resultsWrapper.style.display = 'block';

        for (let item of items) {
            const option = document.createElement('a');

            option.classList.add('dropdown-item');
            option.innerHTML = renderOption(item);

            option.addEventListener('click', (e) => {
                e.preventDefault();
                input.value = getInputValue(item);
                onOptionSelect(item);
                resultsWrapper.style.display = 'none';
            });

            resultsWrapper.appendChild(option);
        }
    };

    input.addEventListener('input', debounce(onInput, WAITING_TIME));
    document.addEventListener('click', event => {

        if (!root.contains(event.target) && !isOnAjax) {
            resultsWrapper.style.display = 'none';

            root.dispatchEvent(
                new CustomEvent('au-close', {
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                    detail:{input}
                })
            );
        }
    });
}
const debounce = (func, delay = 1000) => {
    let timeoutId;

    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func.apply(null, args);
        }, delay)
    }
}

export default Autocomplete;

