<style lang="scss">
    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        opacity: 0.8;
        z-index: 5;
    }

    .loading-overlay__icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
        width: 32px;
        height: 32px;
        fill: #C3C4BE;
        animation: spinner 1s infinite steps(8);

        &.loading-overlay__icon--small {
            margin-top: -8px;
            margin-left: -8px;
            width: 16px;
            height: 16px;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
</style>

<template>
    <div class="loading-overlay">
        <spinner-icon viewBox="0 0 32 32" class="loading-overlay__icon" :class="classObject"></spinner-icon>
    </div>
</template>

<script>
    import SpinnerIcon from '../../svg/spinner.svg';

    export default {
        props: ['type'],
        components: { SpinnerIcon },
        computed: {
            classObject() {
                return {
                    'loading-overlay__icon--small': this.type === 'small'
                };
            }
        }
    };
</script>
