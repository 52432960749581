import ObjectFilter from '../../helpers/object-filter';
import TrackRepository from '../track/track-repository';
import AlbumRepository from '../album/album-repository';
import ReleaseMetadataTrack from '../release-metadata-track/release-metadata-track';
import ArtistsRoles from '../artists-roles';
import InheritedExplicitLanguage from '../explicit-language/inherited-explicit-language';

export default class ReleaseMetadataAlbum {
    constructor(data) {
        this.info = ObjectFilter.omitFields(data, ['artists', 'cover', 'date', 'tracks', 'uuid']);
        this.assets = ObjectFilter.pickFields(data, ['cover', 'tracks']);
        this.original = data;

        const inheritedExplicitLanguage = new InheritedExplicitLanguage(this.info.inherited_explicit_language);
        const artistRoles = new ArtistsRoles(data.artists);

        this.info.inherited_explicit_language = inheritedExplicitLanguage.translate();
        this.artists = artistRoles.namesByRoles();

        this.assets.tracks = this.assets.tracks
            .map(track => new ReleaseMetadataTrack(track, TrackRepository));
    }

    loadCover() {
        return AlbumRepository.getAllCovers(this.original.uuid);
    }
}
