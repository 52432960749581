import Vue from 'vue';
import progressStatusBar from './components/progress-status-bar.vue';
import ExportBatchHistory from './models/exportBatchHistory';

export default {
    load(options, active) {
        new Vue({
            el: '#progressStatusBar',
            data: {
                options,
                currentTab: active
            },
            components: { progressStatusBar },
            computed: {
                steps() {
                    return ExportBatchHistory.parseForProgressStatusBar(this.options);
                },
                activeTab() {
                    return ExportBatchHistory.parseActive(this.currentTab);
                }
            }
        });
    }
};
