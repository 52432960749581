import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';

import Ringtone from '../../models/ringtone';
import RingtoneRepository from '../../models/ringone/ringtone-repository';
import RingtoneProcessor from '../../tools/ringtone-processor';
import AudioUpload from '../../components/audio-upload.vue';
import CoverUpload from '../../components/cover-upload.vue';
import InputLabel from '../../components/form-elements/input-label.vue';
import { _T } from '../../globals';
import { AUDIO_FILE_TYPES } from '../../constants/constants';

Vue.use(Vue2TouchEvents, {
    disableClick: true
})

export default {
    load(ringtone, editCover, deleteCover) {
        const audioFile = new Ringtone(ringtone);
        const processor = new RingtoneProcessor(audioFile.id);
        const restrictions = _T('ringtone audio file restrictions');

        return new Vue({
            el: '#ringtonesInfoPage',
            name: 'ringtonesInfoPage',
            components: {
                AudioUpload,
                CoverUpload,
                InputLabel
            },
            data: {
                coverModel: {
                    uuid: ringtone.uuid,
                    cover: ringtone.cover,
                    repository: RingtoneRepository,
                    editCover: editCover,
                    deleteCover: deleteCover
                },
                config: { processor, restrictions, file: audioFile },
                type: AUDIO_FILE_TYPES.RINGTONE
            },
            methods: {
                processing(processingData) {
                    this.config.file = new Ringtone(processingData);
                },
                clean() {
                    this.config.file.removeAudio();
                }
            }
        });
    }
};
