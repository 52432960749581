import Track from '../models/track';
import Processor from './processor';
import { API } from '../constants/constants';

export default class TrackProcessor extends Processor {
    constructor(id) {
        super({
            urls: {
                process: API.TRACK.replace('[id]', id),
                remove: API.TRACK_DELETE_AUDIO.replace('[id]', id)
            },
            condition(data) {
                const audioFile = new Track(data);

                return audioFile.hasAllAudio() || !audioFile.isProcessing();
            }
        });
    }
}
