<style lang="scss">
    .list__item {
        display: flex;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;

        &.list__item--inline {
            display: inline-block;
            width: 100%;

            .list__item-key,
            .list__item-value {
                width: 100%;
            }

            .list__item-value {
                margin-top: 5px;
            }
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .list__item-key {
        display: inline-block;
        width: 35%;
        padding-right: 10px;
        word-break: break-word;
        color: #787878;

        + .list__item-value {
            width: 65%;
        }
    }

    .list__item-value {
        display: inline-block;
        width: 100%;
        word-break: break-word;
    }


</style>

<template>
    <li class="list__item" :class="listItemClass">
        <span class="list__item-key" v-if="$slots.key">
           <slot name="key"></slot>
        </span>
        <span class="list__item-value">
            <slot name="value"></slot>
        </span>
    </li>
</template>

<script>
    export default {
        props: ['inline'],
        computed: {
            listItemClass() {
                return { 'list__item--inline': this.inline };
            }
        }
    };
</script>
