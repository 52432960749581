import axios from 'axios';
import { API } from '../constants/constants';

export default class bulkUpload {
    constructor() {
        this.file = null;
        this.uploading = false;
    }

    upload() {
        return new Promise((resolve, reject) => {
            const data = new FormData();

            if (!this.file) {
                this.clean();
                reject();
            } else {
                this.uploading = true;
                data.append('file', this.file);

                axios({
                    data,
                    method: 'post',
                    url: API.BULK_UPLOADS
                }).then((result) => {
                    this.clean();
                    this.uploading = false;
                    resolve(result);
                }).catch((error) => {
                    this.clean();
                    this.uploading = false;
                    reject(error);
                });
            }
        });
    }

    clean() {
        this.file = null;
    }

    isEmpty() {
        return this.file !== null;
    }

    isUploading() {
        return this.uploading;
    }
}
