import Uploader from './uploader';
import { API } from '../constants/constants';

export default class TrackUploader extends Uploader {
    constructor(options) {
        super({
            url: API.TRACK_POST_AUDIO_TOKEN.replace('[id]', options.id)
        });
    }

    addToken(token) {
        this.url = this.url.replace('[token]', token);
    }
}
