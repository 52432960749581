import axios from 'axios';
import { URLS } from '../constants/constants';

export default class Language {
    static change(locale) {
        return axios({
            cancelToken: null,
            method: 'post',
            url: URLS.CHANGE_LANGUAGE.replace('[locale]', locale)
        });
    }
}
