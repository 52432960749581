import ObjectFilter from '../../helpers/object-filter';
import RingtoneRepository from '../ringone/ringtone-repository';
import ReleaseMetadataTrack from '../release-metadata-track/release-metadata-track';
import ExplicitLanguage from '../explicit-language/explicit-language';
import ArtistsRoles from '../artists-roles';

function addTracks(assets, data) {
    const newAssets = Object.assign({}, assets);

    if (data.audio.uuid) {
        newAssets.tracks = [{
            title: data.title,
            audio: data.audio,
            uuid: data.uuid,
            artists: data.artists
        }];

        newAssets.tracks = newAssets.tracks
            .map(track => new ReleaseMetadataTrack(track, RingtoneRepository));
    }

    return newAssets;
}

export default class ReleaseMetadataRingtone {
    constructor(data) {
        const artistRoles = new ArtistsRoles(data.artists);

        this.info = ObjectFilter.omitFields(data, ['artists', 'cover', 'date', 'tracks', 'audio', 'uuid']);
        this.assets = ObjectFilter.pickFields(data, ['cover', 'tracks']);
        this.original = data;

        if (this.info.explicit_language !== undefined) {
            const explicitLanguage = new ExplicitLanguage(this.info.explicit_language);

            this.info.explicit_language = explicitLanguage.translate();
        }

        this.artists = artistRoles.namesByRoles();
        this.assets = addTracks(this.assets, data);
    }

    loadCover() {
        return RingtoneRepository.getAllCovers(this.original.uuid);
    }
}
