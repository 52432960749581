<style lang="scss">
    .input-color {
        position: relative;
        display: block;
    }

    .input-color__picker {
        position: absolute;
        top: 0;
        right: -10px;
        transform: translateX(100%);
        z-index: 2;
    }
</style>

<template>
    <div class="input-color">
        <input type="text" ref="input"
               :id="id" :value="value"
               @input="onInput" @focus="onFocus" @blur="onBlur">
        <div class="input-color__picker" ref="picker" v-show="isPickerVisible"></div>
    </div>
</template>

<script>
    import { $ } from '../../globals';

    export default {
        props: ['id', 'value'],
        data() {
            return {
                isPickerVisible: false,
                $farbtastic: null
            };
        },
        mounted() {
            const { $refs: { input } } = this;
            this.$farbtastic = $.farbtastic(this.$refs.picker);

            this.$farbtastic.linkTo((color) => {
                input.style.backgroundColor = color;
                input.style.color = this.$farbtastic.hsl[2] > 0.5 ? '#000' : '#fff';
                input.value = color.toUpperCase();
                this.$emit('input', input.value);
            });

            this.$farbtastic.setColor(input.value);
        },
        updated() {
            this.$farbtastic.setColor(this.value);
        },
        methods: {
            onInput($event) {
                this.$emit('input', $event.target.value.toUpperCase());
            },
            onFocus() {
                this.isPickerVisible = true;
                this.$emit('focus');
            },
            onBlur($event) {
                this.isPickerVisible = false;
                this.$emit('input', $event.target.value);
            }
        }
    };
</script>
