<style lang="scss">
    @import '../../scss/settings';

    .footer {
        position: relative;
        margin: 0 auto;
        width: $wrapperSize + ($spacing*4);
        padding: 32px 0;
        text-align: right;
    }
</style>

<template>
    <footer class="footer">
        <inline-menu class="spaced" :links="links.top"></inline-menu>
        <inline-menu :links="links.bottom"></inline-menu>
    </footer>
</template>

<script>
    import InlineMenu from './inline-menu.vue';

    export default {
        props: ['links'],
        components: { InlineMenu }
    };
</script>
