export default class PromiseQueue {
    constructor() {
        this.queue = [];
        this.isStarted = false;

        this.started = () => {};
        this.finished = () => {};
    }

    push(promise) {
        this.queue.push(promise);
    }

    start() {
        if (!this.isStarted) {
            this.isStarted = true;
            this.started();
        }

        if (this.queue.length === 0) {
            this.isStarted = false;
            this.finished();
            return;
        }

        this.queue[0]()
            .then(() => { this.next(); })
            .catch(() => { this.next(); });
    }

    next() {
        this.queue.shift();

        if (this.queue.length) {
            this.start();
        } else {
            this.isStarted = false;
            this.finished();
        }
    }
}
