<style lang="scss">
    .alert {
        border: 1px solid #d6e9c6;
        border-radius: 4px;
        padding: 15px;
        color: #3c763d;
        background: #dff0d8;
    }
    .alert.alert--error {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
    }
</style>

<template>
    <div class="alert" :class="{'alert--error': message.isError()}" v-html="message.text"></div>
</template>

<script>
    export default {
        props: ['message']
    };
</script>
