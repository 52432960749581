import Ringtone from '../models/ringtone';
import Processor from './processor';
import { API } from '../constants/constants';

export default class RingtoneProcessor extends Processor {
    constructor(id) {
        super({
            urls: {
                process: API.RINGTONE.replace('[id]', id),
                remove: API.RINGTONE_DELETE_AUDIO.replace('[id]', id)
            },
            condition(data) {
                const audioFile = new Ringtone(data);

                return audioFile.hasAllAudio() || !audioFile.isProcessing();
            }
        });
    }
}
