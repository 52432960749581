<style lang="scss">
    .metadata {
        position: relative;
        min-height: 100px;
    }
</style>

<template>
    <div class="metadata grid">
        <loading-overlay v-if="isLoading"></loading-overlay>
        <metadata-box v-if="hasAllMetadata" :metadata="metadata" :userdata="userdata" :id ="userId"></metadata-box>
    </div>
</template>

<script>
    import ReleaseRepository from '../../models/release/release-repository';
    import UserRepository from '../../models/user/user-repository';
    import LoadingOverlay from '../../components/loading-overlay.vue';
    import MetadataBox from '../../components/metadata-box.vue';

    export default {
        props: ['releaseId', 'userId'],
        components: { LoadingOverlay, MetadataBox },
        data() {
            return {
                isLoading: true,
                metadata: null,
                userdata: {}
            };
        },
        mounted() {
            const promises = [ReleaseRepository.getMetadataById(this.releaseId), UserRepository.getUser(this.userId)];
            Promise.all(promises)
                .then((data) => {
                    const {strikes} = data[1];
                    this.metadata = data[0];
                    this.userdata = Object.assign(this.userdata, {strikes});
                    this.isLoading = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.isLoading = false;
                });
        },
        computed: {
            hasAllMetadata() {
                return this.metadata;
            }
        }
    };
</script>
