import Vue from 'vue';
import Sidebar from './sidebar.vue';
import Bus from '../bus';

export default {
    load({ code, links }) {
        return new Vue({
            el: '#sidebar',
            template: `<sidebar :code="code" :links="links" :active="active" :key="key"></sidebar>`,
            data: {
                code,
                links,
                active: null,
                key: 1
            },
            components: { Sidebar },
            created() {
                Bus.$on('sidebar.set.active', (item) => {
                    this.links = this.links.map((link) => {
                        link.selected = link.id === item;

                        return link;
                    });

                    this.key = new Date().getTime();
                });
            }
        });
    }
};
