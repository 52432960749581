// GLOBAL EXTERNALS
// TODO: add script and css to input-color component
// and remove from here after removing all the legacy js and css
/* eslint-disable */
import 'script-loader!../node_modules/farbstastic/farbtastic.js';
import '../node_modules/farbstastic/farbtastic.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
/* eslint-enable */

import '../scss/_font-faces.scss';
import '../scss/_globals.scss';
import '../scss/_animations.scss';
import '../scss/_box.scss';
import '../scss/_grid.scss';
import '../scss/_custom-form.scss';
import '../scss/_inline-list.scss';

import CanLeave from './services/can-leave';
import Terms from './models/terms';

import Bus from './bus';
import Checkout from './checkout';
import DateRange from './date-range';
import Notifier from './notifier';
import Player from './player';
import ProgressStatusBar from './exportBatchProgressStatusBar';
import ReportUpload from './report-upload';
import ArtificialStreamingReportUpload from './artificial-streaming-report-upload';
import SalesChart from './sales-chart';
import SearchBox from './search-box';
import SearchBoxAmount from './search-box-amount';
import SearchBoxDate from './search-box-date';
import SearchBoxDateAmount from './search-box-date-amount';
import GlobalModals from './global-modals';
import Uploader from './uploader';
import Autocomplete from './Autocomplete';
import UserAutocompleteConfig from './autocompleteConfig/userAutocompleteConfig';
import LabelAutocompleteConfig from './autocompleteConfig/labelAutocompleteConfig';
import ReleaseAutocompleteConfig from './autocompleteConfig/releaseAutocompleteConfig';
import TrackAutocompleteConfig from './autocompleteConfig/trackAutocompleteConfig';


import Header from './layout/header';
import OldFooter from './layout/old-footer';
import NewFooter from './layout/new-footer';
import Footer from './layout/footer';
import GlobalMessage from './layout/global-message';
import Sidebar from './layout/sidebar';
import SubNavigation from './layout/sub-navigation';
import TopMenu from './layout/top-menu';

import LookAndFeelPage from './pages/look-and-feel/look-and-feel';
import MetadataPage from './pages/metadata/metadata';
import AlbumInfoPage from './pages/albums/info';
import AlbumLicensesPage from './pages/albums/licenses';
import AlbumTracksPage from './pages/albums/tracks';
import ArtistInfoPage from './pages/artists/info'
import ChannelInfoPage from './pages/channels/info';
import LabelInfoPage from './pages/labels/info';
import RingtoneInfoPage from './pages/ringtones/info';
import RingtoneLicensesPage from './pages/ringtones/licenses';
import TrackInfoPage from './pages/tracks/info';
import TaxRulesPage from './pages/taxes/tax-rules';
import VideoInfoPage from './pages/videos/info';

import BulkUploadDetail from './bulk-upload-detail';
import BulkUpload from './bulk-upload';
import ProgressBar from './progress-bar';
import Polling from './Polling';

window.app = {
    bus: Bus,
    dateRange: DateRange,
    loadCheckout: Checkout.load,
    loadProgressStatusBar: ProgressStatusBar.load,
    notifier: Notifier.load(),
    player: Player.load(),
    reportUpload: ReportUpload,
    artificialStreamingReportUpload: ArtificialStreamingReportUpload,
    bulkUploadDetail: BulkUploadDetail,
    bulkUpload: BulkUpload.load(),
    progress: ProgressBar,
    salesChart: SalesChart,
    searchBox: SearchBox,
    searchBoxAmount: SearchBoxAmount,
    searchBoxDate: SearchBoxDate,
    searchBoxDateAmount: SearchBoxDateAmount,
    globalsModals: GlobalModals.load(),
    uploader: new Uploader(),
    Polling: Polling,
    Autocomplete,
    AutocompleteConfig:{
        user: UserAutocompleteConfig,
        label: LabelAutocompleteConfig,
        release: ReleaseAutocompleteConfig,
        track: TrackAutocompleteConfig
    },
    layout: {
        header: Header,
        oldFooter: OldFooter,
        newFooter: NewFooter,
        footer: Footer,
        globalMessage: GlobalMessage,
        sidebar: Sidebar,
        subNavigation: SubNavigation,
        topMenu: TopMenu
    },
    pages: {
        lookAndFeel: LookAndFeelPage,
        metadata: MetadataPage,
        albums: {
            info: AlbumInfoPage,
            licenses: AlbumLicensesPage,
            tracks: AlbumTracksPage
        },
        artists: {
            info: ArtistInfoPage
        },
        channels: {
            info: ChannelInfoPage
        },
        labels: {
            info: LabelInfoPage
        },
        ringtones: {
            info: RingtoneInfoPage,
            licenses: RingtoneLicensesPage
        },
        tracks: {
            info: TrackInfoPage
        },
        taxRules: TaxRulesPage,
        videos: {
            info: VideoInfoPage
        }
    }
};

CanLeave.init();
Terms.show();

Bus.$on('upload.start', () => { window.app.uploader.setBusy(); });
Bus.$on('upload.end', () => { window.app.uploader.setIdle(); });

// TODO: move all data-[something] elements to components
// to apply the changes inside the component
Bus.$on('refresh.tenant.css', () => {
    const css = document.querySelectorAll('[data-tenant-css]')[0];
    css.href = css.href.replace(/\?.*|$/, `?refresh=${new Date().getTime()}`);
});
Bus.$on('refresh.favicon', () => {
    const favicon = document.querySelectorAll('[data-favicon]')[0];
    favicon.href = favicon.href.replace(/\?.*|$/, `?refresh=${new Date().getTime()}`);
});
Bus.$on('refresh.background', (src) => {
    document.body.style.backgroundImage = `url(${src}?refresh=${new Date().getTime()})`;
});
