import Vue from 'vue';
import DateRange from './components/date-range.vue';

function dateToMonthPickerFormat(date) {
    if (!date) {
        return '';
    }

    const dateSplitted = date.split('-');

    return `${dateSplitted[1]}/${dateSplitted[0]}`;
}

function monthPickerFormatToDate(monthPickerDate) {
    if (!monthPickerDate) {
        return '';
    }

    const dateSplitted = monthPickerDate.split('/');

    return `${dateSplitted[1]}-${dateSplitted[0]}`;
}

export default class Range {
    constructor(options) {
        return this.constructor.load(options);
    }

    static load(options) {
        const data = options;

        data.from = data.type === 'monthpicker' ? dateToMonthPickerFormat(data.from) : data.from;
        data.to = data.type === 'monthpicker' ? dateToMonthPickerFormat(data.to) : data.to;

        return new Vue({
            el: '#dateRangeApp',
            data: {
                from: data.from,
                to: data.to,
                type: data.type
            },
            components: { DateRange },
            methods: {
                search(filters) {
                    this.from = filters.date.from;
                    this.to = filters.date.to;
                    this.onSearched(filters);
                },
                getFrom() {
                    return data.type === 'monthpicker' ? monthPickerFormatToDate(this.from) : this.from;
                },
                getTo() {
                    return data.type === 'monthpicker' ? monthPickerFormatToDate(this.to) : this.to;
                },
                onSearched() {}
            }
        });
    }
}
