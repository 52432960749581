import Vue from 'vue';
import Notifier from './components/notifier.vue';


export default {
    load() {
        if (!document.getElementById('notifier')) {
            return false;
        }

        return new Vue({
            el: '#notifier',
            template: `<notifier></notifier>`,
            components: { Notifier }
        });
    }
};
