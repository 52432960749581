import ObjectHelper from '~phoenix/js/helpers/object';

export default class Contract {
    constructor(data) {
        if (!data) {
            return;
        }

        this.user_id = data.user_id;
        this.version = data.version;
    }

    fromVersion(aVersion) {
        return new Contract({
            user_id: this.user_id,
            version: aVersion
        });
    }

    equals(aContract) {
        return this.version === aContract.version
            && this.user_id === aContract.user_id;
    }

    equalVersions(aContract) {
        return this.version === aContract.version;
    }

    isEmpty() {
        return ObjectHelper.isEmpty(this);
    }
}
