<template>
    <div
        v-if="!cute"
        class="search-box cform cform--small"
        :class="{'search-box--opened': areFiltersOpened}"
    >
        <div class="cform__input-group cform__input-group--round" v-bind:class="inputTypeClass">
            <span class="cform__input-group__icon" @click="toggleFilters" v-if="hasFiltersSlot">
                <circle-down-icon viewBox="0 0 32 32"></circle-down-icon>
            </span>
            <input type="text" :placeholder="placeholder" @keyup.enter="search" ref="search">
            <span class="cform__input-group__icon" @click="search">
                <search-icon viewBox="0 0 32 32"></search-icon>
            </span>
        </div>
        <div class="search-box__filters" v-if="hasFiltersSlot && areFiltersOpened">
            <slot name="filters"></slot>
        </div>
    </div>
    <div v-else>
        <div class="search-box__container">
            <input
                type="text"
                ref="search"
                class="search-box__input"
                :placeholder="placeholder"
                @keyup="search"
            />
            <div
                v-if="showRemove"
                role="button"
                class="search-box__button"
                :ariaLabel="removeValue"
                @click="cleanSearch"
            >
              <cross-small/>
            </div>
            <div
                role="button"
                class="search-box__button"
                :ariaLabel="placeholder"
                @click="search"
            >
              <search-icon-cute/>
            </div>
        </div>
    </div>
</template>

<script>
    import CircleDownIcon from '../../svg/circle-down.svg';
    import SearchIcon from '../../svg/search.svg';
    import SearchIconCute from '../../svg/search-icon.svg';
    import CrossSmall from '../../svg/cross-small.svg';
    import { _T } from '../globals';

    export default {
        name: 'SearchBox',
        components: {
            CircleDownIcon,
            SearchIcon,
            SearchIconCute,
            CrossSmall
        },
        props: {
            /**
             * If set to true, implements the new UI developed for BulkUpload
             */
            cute: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: _T('Search...')
            },
            removeValue: {
                type: String,
                default: _T('delete')
            }
        },
        data: () => ({
            hasKeyword: false,
            filtersOpen: false
        }),
        methods: {
            search() {
                if (this.$refs.search.value.length > 0 ) {
                    this.hasKeyword = true
                }
                this.$emit('search', this.$refs.search.value);
            },
            cleanSearch() {
                this.hasKeyword = false
                this.$refs.search.value = ''
                this.$emit('search', this.$refs.search.value)
            },
            toggleFilters() {
                this.filtersOpen = !this.filtersOpen;
            }
        },
        computed: {
            showRemove() {
                return this.hasKeyword
            },
            hasFiltersSlot() {
                return !!this.$slots.filters;
            },
            areFiltersOpened() {
                return this.filtersOpen;
            },
            inputTypeClass() {
                return [this.hasFiltersSlot ? 'cform__input-group--icon-both' : 'cform__input-group--icon-right'];
            }
        }
    };
</script>

<style lang="scss">
    @import '../../scss/settings';

    .search-box {
        position: relative;
        float: left;
        width: 438px;
        &.search-box--opened {
            .cform__input-group .cform__input-group__icon:first-child svg {
                transform: rotate(180deg);
            }
        }
        //TODO: old styles, refactor please.
        &.search-box--fixed {
            padding: 0!important;
            margin: 0!important;
            position: absolute;
            right: 100px;
        }
        &.search-box--right {
            float: right;
        }
        &.search-box--small {
            width: 279px;
        }
        &.search-box--full {
            width: 100%;
        }
    }

    .search-box__filters {
        position: absolute;
        bottom: -2px;
        left: 0;
        border: 1px solid #B9D1EE;
        width: 100%;
        padding: 12px;
        background: #F9FBFF;
        transform: translateY(100%);
        z-index: 1;
    }

    // New search box implemented on BulkUpload view
    .search-box {
        &__container {
            display: flex;
            justify-content: space-between;
            width: 17rem;
            padding: 0.625rem 1rem;
            border: 0.0625rem solid #d7d7d7;
            border-radius: 0.3125rem;
        }

        &__button {
            display: flex;
            align-self: center;
            margin-left: 1rem;
        }
    }
    input.search-box {
        &__input.std-input.std-textfield {
            width: 86%;
            height: auto !important;
            padding: 0 !important;
            border: none;
            font-size: 0.875rem;
            color: #787878;
        }
    }
</style>
