import Track from './track';

export default class EmptyTrack extends Track {
    constructor(data) {
        super({
            id: data.id,
            title: data.title,
            audio: {},
            display_artist: '',
            remix_or_version: null
        });

        this.uploader.setBusy();
    }
}
