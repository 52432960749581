<style lang="scss">
    .artist-box {
        display: inline-block;
        border: 1px solid #c3c4be;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        padding: 10px;
    }
</style>

<template>
    <div class="artist-box">
        <list>
            <list-item v-for="(value, key) in artist" :key="key">
                <template slot="key">{{key}}</template>
                <template slot="value">{{value | toString}}</template>
            </list-item>
        </list>
    </div>
</template>

<script>
    import List from './list.vue';
    import ListItem from './list-item.vue';

    export default {
        props: ['artist'],
        components: { List, ListItem }
    };
</script>
