<style lang="scss">
    .audio-file {
        position: relative;
        float: left;
        width: 100%;
        margin-bottom: 15px;
        clear: both;
        text-align: left;
    }
    .audio-file__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 70px;
        height: 40px;
    }
    .audio-file__control {
        position: relative;
        float: right;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .audio-file__control svg {
        fill: #7B7878;
    }
    .audio-file__control.audio-file__control--play {
        margin-right: 10px;
        background: #7B7878;
        border-radius: 50%;
    }
    .audio-file__control.audio-file__control--play svg{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -11px;
        margin-left: -9px;
        width: 22px;
        height: 22px;
        fill: #FFFFFF;
    }
    .audio-file__info {
        margin-left: 85px;
    }
    .audio-file__title {
        float: left;
        width: 100%;
        font-weight: 700;
    }
    .audio-file__description {
        float: left;
        width: 100%;
    }
</style>

<template>
    <div class="audio-file">
        <div class="audio-file__controls">
            <span class="audio-file__control" @click="download">
                <download-icon viewBox="0 0 32 32"></download-icon>
            </span>
            <span class="audio-file__control audio-file__control--play" v-if="isPlayable"
                  @click="play">
                <play-icon viewBox="0 0 32 32"></play-icon>
            </span>
        </div>
        <div class="audio-file__info">
            <span class="audio-file__title">{{audio.audio_type}}</span>
            <span class="audio-file__description">{{audio.metadataToString()}}</span>
        </div>
    </div>
</template>

<script>
    import PlayIcon from '../../svg/play.svg';
    import DownloadIcon from '../../svg/download.svg';

    export default {
        props: ['audio'],
        components: { PlayIcon, DownloadIcon },
        methods: {
            download() {
                window.open(this.audio.url);
            },
            play() {
                this.$emit('play');
            }
        },
        computed: {
            isPlayable() {
                return this.audio.audio_type !== 'original';
            }
        }
    };
</script>
