import { nest as d3Nest } from 'd3-collection';
import { sum as d3Sum } from 'd3-array';
import Colors from './chart-colors';
import time from './time';
import numbers from './numbers';

export default {
    salesToChart(royalties_analytics_data, services_data) {
        const parsed = [];
        let nested = [];
        let totals = [];
        const points = [];
        let total = 0;

        royalties_analytics_data.items.forEach((item) => {
            const date = `${item.year}-${item.month}`;

            if (!points.includes(date)) {
                points.push(date);
            }

            total += parseFloat(item.amount);

            parsed.push({
                id: String(item.service),
                name: getServiceName(services_data, item.service),
                value: Number(item.amount),
                date: time.setDateByYearMonth(item.year, item.month)
            });
        });

        nested = d3Nest()
            .key(d => d.id)
            .entries(parsed);

        totals = d3Nest()
            .key(d => d.date)
            .rollup(v => d3Sum(v, d => d.value))
            .entries(parsed);

        totals = totals.map(d => Object.assign(d, { name: 'Total', date: new Date(d.key), color: Colors[0] }));
        nested = nested.map((d, i) => Object.assign(d, { color: Colors[i] }));

        return {
            parsed,
            nested,
            totals,
            points,
            original: royalties_analytics_data.items,
            total: numbers.round(total, 2)
        };

        function getServiceName(services_data, service_code) {

            let name = Object.keys(services_data).find(key => services_data[key] === service_code);

            return name ? name : service_code;

        }
    }
};
