import {_T} from '../globals';

export default {
    htmlOptions: {
        root: document.querySelector('#user'),
        placeholderText: _T('user_email')
    },
    onOptionSelect(item){
        const userHidden = document.querySelector('#user_id');
        userHidden.value = item.uuid;
    },
    renderOption(item) {
        const email = item.email;

        return `<span/>${email}</span>`;
    },
    getInputValue(item) {
        return item.email;
    },
    async fetchData(value) {
        try {
            const response = await fetch('/api/1.0/users?filter[email]=' + encodeURIComponent('%' + value + '%'));
            const data = await response.json();

            return data.items;
        } catch (e) {
            alert('Could not fetch details');
            throw e;
        }

    }
};

