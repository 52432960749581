<style lang="scss">
    @import '../../scss/settings';

    .sidebar {
        position: fixed;
        width: $sidebarSize;
        height: 100%;
        background: $white;
        box-sizing: border-box;
        overflow: auto;
        z-index: 1;

        * {
            box-sizing: border-box;
        }
    }

    .sidebar__logo {
        padding: $spacing*2 0 0 $spacing*2;
    }

    .sidebar__logo-link {
        display: block;
    }

    .sidebar__logo-image {
        display: block;
        max-width: $sidebarSize - $spacing*4;
    }

    .sidebar__menu {
        margin-top: $spacing*2;
    }

    .sidebar__menu-item {
        margin-bottom: $spacing/2;
    }

    .sidebar__menu-link {
        position: relative;
        display: flex;
        align-items: center;
        padding: 1.3125rem 1.75rem;
        font-size: 1rem;
        color: $darkGray;
        fill: $darkGray;

        &.sidebar__menu-link--selected {
            background: $darkGray;
            color: $white;
            fill: $white;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: $spacing/4;
                height: 100%;
                background: $primary;
                content: '';
            }
        }
    }

    .sidebar__menu-icon {
        display: inline-block;
        margin-right: 1.5rem;
        width: 1.125rem;
        height: 1.125rem;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
</style>

<template>
    <aside class="sidebar">
        <div>
            <div class="sidebar__logo">
                <a class="sidebar__logo-link" href="/" :title="code">
                    <img class="sidebar__logo-image" :src="src" :alt="code">
                </a>
            </div>
            <ul class="sidebar__menu">
                <li class="sidebar__menu-item" v-for="(link, index) in links" :key="index">
                    <a class="sidebar__menu-link"
                       :class="{'sidebar__menu-link--selected' : link.selected}"
                       :href="link.href" :title="link.text">
                    <span class="sidebar__menu-icon">
                        <component :is="link.icon"></component>
                    </span>
                        <span>{{link.text}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
    import Bus from '../bus';
    import BarChartIcon from '../../svg/bar-chart.svg';
    import DistributionIcon from '../../svg/distribution.svg';
    import FinancialsIcon from '../../svg/financials.svg';
    import NoteIcon from '../../svg/note.svg';
    import WheelIcon from '../../svg/wheel.svg';

    export default {
        props: ['code', 'links'],
        components: {
            BarChartIcon,
            DistributionIcon,
            FinancialsIcon,
            NoteIcon,
            WheelIcon
        },
        data() {
            return {
                src: `/branding/${this.code}/images/header_logo.png`
            };
        },
        created() {
            Bus.$on('refresh.logo', this.refreshImage);
        },
        methods: {
            refreshImage() {
                this.src = `${this.src}?refresh=${new Date().getTime()}`;
            }
        }
    };
</script>
