<style lang="scss">
    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000000, 0.2);
        box-sizing: border-box;
        overflow: auto;
        z-index: 999999;

        * {
            box-sizing: border-box;
        }
    }

    .modal__box {
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        margin: 20px 0;
        max-width: 80%;
        max-height: 80%;
        background: #FFFFFF;
        box-shadow: 0 20px 60px -2px rgba(27, 33, 58, .4);
        overflow: hidden;
    }

    .modal__header {
        border-bottom: 0.0625rem solid #DCDCDC;
        padding: 0.9375rem 1.125rem 0.9375rem 1.5rem;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.575rem;

        &:empty {
            display: none;
        }
    }

    .modal__subheader {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;

        &:empty {
            display: none;
        }
    }

    .modal__body {
        flex: 1;
        overflow: auto;

        &:empty {
            display: none;
        }
    }

    .modal__footer {
        width: 100%;
        border-top: 0.0625rem solid #DCDCDC;
        margin-top: 0.75rem;
        padding: 0.5rem 1.125rem;
        text-align: right;

        &:empty {
            display: none;
        }
    }
</style>

<template>
    <div class="modal">
        <div class="modal__box">
            <div class="modal__header">
                <slot name="header"></slot>
                <div class="modal__subheader">
                    <slot name="sub-header"></slot>
                </div>
            </div>
            <div class="modal__body">
                <slot name="body"></slot>
            </div>
            <div class="modal__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            document.body.style.overflow = 'hidden';
        },
        destroyed() {
            document.body.style.overflow = null;
        }
    };
</script>
