<style>
    .language-dropdown .dropdown__selected .dropdown__language_icon {
        width: 20px;
        height: 21px;
    }

    .language-dropdown .dropdown__selected .dropdown__language_icon svg {
        width: 20px;
        height: 21px;
    }
</style>

<template>
    <dropdown :selected="selected" :class="'language-dropdown'" :options="options" @optionSelected="changeLanguage">
    </dropdown>
</template>

<script>
    import Dropdown from './dropdown.vue';
    import Language from '../models/language';
    import { doReload } from '../globals';

    export default {
        props: ['selected', 'options'],
        components: { Dropdown },
        data() {
            return {
                language: {
                    name: this.selected.name,
                    locale: this.selected.id
                }
            };
        },
        methods: {
            changeLanguage(option) {
                document.body.style.cursor = 'wait';

                Language.change(option.id)
                    .then(() => {
                        doReload();
                        document.body.style.cursor = 'default';
                    })
                    .catch(() => {
                        document.body.style.cursor = 'default';
                    });
            }
        }
    };
</script>
