<template>
    <div>
        <div class="icon-upload">
            <label class="icon-upload__image-container">
                <div for="image-upload-icon" class="icon-upload__label">
                    {{ translate(label) }}
                </div>
                <div class="icon-upload__image">
                    <loading-overlay v-if="isLoading"/>
                    <image-upload-icon
                        :cover=true
                        :src="src"
                        :size="size"
                        @click="onClicked"
                        @change="onChanged"
                        @onDelete="onDelete"
                        :edit-cover="true"
                        :delete-cover="true"
                    />
                </div>
            </label>
            <div class="icon-upload__requirements">
                <div class="icon-upload__requirements-title">
                    {{ translate(rulesTitle) }}
                </div>
                <ul class="icon-upload__requirements-list">
                    <li
                        v-for="(item, key) in rules"
                        :key="key"
                        class="icon-upload__requirements-list-item"
                    >{{ translate(item) }}</li>
                </ul>
            </div>
        </div>
        <div
            v-if="errors.length"
            class="icon-upload__error-container"
        >
            <div class="icon-upload__error--bold">
                {{ uploadFailed }}
            </div>
            <div
                v-for="(error, index) in errors"
                :key="index"
                class="icon-upload__error"
            >{{ error }}</div>
        </div>
    </div>

</template>

<script>
    import ImageUploadIcon from './image-upload-icon.vue';
    import LoadingOverlay from './loading-overlay.vue';
    import ErrorsHelper from '../helpers/errors';
    import Polling from '../helpers/polling';
    import {_T} from '../globals';

    export default {
        props: ['model', 'label', 'rules', 'rulesTitle'],
        components: { LoadingOverlay, ImageUploadIcon },
        data() {
            return {
                size: '80x80',
                src: this.model.icon.length !== 0 ? this.model.icon[40].href : null,
                isLoading: false,
                errors: [],
                uploadFailed: _T('upload_failed')
            };
        },
        methods: {
            onClicked() {
                this.errors = [];
            },
            onChanged($event) {
                this.isLoading = true;

                this.model.repository.uploadIcon(this.model.id, $event.target.files[0])
                    .then(() => {
                        this.model.repository.getIcon(this.model.id, 40)
                            .then((src) => {
                                Polling.pollFile(src)
                                    .then(() => {
                                        this.src = src;
                                        this.isLoading = false;
                                    });
                            });
                    })
                    .catch((error) => {
                        this.errors = ErrorsHelper.toArray(error);
                        this.isLoading = false;
                    });
            },
            onDelete(event) {
                this.isLoading = true;

                this.model.repository.deleteIcon(this.model.id)
                    .then(this.clean)
                    .catch(this.clean);
                event.preventDefault();
            },
            clean() {
                this.src = null;
                this.isLoading = false;
            },
            translate(string) {
                return _T(string);
            }
        }
    };
</script>

<style lang="scss">
    .icon-upload {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        padding: 0 0 0.75rem 0;
        width: 100%;

        &__image-container {
            display: flex;
            flex-flow: row nowrap;
        }

        &__image {
            position: relative;
            width: 80px;
            height: 80px;
            background-color: #fff;
            border-radius: 0.3125rem;
        }

        &__label {
            width: 222px;
        }

        &__requirements {
            padding: 0.5rem 6rem;
            
        }

        &__requirements-title {
            margin-bottom: 0.25rem;
            line-height: 1.225rem;
            font-size: 0.875rem;
            font-weight: 500;
        }

        &__requirements-list {
            line-height: 1.225rem;
            font-size: 0.875rem;
            margin-bottom: 1rem;
        }

        &__requirements-list-item {
            font-weight: 400;
        }

        &__requirements-list-item:before {
            content: '\2022';
            margin: 0 0.25rem 0 0;
            line-height: 1;
            font-size: 1.25rem;
            font-weight: 400;
        }

        &__error-container {
            margin: 0.5rem 0 0.5rem 13.6875rem;
            color: #f03d3d;
        }

        &__error {
            display: block;
            line-height: 16.8px;
            font-size: 12px;

            &--bold {
                background-image: url('/images/default/error.svg');
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 14px;
                padding-left: 1.3rem;
                margin-top: 0.3rem;
                width:100%;
                color: #f03d3d;

                margin-bottom: 0.25rem;
                font-size: 0.875rem;
                font-weight: 600;
            }
        }
    }
</style>
