import ObjectFilter from '../../helpers/object-filter';

function parseFileErrors(errors) {
    const parsed = {};

    if(typeof errors === 'object') {
        return parsed;
    }

    const decodedErrors = JSON.parse(errors);

    if (decodedErrors.file) {
        parsed.general = decodedErrors.file.errors || decodedErrors.file;
    }

    if (decodedErrors.metadata) {
        parsed.metadata = decodedErrors.metadata.errors || decodedErrors.metadata;
    }

    return parsed;
}

function parseErrors(errors) {
    const parsed = errors.reduce((total, actual) => {
        total.push(actual.violation);

        return total;
    }, []);

    return parsed;
}

function parseReleases(releases) {
    const parsed = releases.reduce((total, actual) => {
        total.push({
            info: ObjectFilter.omitFields(actual.release, ['tracks']),
            tracks: ObjectFilter.pickField(actual.release, 'tracks'),
            errors: parseErrors(actual.details)
        });

        return total;
    }, []);

    return parsed;
}

export default class BulkUploadDetail {
    constructor(data) {
        this.info = ObjectFilter.omitFields(data, ['details', 'errors']);
        this.releases = parseReleases(ObjectFilter.pickField(data, 'errors'));
        this.fileErrors = parseFileErrors(ObjectFilter.pickField(data, 'details'));
        this.original = data;
    }

    hasInfo() {
        return Object.keys(this.info).length !== 0;
    }

    hasReleases() {
        return Object.keys(this.releases).length !== 0;
    }

    hasFileErrors() {
        return Object.keys(this.fileErrors).length !== 0;
    }

    hasFileGeneralErrors() {
        return this.hasFileErrors()
            && this.fileErrors.general
            && Object.keys(this.fileErrors.general).length !== 0;
    }

    hasFileMetadataErrors() {
        return this.hasFileErrors()
            && this.fileErrors.metadata
            && Object.keys(this.fileErrors.metadata).length !== 0;
    }
}
