import Vue from 'vue';
import ReportUpload from './components/report-upload.vue';

export default class Upload {
    constructor() {
        return this.constructor.load();
    }

    static load() {
        return new Vue({
            el: '#reportUploadApp',
            data: {},
            components: { ReportUpload },
            methods: {
                uploaded() {
                    this.onUploaded();
                },
                onUploaded() {}
            }
        });
    }
}
