import { render, staticRenderFns } from "./bulk-upload-detail.vue?vue&type=template&id=cb44db56&"
import script from "./bulk-upload-detail.vue?vue&type=script&lang=js&"
export * from "./bulk-upload-detail.vue?vue&type=script&lang=js&"
import style0 from "./bulk-upload-detail.vue?vue&type=style&index=0&id=cb44db56&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports