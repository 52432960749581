import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import InputText from '../../components/form-elements/input-text.vue';

Vue.use(Vue2TouchEvents, {
    disableClick: true
})

export default {
    load() {
        return new Vue({
            el: '#artistsInfoPage',
            name: 'artistsInfoPage',
            components: { InputText }
        });
    }
};
