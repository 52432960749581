<template>
    <span class="tooltip">
        <div class="tooltip-block"
            v-for="(message, index) in messages"
            :key="index"
             v-html="translate(message)"
        ></div>
    </span>
</template>

<script>
import {_T} from '../globals';

export default {
    props: {
        messages: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        translate(string) {
            return _T(string);
        }
    }
};
</script>

<style lang="scss">
    .tooltip {
        display: block;
        position: absolute;
        top: -12px;
        right: -12px;
        border-radius: 0.5rem;
        padding: 1rem;
        width: 240px;
        background: #141419;
        color: #FFF;
        font-size: 0.75rem;
        font-weight: 500;
        text-align: left;
        word-break: break-word;
        transform: translateX(100%);
        z-index: 51;

        &:before {
            position: absolute;
            top: 12px;
            left: -6px;
            border-top: 0.5rem solid transparent;
            border-bottom: 0.5rem solid transparent;
            border-right: 0.5rem solid #141419;
            width: 0;
            height: 0;
            content: '';
        }

        a {
            background: #141419;
            color: #FFF;
        }
    }

    .tooltip-block {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
