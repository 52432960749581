import { EXPORT_BATCH_HISTORY_TITLES } from '../constants/constants';
import { moment } from '../globals';

export default class ExportBatchHistory {
    static parseForProgressStatusBar(options) {
        const data = Object.assign({}, options);
        const diffs = [];
        const keys = Object.keys(EXPORT_BATCH_HISTORY_TITLES);

        keys.forEach((key, index) => {
            const next = keys[index + 1];

            if (next && data[next]) {
                diffs[key] = moment.duration(moment(data[key]).diff(moment(data[next]))).humanize();
            }
        });

        return Object.keys(EXPORT_BATCH_HISTORY_TITLES).reduce((total, key) => {
            const copy = total;

            if (data[key]) {
                data[key] = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
            }

            if (diffs[key]) {
                data[key] = `${data[key]} (${diffs[key]})`;
            }

            copy[EXPORT_BATCH_HISTORY_TITLES[key]] = data[key];

            return copy;
        }, {});
    }

    static parseActive(active) {
        return active === EXPORT_BATCH_HISTORY_TITLES.finishedAt ? '' : active;
    }
}
