import axios from 'axios';
import { API } from '../constants/constants';
import FormsHelper from '../helpers/forms';

export default class TenantBranding {
    constructor(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
    }

    save(data) {
        return axios.post(API.TENANT_BRANDING.replace('[id]', this.id), data);
    }

    saveBackgroundImage(file) {
        return axios.post(API.TENANT_BRANDING_BG_IMAGE.replace('[id]', this.id), FormsHelper.filesToFormData([file]));
    }

    saveFavicon(file) {
        return axios.post(API.TENANT_BRANDING_FAVICON.replace('[id]', this.id), FormsHelper.filesToFormData([file]));
    }

    saveLogo(file) {
        return axios.post(API.TENANT_BRANDING_LOGO.replace('[id]', this.id), FormsHelper.filesToFormData([file]));
    }
}
