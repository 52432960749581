import Vue from 'vue';
import axios from 'axios';
import Parser from './helpers/parser';
import Chart from './models/line-chart';
import LineChart from './components/line-chart.vue';
import { API } from './constants/constants';

export default class SalesChart {
    constructor(options) {
        return this.constructor.load(options);
    }

    static load(options) {
        return new Vue({
            el: '#salesChartApp',
            data: {
                chart: new Chart(options.currency),
                data: {},
                showTotals: false,
                currency: options.currency,
                channels: options.channels,
                isError:false

            },
            components: { LineChart },
            methods: {
                onDataSuccess(sales_analytics_response) {
                    
                    this.isError = false;
                    this.data = Parser.salesToChart(sales_analytics_response.data, this.channels);

                    if (this.showTotals) {
                        this.getTotals(this.data);
                    } else {
                        this.getIndividuals(this.data);
                    }
                },
                onDataError() {
                    this.isError = true;
                    this.data = Parser.salesToChart({ items: [] });
                    this.chart.update(this.data);
                },
                update(params) {

                    let sales_analytics_url = `${API.ROYALTIES_ANALYTICS}?${this.generateFilterQueryString(params)}`;

                    this.data = {};

                    axios.get(sales_analytics_url).then(data => {
                        this.onDataSuccess(data)
                    })
                        .catch(() => this.onDataError())
                },
                generateFilterQueryString(filterParams) {
                    return filterParams
                        .map(param => `filter[${param.key}]=${param.value}`)
                        .join('&');
                },
                generatePagerQueryString(offset, limit) {
                    return `pager[offset]=${offset}&pager[limit]=${limit}`;
                },
                getIndividuals() {
                    this.showTotals = false;

                    if (this.hasData()) {
                        this.chart.update(this.data);
                    }
                },
                getTotals() {
                    this.showTotals = true;

                    if (this.hasData() && this.hasTotals()) {
                        this.chart.updateTotals(this.data);
                    }
                },
                hasData() {
                    return Object.keys(this.data).length !== 0;
                },
                hasTotals() {
                    return Object.keys(this.data.totals).length !== 0;
                }
            }
        });
    }
}
