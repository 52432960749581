const API = {
    URL: '/api/1.0',
    ALBUM_TRACKS: '/api/1.0/albums/[id]/tracks',
    CONVERSORS: '/api/1.0/conversors',
    ALBUM_AUDIO_TOKENS: '/api/1.0/albums/[id]/audio/tokens',
    ALBUM_COVER: '/api/1.0/albums/[id]/cover/[size]',
    ALBUM_COVER_INFO: '/api/1.0/albums/[id]/cover/info',
    CHANNEL_SALES_REPORT: '/api/1.0/channel_sales_reports',
    CHANNEL_SALES_REPORT_ERRORS: '/api/1.0/channel_sales_reports_errors',
    PLATFORM_ARTIFICIAL_STREAMING_REPORT: '/api/1.0/platform_artificial_streaming_reports',
    CLIENT_ARTIFICIAL_STREAMING_REPORT: '/api/1.0/client_artificial_streaming_reports',
    CHANNEL_LOGO: '/api/1.0/channels/[id]/logo/[size]',
    CHANNEL_ICON: '/api/1.0/channels/[id]/icon/[size]',
    CHANNEL_COLOUR: '/api/1.0/channels/[id]/colour',
    CHANNELS_INFO: '/api/1.0/channels',
    RELEASE_METADATA: '/api/1.0/releases/[id]/metadata',
    RELEASE_UNBLOCK: '/api/1.0/releases/[id]/unblock_release',
    RINGTONE: '/api/1.0/ringtones/[id]',
    RINGTONE_AUDIO_TOKENS: '/api/1.0/ringtones/[id]/audio/tokens',
    RINGTONE_COVER: '/api/1.0/ringtones/[id]/cover/[size]',
    RINGTONE_COVER_INFO: '/api/1.0/ringtones/[id]/cover/info',
    RINGTONE_POST_AUDIO_TOKEN: '/api/1.0/ringtones/[id]/audio?X-Auth-Token=[token]',
    RINGTONE_DELETE_AUDIO: '/api/1.0/ringtones/[id]/audio',
    ROYALTIES_ANALYTICS: '/api/1.0/royalties_analytics',
    TAX_RULES: '/api/1.0/tax_rules/[id]',
    TENANT_BRANDING: '/api/1.0/tenant_brandings/[id]',
    TENANT_BRANDING_BG_IMAGE: '/api/1.0/tenant_brandings/[id]/bg_image',
    TENANT_BRANDING_FAVICON: '/api/1.0/tenant_brandings/[id]/favicon',
    TENANT_BRANDING_LOGO: '/api/1.0/tenant_brandings/[id]/logo',
    TENANT_TAX_RULES: '/api/1.0/tenants/[id]/taxes',
    TRACK: '/api/1.0/tracks/[id]',
    TRACK_AUDIO_TOKENS: '/api/1.0/tracks/[id]/audio/tokens',
    TRACK_POST_AUDIO_TOKEN: '/api/1.0/tracks/[id]/audio?X-Auth-Token=[token]',
    TRACK_DELETE_AUDIO: '/api/1.0/tracks/[id]/audio',
    TRACKS_PAGER: '/api/1.0/tracks',
    USER: '/api/1.0/users/[id]/',
    USER_CONTRACT: '/api/1.0/users/[uuid]/contract',
    USER_CONTRACT_ONLY: '/user/contractonly',
    USER_ACCEPT_TERMS: '/user/acceptterms',
    BULK_UPLOADS: '/api/1.0/bulk_uploads',
    BULK_UPLOAD_DETAIL: '/api/1.0/bulk_uploads/[uuid]'
};

const LOCAL_STORAGE = {
    ACCEPTED_CONTRACT_VERSION: 'mx2_accepted_contract_version'
};

const URLS = {
    CHANGE_LANGUAGE: '/sessions/language/[locale]',
    CHANGE_SESSION: '/sessions/change',
    LOGOUT: '/logout',
    TRACK: '/tracks/[id]',
    RINGTONE: '/ringtones/[id]',
    USER_PROFILE: '/user/profile'
};

const AUDIO_FILE_STATE = {
    TRANSCODING: 'TRANSCODING',
    READY: 'READY'
};

const AUDIO_FILE_TYPES = {
    TRACK: 'track',
    RINGTONE: 'ringtone'
};

const AUDIO_TYPE = {
    ORIGINAL: 1,
    FULL: 2
};

const EXPORT_BATCH_HISTORY_TITLES = {
    createdAt: 'processing',
    generatedAt: 'pending',
    transferStartedAt: 'transferring',
    finishedAt: 'completed'
};

const SERVER_ERRORS = {
    BAD_REQUEST: {
        STATUS: 400,
        TEXT: 'Bad Request'
    },
    INTERNAL_ERROR: {
        STATUS: 500,
        TEXT: ' Internal Server Error'
    }
};

const MESSAGE_TYPE = {
    DEFAULT: 'default',
    ERROR: 'error'
};

const PROCESSOR_STATUS = {
    IDLE: 'idle',
    PROCESSING: 'processing',
    REMOVING: 'removing'
};

const PROGRESS_STATUS_BAR_CLASSES = {
    ITEM: 'progress-status-bar__item',
    DONE: 'progress-status-bar__item--done',
    ACTIVE: 'progress-status-bar__item--active',
    TODO: 'progress-status-bar__item--todo'
};

const RELEASE_TYPE = {
    ALBUM: 1,
    RINGTONE: 2
};

const STATUS = {
    LOADING: 'loading',
    PLAYING: 'playing',
    PAUSED: 'paused',
    CLEAN: 'clean'
};

const TRACK_TYPE = {
    APPENDABLE: 'appendable',
    EDITABLE: 'editable',
    PLAYABLE: 'playable',
    REMOVABLE: 'removable',
    SORTABLE: 'sortable',
    UPLOADABLE: 'uploadable'
};

const UPLOADER_STATUS = {
    IDLE: 'idle',
    BUSY: 'busy'
};

const USER_ACTIONS = {
    CHANGE_USER: 'CHANGE_USER',
    GO_TO_PROFILE: 'GO_TO_PROFILE',
    LEAVE_USER: 'LEAVE_USER',
    LOGOUT: 'LOGOUT'
};

export {
    API,
    AUDIO_TYPE,
    AUDIO_FILE_TYPES,
    AUDIO_FILE_STATE,
    EXPORT_BATCH_HISTORY_TITLES,
    LOCAL_STORAGE,
    SERVER_ERRORS,
    MESSAGE_TYPE,
    PROCESSOR_STATUS,
    PROGRESS_STATUS_BAR_CLASSES,
    RELEASE_TYPE,
    STATUS,
    TRACK_TYPE,
    UPLOADER_STATUS,
    URLS,
    USER_ACTIONS
};
