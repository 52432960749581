import { _T } from '../globals';

export default {
    filters: {
        t(value, params = []) {
            return _T(value, params);
        },
        currency(...args) {
            const [value, symbol, e] = args;

            return symbol + (Number(value)).toFixed(e);
        }
    }
};
