import axios from 'axios';
import { URLS } from '../constants/constants';

export default class User {
    static leave() {
        return axios({
            cancelToken: null,
            method: 'post',
            url: URLS.CHANGE_SESSION
        });
    }
}
