import Vue from 'vue';
import GlobalMessage from './global-message.vue';

export default {
    load(message) {
        return new Vue({
            el: '#global-message',
            template: `<global-message v-if="message">{{message}}</global-message>`,
            data: { message },
            components: { GlobalMessage }
        });
    }
};
