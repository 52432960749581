<template>
    <transition name="fade">
        <div
            v-if="isVisible" 
            data-bulk-upload-detail 
            class="bulk-upload-detail"
        >
            <button 
                class="bulk-upload-detail__icon-close"
                @click="onCloseClick"
            >
                <cross-large :aria-label="ariaLabel" />
            </button>
            
            <template v-if="detail.hasInfo()">
                <h3 class="bulk-upload-detail__title">{{ sectionTitle }}</h3>
                <list>
                    <list-item 
                        v-for="(item, key) in detailInfo"
                        :key="'info'+key"
                        class="bulk-upload-detail__list-item"
                    >
                        <template slot="key">{{ key }}</template>
                        <template slot="value">
                            <span :class="getClass(item, key)">
                                {{ getFormat(item, key) }}
                            </span>
                        </template>
                    </list-item>
                </list>
            </template>
            <template v-if="detail.hasFileErrors() || detail.hasReleases()">
                <div class="ui-dialog__alert alert-large alert--error">
                    <div class="ui-dialog__alert-text alert-large">
                        {{ countErrors() }}
                    </div>
                </div>
            </template>
            <template v-if="detail.hasFileErrors()">
                <h3 class="bulk-upload-detail__title">{{ fileErrors }}</h3>
                <div v-if="detail.hasFileGeneralErrors()">
                    <h4 class="subtitle">{{ fileGeneralErrors }}</h4>
                    <template v-if="detail.fileErrors.general">
                        <error-line 
                            v-for="(error, errorIndex) in detail.fileErrors.general"
                            :key="'general_error'+errorIndex"
                            :error="error"
                        />
                    </template>
                </div>
                <div v-if="detail.hasFileMetadataErrors()">
                    <h4 class="subtitle">{{ fileMetadataErrors }}</h4>
                    <error-line 
                        v-for="(error, errorIndex) in detail.fileErrors.metadata"
                        :key="'metadata_error'+errorIndex" 
                        :error="error"
                    />
                </div>
            </template>
            <template v-if="detail.hasReleases()">
                <h3 class="bulk-upload-detail__title">{{ releasesErrors }}</h3>
                <view-more 
                    v-for="(release, index) in detail.releases" 
                    :key="'release'+index"
                    :error="release.errors ? true : false"
                    :title="releaseTitle(index + 1)"
                >
                    <div class="ui-dialog__alert alert-large alert--error">
                        <div class="ui-dialog__alert-text alert-large">
                            {{ errorListTitle }}
                        </div>
                        <ul class="ui-dialog__alert-list">
                            <error-line 
                                v-for="(error, errorIndex) in release.errors"
                                :listItem="true"
                                :key="'release_error'+errorIndex" 
                                :error="error"
                            />
                        </ul>
                    </div>
                    
                    <view-more :title="generalInfo">
                        <list>
                            <list-item
                                v-for="(item, releaseIndex) in release.info"
                                :key="'release_info'+releaseIndex"
                                class="bulk-upload-detail__list-item"
                            >
                                <template slot="key">{{ releaseIndex }}</template>
                                <template slot="value">{{ item }}</template>
                            </list-item>
                        </list>
                    </view-more>
                    <view-more
                        v-for="(track, trackIndex) in release.tracks" 
                        :key="'track'+trackIndex"
                        :title="trackTitle(trackIndex + 1)"
                    >
                        <list>
                            <list-item 
                                v-for="(trackItem, trackItemKey) in track"
                                :key="'track_item'+trackItemKey"
                                class="bulk-upload-detail__list-item"
                            >
                                <template slot="key">{{ trackItemKey }}</template>
                                <template slot="value">{{ trackItem }}</template>
                            </list-item>
                        </list>
                    </view-more>
                </view-more>
            </template>
        </div>
    </transition>
</template>

<script>
    import bulkUploadDetailRepository from '../models/bulk-upload-detail/bulk-upload-detail-repository'
    import Bus from '../bus';
    import CrossLarge from '../../svg/cross-large.svg'
    import ErrorLine from './error-line.vue'
    import List from './list.vue'
    import ListItem from './list-item.vue'
    import ViewMore from './view-more.vue'
    import { _T } from '../globals'

    export default {
        props: {
            ariaLabel: {
                type: String,
                default: _T('close')
            },
            errorListTitle: {
                type: String,
                default: _T('bulkupload_details_error_list_title')
            },
            fileErrors: {
                type: String,
                default: _T('bulkupload_details_file_errors')
            },
            fileGeneralErrors: {
                type: String,
                default: _T('bulkupload_details_general_errors')
            },
            fileMetadataErrors: {
                type: String,
                default: _T('bulkupload_details_metadata_errors')
            },
            generalInfo: {
                type: String,
                default: _T('bulkupload_details_general_info')
            },
            releasesErrors: {
                type: String,
                default: _T('releases')
            },
            sectionTitle: {
                type: String,
                default: _T('details')
            },
            uuid: {
                type: String,
                default: ''
            }
        },
        components: {
            CrossLarge,
            ErrorLine,
            List,
            ListItem,
            ViewMore
        },
        data: () => ({
            detail: null,
            detailInfo: {},
            errorCount: null,
            translationKeys: {
                release: 'bulkupload_details_release',
                track: 'bulkupload_details_track'
            }
        }),
        mounted () {
            bulkUploadDetailRepository.findByUUId(this.uuid)
                .then((data) => {
                    this.detail = data;
                    this.detailInfo = {
                        uuid: data.info.uuid,
                        created_at: data.info.created_at,
                        completed_at: data.info.completed_at,
                        description: data.info.description,
                        format: data.info.format,
                        version: data.info.version,
                        total_releases: data.info.total_releases,
                        total_tracks: data.info.total_tracks,
                        succeed_releases: data.info.succeed_releases,
                        succeed_tracks: data.info.succeed_tracks,
                        failed_releases: data.info.failed_releases,
                        failed_tracks: data.info.failed_tracks,
                        status: data.info.status
                    }
                    this.$emit('loadEnd');
                })
                .catch(() => {
                    this.$emit('loadEnd');
                });
            Bus.$on('fileUploaded', () => {
                this.onCloseClick();
            })
        },
        computed: {
            isVisible() {
                return this.detail;
            }
        },
        methods: {
            /**
             * Hides Details section
             */
            onCloseClick() {
                this.$emit('close');
            },
            /**
             * Formats release/track title's number
             */
            doubleDigits(number) {
                return number < 10 ? (`0${number}`).slice(-2) : number
            },
            /**
             * Sets release's translation & format for view-more head title
             */
            releaseTitle(index) {
                return _T(this.translationKeys.release, [this.doubleDigits(index)]);
            },
            /**
             * Sets track's translation & format for view-more head title
             */
            trackTitle(index) {
                return _T(this.translationKeys.track, [this.doubleDigits(index)]);
            },
            /**
             * Applies a branded color class according to BU process's status
             */
            getClass(item, key) {
                return key === 'status' ? `bulk-upload__status--${item.toLowerCase()}` : null
            },
            /**
             * Formats data for a better UI display
             */
            getFormat (item, key) {
                if (key === 'created_at' || key === 'completed_at') {
                    return item !== null ? this.formatDate(item) : item
                } else if (key === 'status') {
                    return item.includes('_') ? item.replace('_', '\t').toLowerCase() : item.toLowerCase();
                } else {
                    return item
                }
            },
            /**
             * Formats date for a better UI display
             */
            formatDate (item) {
                return new Date(item).toLocaleString()
            },
            /**
             * Gets file & releases errors and returns a global sum
             */
            countErrors () {
                // File Errors Count
                const fileErrorsGeneral = this.detail.fileErrors.general 
                const fileErrorsMetadata = this.detail.fileErrors.metadata
                const fileErrorsCount = (fileErrorsGeneral ? fileErrorsGeneral.length ? fileErrorsGeneral.length
                    : fileErrorsGeneral.errors ? fileErrorsGeneral.errors.length ? fileErrorsGeneral.errors.length 
                        : 0 : 0 : 0)
                + (fileErrorsMetadata ? fileErrorsMetadata.length ? fileErrorsMetadata.length
                    : fileErrorsMetadata.errors ? fileErrorsMetadata.errors.length ? fileErrorsMetadata.errors.length 
                        : 0 : 0 : 0)

                // Release Errors Count
                let releaseErrorsCount = 0;
                this.detail.releases.forEach(
                    release => releaseErrorsCount = releaseErrorsCount + release.errors.length
                )

                //Global errors Count
                this.errorCount = fileErrorsCount + releaseErrorsCount

                return this.errorCount === 1 ?
                    this.errorCount + '\t' + _T('error')
                    : this.errorCount + '\t' + _T('errors_found')
                
            }
        }
    };
</script>

<style lang="scss">
    .bulk-upload-detail {
        width: 100%;
        position: relative;
        margin-bottom: 2.5rem;
        padding: 0 1.5rem 1.5rem 1.5rem;
        border: 0.125rem solid #d7d7d7;
        border-radius: 0.1875rem;

        &__icon-close {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            line-height: 0.8;

            &:hover {
                opacity: 0.5;
            }
        }

        &__title {
            padding: 1.5rem 0 1rem 0;
            margin-bottom: 0;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 1.575rem;
            text-transform: capitalize;
        }

        &__list-item {
            font-size: 0.875rem;
            line-height: 1.225rem;

            &--grey {
                color: #787878;
            }
        }
    }

    .bulk-upload {
        &__status {
            &--failed,
            &--processed,
            &--pending,
            &--in_progress {
                padding: 0.125rem 0.5rem;
                border-radius: 0.125rem;
                font-weight: 500;
                text-transform: capitalize;
            }

            &--failed {
                color:#ff5350;
                background-color: #ffeeed;
            }

            &--processed {
                color: #2bac76;
                background-color: #eaf6f1;
            }

            &--pending {
                color: #e9a300;
                background-color: #fdf6E5;
            }

            &--in_progress {
                color: #00b0ff;
                background-color: #ccefff;
            }
        }
    }
</style>
