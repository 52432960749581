<style lang="scss">
    @import '../../scss/settings';

    $subNavigationBottomSpacing: 0.625rem;
    $subNavigationLineSize: 0.25rem;

    .sub-navigation {
        display: inline-block;
        border-bottom: 0.0625rem solid $softGray;
        margin: $spacing*2 0;
        padding-bottom: $subNavigationBottomSpacing;
    }

    .sub-navigation__item {
        position: relative;
        display: inline-block;
        margin-right: 1.25rem;
        padding: 0 0.125rem;

        &.sub-navigation__item--selected {
            &:after {
                position: absolute;
                bottom: -($subNavigationBottomSpacing + $subNavigationLineSize/2);
                left: 0;
                width: 100%;
                height: $subNavigationLineSize;
                background: $primary;
                content: '';
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .sub-navigation__link {
        font-size: 1rem;
    }
</style>

<template>
    <ul class="sub-navigation">
        <li class="sub-navigation__item" v-for="(link, index) in links" :key="index"
            :class="{'sub-navigation__item--selected': link.selected}">
            <a class="sub-navigation__link"
               :href="link.href" :title="link.text">{{link.text}}</a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: ['links']
    };
</script>
