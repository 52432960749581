import Vue from 'vue';
import { MX2Config } from '../globals';
import HeaderLogo from '../components/header-logo.vue';
import UserNavigation from '../components/user-navigation.vue';

export default {
    load(code) {
        return new Vue({
            el: '#header',
            data: {
                code,
                languageMenu: MX2Config.languageMenu,
                userMenu: MX2Config.userMenu
            },
            components: { HeaderLogo, UserNavigation }
        });
    }
};
