import PlayableAudio from './playable-audio';
import { AUDIO_FILE_STATE } from '../constants/constants';

export default class AudioFile {
    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.audio = {
            full: data.audio.full !== undefined ?
                new PlayableAudio(data.audio.full) :
                undefined,
            original: data.audio.original !== undefined ?
                new PlayableAudio(data.audio.original) :
                undefined
        };
        this.uploader = data.uploader;
        this.playable = this.audio.full !== undefined && this.audio.full.state === AUDIO_FILE_STATE.READY ?
            this.audio.full :
            undefined;
    }

    removeAudio() {
        this.audio = {
            full: undefined,
            original: undefined
        };
    }

    isProcessing() {
        return (this.audio.full !== undefined && this.audio.full.state === AUDIO_FILE_STATE.TRANSCODING) ||
            (this.audio.original !== undefined && this.audio.original.state === AUDIO_FILE_STATE.TRANSCODING);
    }

    isProcessed() {
        return this.hasAllAudio();
    }

    isPlayable() {
        return this.playable !== undefined;
    }

    hasAudio() {
        return this.hasFullAudio() || this.hasOriginalAudio();
    }

    hasFullAudio() {
        return this.audio.full !== undefined && this.audio.full.state === AUDIO_FILE_STATE.READY;
    }

    hasOriginalAudio() {
        return this.audio.original !== undefined && this.audio.original.state === AUDIO_FILE_STATE.READY;
    }

    hasAllAudio() {
        return this.hasFullAudio() && this.hasOriginalAudio();
    }
}
