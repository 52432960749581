import Vue from 'vue';
import AppFooter from './footer.vue';

export default {
    load({ navigation }) {
        return new Vue({
            el: '#footer',
            template: `<app-footer :links="links"></app-footer>`,
            data: {
                links: navigation
            },
            components: { AppFooter }
        });
    }
};
