<style lang="scss">
    @import '../../scss/settings';
    $topMenuIconSize: 1.75rem;

    .top-menu {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 auto $spacing*2 auto;
        width: $wrapperSize + ($spacing*4);
        z-index: 2;
    }

    .top-menu__actions {
        display: flex;
        margin-left: $spacing*2;
    }

    .top-menu__action {
        position: relative;
        margin-right: $spacing*2;

        &:last-child {
            margin-right: 0;
        }
    }

    .top-menu__action-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: $topMenuIconSize;
        height: $topMenuIconSize;
        fill: $darkGray;
        cursor: pointer;

        &:before {
            position: absolute;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.1);
            content: '';
        }

        &.top-menu__action-icon--active {
            background: $darkGray;
            fill: $softerGray;

            &:before {
                background: transparent;
            }
        }

        &.top-menu__action-icon--small {
            svg {
                width: 45%;
                height: 45%;
            }
        }

        svg {
            display: block;
            width: 60%;
            height: 60%;
            z-index: 1;
        }
    }

    .top-menu__action-menu {
        position: absolute;
        bottom: -0.375rem;
        right: 0;
        transform: translateY(100%);
    }
</style>

<template>
    <div class="top-menu">
        <inline-menu :links="links"></inline-menu>
        <ul class="top-menu__actions">
            <li class="top-menu__action" v-if="canSwitchUser && !isAdminDisguised">
                <span class="top-menu__action-icon" @click="logAsUser">
                    <micro-phone-icon></micro-phone-icon>
                </span>
            </li>
            <li class="top-menu__action" v-if="isAdminDisguised">
                <span :class="smallIconClass"
                      @click="leave">
                    <cross-thin-icon></cross-thin-icon>
                </span>
            </li>
            <li class="top-menu__action">
                <span class="top-menu__action-icon"
                      :class="userMenuClass"
                      @click="toggleUserMenu">
                    <user-icon></user-icon>
                </span>
                <user-menu class="top-menu__action-menu"
                           :languages="languages"
                           v-if="isUserMenuVisible"></user-menu>
            </li>
        </ul>
    </div>
</template>

<script>
    import UserMenu from './user-menu.vue';
    import UserIcon from '../../svg/user.svg';
    import CrossThinIcon from '../../svg/cross-thin.svg';
    import MicroPhoneIcon from '../../svg/microphone.svg';
    import User from '../models/user';
    import { _T, PromptLine, loginAsUser } from '../globals';
    import InlineMenu from './inline-menu.vue';

    export default {
        props: ['options', 'isMyAccountActive'],
        components: {
            InlineMenu,
            UserMenu,
            UserIcon,
            CrossThinIcon,
            MicroPhoneIcon
        },
        data() {
            return {
                links: this.options.navigation,
                languages: this.options.languages,
                canSwitchUser: this.options.can_switch_user,
                isAdminDisguised: this.options.is_admin_disguised,
                isUserMenuVisible: false
            };
        },
        methods: {
            toggleUserMenu() {
                this.isUserMenuVisible = !this.isUserMenuVisible;
            },
            leave() {
                document.body.style.cursor = 'wait';

                User.leave()
                    .then(() => {
                        window.location.href = '/';
                        document.body.style.cursor = 'default';
                    }).catch(() => {
                        document.body.style.cursor = 'default';
                    });
            },
            logAsUser() {
                PromptLine(_T('enter user email you want to login as'), (_login) => {
                    if (_login.length) {
                        loginAsUser(_login);
                    }
                });
            }
        },
        computed: {
            smallIconClass() {
                return 'top-menu__action-icon top-menu__action-icon--active top-menu__action-icon--small';
            },
            userMenuClass() {
                return {
                    'top-menu__action-icon--active': (this.isUserMenuVisible || this.isMyAccountActive)
                };
            }
        }
    };
</script>
