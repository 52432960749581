import axios from 'axios';
import Vue from 'vue';
import TrackManager from '../../components/track-manager.vue';
import Album from '../../models/album';
import { API } from '../../constants/constants';
import { _T } from '../../globals';

export default {
    load(album) {
        return new Vue({
            el: '#albumsTracksPage',
            components: { TrackManager },
            data: {
                album: new Album(album)
            }
        });
    },
    unblock(releaseId){
        axios.post(API.RELEASE_UNBLOCK.replace('[id]', releaseId), releaseId)
            .then(() => {
                alert(_T('QC removed for this release'));
                window.location.reload();
            })
            .catch((e) => {
                console.error(e);
                alert(_T('error'));
            });
    }
};
