<style lang="scss">
    .asset-track {
        margin-top: -1px;
        border: 1px solid #C3C4BE;
        padding: 10px;
        background: #FFFFFF;
        text-align: left;
        overflow: hidden;

        &:first-of-type {
            border-radius: 3px 3px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 3px 3px;
        }
    }

    .asset-track__head {
        display: flex;
    }

    .asset-track__info {
        flex: 1;
        padding: 0 10px;
    }

    .asset-track__length {
        padding: 0 10px 0 0;
        color: #C3C4BE;
    }

    .asset-track__icon {
        display: flex;
        width: 16px;
        height: 16px;
        fill: #C3C4BE;

        &.asset-track__icon--loading {
            animation: spinner 1s infinite steps(8);
        }

        &.asset-track__icon--clickable {
            cursor: pointer;
        }

        &.asset-track__icon--analysis {
            fill: orange;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        &.asset-track__icon--left-spaced {
            margin-left: 8px;
        }

        &.asset-track__icon--right-spaced {
            margin-right: 8px;
        }
    }

    .asset-track__body {
        margin: 10px 0 0 0;
    }
</style>

<template>
    <div class="asset-track">
        <div class="asset-track__head">
            <span class="asset-track__icon asset-track__icon--loading" v-if="isTrackLoading">
                <spinner-icon></spinner-icon>
            </span>
            <span class="asset-track__icon asset-track__icon--clickable"
                  v-else-if="!isTrackLoading && trackEntity.isPlayable()" @click="play">
                <play-icon viewBox="0 0 32 32"></play-icon>
            </span>
            <span class="asset-track__icon"
                  v-else-if="!isTrackLoading && !trackEntity.isPlayable()">
                <warning-icon viewBox="0 0 32 32"></warning-icon>
            </span>
            <span class="asset-track__info">
                {{formattedIndex}} - {{releaseMetadataTrack.original.title}}
                <strong v-if="releaseMetadataTrack.original.remix_or_version">
                    ({{releaseMetadataTrack.original.remix_or_version}})
                </strong>
            </span>
            <span v-if="hasIssues" class="asset-track__icon asset-track__icon--analysis asset-track__icon--right-spaced">
                <warning-icon viewBox="0 0 32 32" v-on:click="showAnalysisDetails()"></warning-icon>
            </span>
            <span v-if="trackLength" class="asset-track__length">{{trackLength}}</span>
              <a class="asset-track__icon"
               target="_blank"
               :class="editClass" :href="trackLink" v-if="trackLink">
                <component viewBox="0 0 32 32" :is="editIcon"></component>
            </a>
            <span :class="statusClass"
                  v-if="hasInfo || hasArtists || hasAudio"
                  @click="toggle">
                <component viewBox="0 0 32 32" :is="statusIcon"></component>
            </span>
        </div>
        <transition name="show-content">
            <div class="asset-track__body"
                 v-if="isOpen && (hasInfo || hasArtists || hasAudio)">
                <template v-if="hasInfo">
                    <list>
                        <list-item v-for="(value, key) in data.info"
                                   :key="key" inline="true">
                            <template slot="key">{{key}}</template>
                            <template slot="value">{{value}}</template>
                        </list-item>
                    </list>
                </template>
                <template  v-if="hasArtists">
                    <h3 class="title">{{titles.artists}}</h3>
                    <list>
                        <list-item v-for="(value, key) in data.artists"
                                   :key="key" inline="true">
                            <template slot="key">{{translate(key)}}</template>
                            <template slot="value">
                                <span v-for="(name, index) in value" :key="index">
                                    <strong>{{name}}</strong>
                                    <span v-if="index + 1 < value.length"> | </span>
                                </span>
                            </template>
                        </list-item>
                    </list>
                </template>
                <template v-if="hasAudio">
                    <h3 class="title">{{titles.audio}}</h3>
                    <list>
                        <list-item v-for="(value, key) in data.audio"
                                   :key="key" inline="true">
                            <template slot="key">{{key}}</template>
                            <template slot="value">{{value}}</template>
                        </list-item>
                    </list>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
    import ArtistBox from './artist-box.vue';
    import List from './list.vue';
    import ListItem from './list-item.vue';
    import EnlargeIcon from '../../svg/enlarge.svg';
    import PencilIcon from '../../svg/pencil.svg';
    import PlayIcon from '../../svg/play.svg';
    import ShrinkIcon from '../../svg/shrink.svg';
    import SpinnerIcon from '../../svg/spinner.svg';
    import WarningIcon from '../../svg/warning.svg';
    import Bus from '../bus';
    import { _T } from '../globals';
    import Time from '../helpers/time';

    export default {
        props: ['releaseMetadataTrack', 'index', 'extended'],
        components: {
            ArtistBox,
            EnlargeIcon,
            PencilIcon,
            PlayIcon,
            ShrinkIcon,
            SpinnerIcon,
            WarningIcon,
            List,
            ListItem
        },
        data() {
            return {
                isOpen: false,
                trackEntity: null,
                titles: {
                    artists: _T('metadata_version_artists'),
                    audio: _T('metadata_version_audio')
                }
            };
        },
        mounted() {
            this.releaseMetadataTrack.getTrack()
                .then((track) => {
                    this.trackEntity = track;
                })
                .catch(() => {
                    this.trackEntity = {
                        isPlayable: () => false
                    };
                });
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
            play() {
                Bus.$emit('play.track', this.trackEntity);
            },
            translate(string) {
                return _T(string);
            },
            showAnalysisDetails() {
                var analysisList = this.trackEntity.audio.original.analysis;
                if(analysisList.length > 0 ) {
                    const analysis = analysisList[0];
                    const audioAnalysisDetailsDialog = new window.AudioAnalysisDetailsDialog({
                        container: window.$('#analysis_details'),
                        initial: analysis,
                        track_title: this.trackEntity.title
                    });
                    audioAnalysisDetailsDialog.show();
                }
            }
        },
        computed: {
            data() {
                return this.extended ?
                    this.releaseMetadataTrack.extended :
                    this.releaseMetadataTrack.basic;
            },
            statusIcon() {
                return this.isOpen ? 'shrink-icon' : 'enlarge-icon';
            },
            statusClass() {
                return 'asset-track__icon asset-track__icon--clickable asset-track__icon--left-spaced';
            },
            editIcon() {
                return this.isTrackLoading ? 'spinner-icon' : 'pencil-icon';
            },
            formattedIndex() {
                return (`0${this.index}`).slice(-2);
            },
            isTrackLoading() {
                return this.trackEntity === null;
            },
            hasInfo() {
                return !!Object.keys(this.data.info).length;
            },
            hasArtists() {
                return !!Object.keys(this.data.artists).length;
            },
            hasAudio() {
                return !!Object.keys(this.data.audio).length;
            },
            trackLength() {
                if (this.isTrackLoading) {
                    return null;
                }

                if (!this.trackEntity.audio.full) {
                    return null;
                }

                return Time.formatMilliseconds(this.trackEntity.audio.full.time);
            },
            trackLink() {
                return this.isTrackLoading ? null : this.trackEntity.link;
            },
            editClass() {
                return { 'asset-track__icon--loading': !this.trackLink };
            },
            hasIssues() {
                if (this.isTrackLoading) {
                    return null;
                }

                if (!this.trackEntity.audio.original || this.trackEntity.audio.original.analysis.length == 0) {
                    return null;
                }

                let analysys = this.trackEntity.audio.original.analysis[0];
                return analysys.has_issues == 1 ? true : false;
            }
        }
    };
</script>
