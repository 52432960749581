<style lang="scss">
    .track-item {
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid #C3C4BE;
        margin-top: -1px;
        padding: 10px;
        background: #FFFFFF;

        &.track-item--ghost {
            background: lighten(#C3C4BE, 15%);
            opacity: 0.5;
        }

        &.track-item--sortable {
            cursor: move;
        }

        &:first-of-type {
            border-radius: 3px 3px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 3px 3px;
        }
    }

    .track-item__icons {
        display: flex;

        &.track-item__icons--right {
            margin-left: 8px;
            .track-item__icon:last-child {
                margin-right: 0;
            }
        }
        z-index: 1;
    }

    .track-item__icon {
        display: inline-block;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        fill: #C3C4BE;
        cursor: pointer;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        &.track-item__icon--spinning {
            animation: spinner 1s infinite steps(8);
        }

        &.asset-track__icon--analysis {
          fill: orange;
        }
    }

    .track-item__info {
        flex: 1;
        z-index: 1;
    }

    .track-item__artists {
        display: block;
        font-size: 10px;
        color: darken(#C3C4BE, 15%);
    }

    .track-item__upload {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #3c763d;
        opacity: 0.1;
        z-index: 0;
    }
</style>

<template>
    <li class="track-item" :class="{'track-item--sortable' : isSortable && disabledactiontracks}">
        <span class="track-item__upload" :style="{width: getPercentage}"></span>
        <span class="track-item__icons">
            <span class="track-item__icon" v-if="isSortable && disabledactiontracks">
                <draggable-icon viewBox="0 0 32 32"></draggable-icon>
            </span>
            <span class="track-item__icon" @click="play" v-if="isPlayable">
                <play-icon viewBox="0 0 32 32"></play-icon>
            </span>
            <span class="track-item__icon track-item__icon--spinning" v-if="isLoading">
                <spinner-icon viewBox="0 0 32 32"></spinner-icon>
            </span>
        </span>
        <span class="track-item__info">
            <span>
                {{trackFullName}}
                <span v-if="track.remix_or_version">({{track.remix_or_version}})</span>
            </span>
            <span class="track-item__artists">{{trackArtistInfo}}</span>
        </span>
        <span class="track-item__icons track-item__icons--right">
            <span v-if="hasIssues"
                 class="track-item__icon asset-track__icon--analysis"
            >
                <warning-icon viewBox="0 0 32 32" v-on:click="showAnalysisDetails()"></warning-icon>
            </span>
            <a class="track-item__icon"
               :href="'/tracks/' + track.id" v-if="isEditable && !isLoading">
               <pencil-icon viewBox="0 0 32 32"></pencil-icon>
           </a>
           <span v-if="isRemovable && disabledactiontracks && !isLoading"
                 class="track-item__icon"
                 @click="remove"
           >
                <cross-icon viewBox="0 0 32 32"></cross-icon>
           </span>
            <span class="track-item__icon" @click="add" v-if="isAppendable">
                <plus-icon viewBox="0 0 32 32"></plus-icon>
            </span>
        </span>
    </li>
</template>

<script>
    import CrossIcon from '../../svg/cross.svg';
    import DraggableIcon from '../../svg/draggable.svg';
    import PencilIcon from '../../svg/pencil.svg';
    import PlayIcon from '../../svg/play.svg';
    import PlusIcon from '../../svg/plus.svg';
    import SpinnerIcon from '../../svg/spinner.svg';
    import WarningIcon from '../../svg/warning.svg';
    import Bus from '../bus';
    import { TRACK_TYPE } from '../constants/constants';
    import { _T } from '../globals';

    export default {
        props: ['track', 'index', 'types', 'disabledactiontracks'],
        components: {
            CrossIcon,
            DraggableIcon,
            PencilIcon,
            PlayIcon,
            PlusIcon,
            SpinnerIcon,
            WarningIcon
        },
        data() {
            return {
                messages: {
                    noArtist: _T('no_artist')
                }
            };
        },
        methods: {
            add() {
                this.$emit('add', this.track);
            },
            play() {
                Bus.$emit('play.track', this.track);
            },
            remove() {
                this.$emit('remove', this.index);
            },
            showAnalysisDetails() {
                var analysisList = this.track.audio.original.analysis;
                if(analysisList.length > 0 ) {
                    const analysis = analysisList[0];
                    const audioAnalysisDetailsDialog = new window.AudioAnalysisDetailsDialog({
                        container: window.$('#analysis_details'),
                        initial: analysis,
                        track_title: this.track.title
                    });
                    audioAnalysisDetailsDialog.show();
                }
            }
        },
        computed: {
            trackFullName() {
                let fullName = this.track.title;

                if (this.index !== undefined) {
                    const doubleDigit = `0${(this.index + 1)}`.slice(-2);

                    fullName = `${doubleDigit} - ${fullName}`;
                }

                return fullName;
            },
            trackArtistInfo() {
                return this.track.display_artist ?
                    this.track.display_artist :
                    this.messages.noArtist;
            },
            getPercentage() {
                return this.track.uploader === undefined ? '0%' : `${this.track.uploader.percentage}%`;
            },
            isLoading() {
                return this.track.uploader === undefined ? false : this.track.uploader.isBusy();
            },
            isAppendable() {
                return this.types.indexOf(TRACK_TYPE.APPENDABLE) > -1;
            },
            isEditable() {
                return this.types.indexOf(TRACK_TYPE.EDITABLE) > -1;
            },
            isPlayable() {
                return this.track.isPlayable();
            },
            isRemovable() {
                return this.types.indexOf(TRACK_TYPE.REMOVABLE) > -1;
            },
            isSortable() {
                return this.types.indexOf(TRACK_TYPE.SORTABLE) > -1;
            },
            hasIssues() {
                if (!this.track.audio.original || !this.track.audio.original.analysis) {
                    return null;
                }

                if (this.track.audio.original.analysis.length == 0) {
                    return null;
                }

                let analysys = this.track.audio.original.analysis[0];
                return analysys.has_issues == 1 ? true : false;
            }
        }
    };
</script>
