import Vue from 'vue';
import SearchBoxDate from './components/search-box-date.vue';

export default class DateAmount {
    constructor() {
        return this.constructor.load();
    }

    static load() {
        return new Vue({
            el: '#searchBoxDateApp',
            components: { SearchBoxDate },
            methods: {
                search(query, filters) {
                    this.onSearched(query, filters);
                },
                onSearched() {}
            }
        });
    }
}
