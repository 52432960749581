import {_T} from '../globals';

export default {
    htmlOptions: {
        root: document.querySelector('#label'),
        placeholderText: _T('label')
    },
    onOptionSelect(item){

        const labelHidden = document.querySelector('#label_id');
        labelHidden.value = item.uuid;
    },
    renderOption(item) {
        const name = item.name;

        return `<span/>${name}</span>`;
    },
    getInputValue(item) {
        return item.name;
    },
    async fetchData(value) {

        const userSelector = document.querySelector('#user')
        let url = '/api/1.0/labels?sort=name&filter[state]=distributed&filter[' +
            encodeURIComponent('+name') + ']='+
            encodeURIComponent('%' + value + '%');

        if (userSelector !== null) {
            const userInput = userSelector.querySelector('input');
            if (userInput.value) {
                url += '&filter[' + encodeURIComponent('email') + ']=' + encodeURIComponent(userInput.value);
            }
        }

        try {
            const response = await fetch(url);
            const data = await response.json();

            return data.items;
        } catch (e) {
            alert('Could not fetch details');
            throw e;
        }
    }
};

