export default {
    formatSeconds(time) {
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);

        minutes = (`0${minutes}`).slice(-2);
        seconds = (`0${seconds}`).slice(-2);

        return `${minutes}:${seconds}`;
    },
    formatMilliseconds(time) {
        const totalMilliseconds = Math.floor(time / 1000);
        let minutes = Math.floor(totalMilliseconds / 60);
        let seconds = totalMilliseconds - (minutes * 60);

        minutes = (`0${minutes}`).slice(-2);
        seconds = (`0${seconds}`).slice(-2);

        return `${minutes}:${seconds}`;
    },
    setDateByYearMonth(year, month) {
        let date = new Date(year, 0);

        date = new Date(date.setMonth(month - 1));

        return date;
    }
};
