<template>
    <div
        ref="dropZone"
        :class="`${dropClass} ${dropClass}--${component}`"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @dragover.prevent
        @drop.prevent="dropFile"
    >   
        <template v-if="isLoading">
            <loading-overlay />
        </template>
        <slot></slot>
    </div>
</template>

<script>
import LoadingOverlay from './loading-overlay.vue';

export default {
    components: { LoadingOverlay },
    props: {
        component: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isHoveringDropZone: false
    }),
    computed: {
        /**
     * Gets dropped files
     */
        dropZone: {
            cache: false,
            get () {
                return this.$refs.dropZone
            }
        },
        /**
     * Sets dropZone class according to hover state
     */
        dropClass () {
            return this.isHoveringDropZone ? 'drop-zone__hover' : 'drop-zone'
        }
    },
    methods: {
        /**
     * Keeps track of hovering on drag/drop Zone
     */
        dragEnter () {
            this.isHoveringDropZone = true
        },
        /**
     * Keeps track of hovering on drag/drop Zone
     */
        dragLeave () {
            this.isHoveringDropZone = false
        },
        /**
     * Keeps track of dropped content
     */
        dropFile ($event) {
            this.isHoveringDropZone = false
            this.$emit('dropFile', $event)
        }
    }
};
</script>

<style lang="scss">
.drop-zone {
    background-color: #FFF;

    &__hover {
        background-color: #d7d7d7;
    }
}

.drop-zone,
.drop-zone__hover {
    display: flex;
    border-radius: 0.3125rem;
    transition: background-color 0.2s;

    &--file-upload {
        border: 0.125rem dashed #d7d7d7;
        padding: 2rem;
    }

    &--image-upload {
        position: relative;
    }
}
</style>
