export default [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
    '#5A5A59',
    '#000000',
    '#FEFE01',
    '#FBB1FE',
    '#3498DB',
    '#A9DFBF',
    '#AEB6BF',
    '#F7DC6F',
    '#32E30B',
    '#E48AFF',
    '#410452',
    '#808000',
    '#FFF8DC',
    '#FFB90F',
    '#FFE4B5',
    '#EE8262',
    '#EAEAEA',
    '#B4EEB4',
    '#00E5EE',
    '#2916F5',
    '#95B9C7',
    '#AAF0D1',
    '#008B8B',
    '#033E3E',
    '#78866B',
    '#347C2C',
    '#FBE7A1',
    '#FFD801',
    '#513B1C',
    '#A0522D',
    '#C34A2C'
];
