<style lang="scss">
    .progress-status-bar {
        margin-bottom: 3em;
    }

    .progress-status-bar__group {
        display: table;
        list-style-type: none;
        margin: 0;
        padding: 0;
        table-layout: fixed;
        width: 100%;
    }

    .progress-status-bar__item {
        display: table-cell;
        color: silver;
        font-size: 1.25em;
    }

    .progress-status-bar__item:first-letter {
        text-transform:capitalize;
    }

    .progress-status-bar__item--done {
        color: black;
        font-weight: 700;
        border-bottom: 4px solid greenyellow;
    }

    .progress-status-bar__details {
        font-size: 0.75em;
        display: block;
    }

    .progress-status-bar__item--active {
        color: black;
        border-bottom: 4px solid yellow;
    }

    .progress-status-bar__item--todo {
        border-bottom: 4px solid silver;
    }
</style>

<template>
    <div class="progress-status-bar">
        <ol class="progress-status-bar__group">
            <li v-for="(value, index) in steps" class="progress-status-bar__item"
                :class="getStatusClass(value, index)" :key="index">
                {{ translateTab(index) }}
                <span class="progress-status-bar__details">{{ value }}</span>
            </li>
        </ol>
    </div>
</template>

<script>
    import { PROGRESS_STATUS_BAR_CLASSES } from '../constants/constants';
    import { _T } from '../globals';

    export default {
        props: ['active', 'steps'],
        methods: {
            getStatusClass(value, index) {
                if (index === this.active) {
                    return PROGRESS_STATUS_BAR_CLASSES.ACTIVE;
                }
                return value
                    ? PROGRESS_STATUS_BAR_CLASSES.DONE
                    : PROGRESS_STATUS_BAR_CLASSES.TODO;
            },
            translateTab(string) {
                return _T(`tab_${string}`);
            }
        }
    };
</script>
