import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';

import InputTimepicker from '../../components/form-elements/input-timepicker.vue';
import AudioUpload from '../../components/audio-upload-release.vue';
import InputLabel from '../../components/form-elements/input-label.vue';
import InputText from '../../components/form-elements/input-text.vue';

import Track from '../../models/track';
import TrackProcessor from '../../tools/track-processor';
import {_T} from '../../globals';
import {AUDIO_FILE_TYPES} from '../../constants/constants';

Vue.use(InputTimepicker, Vue2TouchEvents, {
    disableClick: true
})

var trackEd = {};

function load(track, audioLength, startPoint) {
    const audioFile = new Track(track);
    const processor = new TrackProcessor(audioFile.id);
    const restrictions = _T('track audio file restrictions');

    let trackComponent = new Vue({
        el: '#tracksInfoPage',
        name: 'tracksInfoPage',
        components: { AudioUpload, InputTimepicker, InputLabel, InputText },
        data: {
            updated: false,
            currentTime: new Date(),
            minuteRange: [0],
            audioLength: audioLength,
            startPoint: startPoint,
            timeRange: '',
            config: { processor, restrictions, file: audioFile },
            type: AUDIO_FILE_TYPES.TRACK
        },
        mounted () {
            this.timeRange = audioLength.split(':');
            if(this.timeRange.length > 0) {
                this.minuteRange = Array.from({length: parseInt(this.timeRange[0])+1}, (v, i) => i);
            }
            this.currentTime.setMinutes(this.timeRange[0]);
            this.currentTime.setSeconds(this.timeRange[1]);
        },
        methods: {
            processing(processingData) {
                this.config.file = new Track(processingData);
            },
            clean() {
                this.config.file.removeAudio();
            },
            setStartPoint(eventData) {
                this.startPoint = `${eventData.data.mm}:${eventData.data.ss}`;
                document.getElementById('startpoint').value = Number(eventData.data.mm) * 60 + Number(eventData.data.ss);
                track.startpoint = this.startPoint;

                if (this.updated) {
                    if(trackEd) {
                        trackEd.isDirty = true;
                        trackEd.renderControls();
                    }
                } else {
                    this.updated = true;
                }
            }
        }
    });
    return trackComponent
}

function setNewData(trackEdData) {
    trackEd = trackEdData
}

export default { load, setNewData };
