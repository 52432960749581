import axios from 'axios';
import { API } from '../../constants/constants';

export default {
    /**
     * Get All Tax Rules filtered by tenant
     * @param tenantId
     * @returns {*}
     */
    getTaxRulesByTenant(tenantId) {
        return axios.get(API.TENANT_TAX_RULES.replace('[id]', tenantId));
    },
    /**
     * Create New Tax Rules by territory
     * @param newTaxRules
     * @returns {Promise<void>}
     */
    createTaxRules(newTaxRules) {
        return Promise.all(newTaxRules.map(num => axios.post(API.TAX_RULES.replace('[id]', ''), num)));
    },
    /**
     * Remove tax rule by tax_group_id
     * @param uuid
     * @returns {Promise<any> | * | Thenable<any> | PromiseLike<T | never> | Promise<T | never>}
     */
    deleteTaxRules(uuid) {
        return axios.delete(API.TAX_RULES.replace('[id]', uuid))
            .then(response => response.data);
    }
};
