<style lang="scss">
    .playlist {
        position: absolute;
        top: 0;
        width: 100%;
        transform: translateY(-100%);
        text-align: left;
    }
    .playlist__wrapper {
        position: relative;
        margin: 0 auto;
        width: 960px;
        background: #f2f2f2;
    }
    .playlist__list {
        border: 1px solid #d8d8d8;
        margin: 0;
        max-height: 271px;
        padding: 0;
        list-style: none;
        overflow: auto;
    }
    .playlist__item {
        display: block;
        border-bottom: 1px solid #d8d8d8;
        padding: 5px 10px;
        cursor: pointer;
    }
    .playlist__item.playlist__item--selected {
        background: #D9D9D9;
        cursor: default;
    }
    .playlist__item:last-child {
        border-bottom: none;
    }
    .playlist__item-number {
        color: #666666;
    }
    .playlist__item-time {
        display: inline-block;
        color: #666666;
    }
    .playlist__remove {
        display: inline-block;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        vertical-align: middle;
        cursor: pointer;
        transform: translateY(-15%);
    }
    .playlist__remove svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        vertical-align: top;
    }
    .playlist__controls {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid #d8d8d8;
        padding: 5px;
        background: #f2f2f2;
        font-size: 10px;
        transform: translateY(-100%);
    }
    .playlist__controls.playlist__controls--open {
        top: 1px;
    }
    .playlist__control {
        display: inline-block;
        width: 20px;
        height: 15px;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
    .playlist__control svg {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    .playlist__control--close {
        width: 12px;
        height: 12px;
    }
</style>
<template>
    <div class="playlist" v-if="active">
        <div class="playlist__wrapper">
            <div class="playlist__controls" :class="[ open ? 'playlist__controls--open' : '']">
                <div class="playlist__control" v-if="open" @click="toggle">
                    <minimize-icon viewBox="0 0 32 32"></minimize-icon>
                </div>
                <div class="playlist__control" v-if="!open" @click="toggle">
                    <maximize-icon viewBox="0 0 32 32"></maximize-icon>
                </div>
                <div class="playlist__control playlist__control--close" @click="clear">
                    <cross-icon viewBox="0 0 32 32"></cross-icon>
                </div>
            </div>
            <ul class="playlist__list" v-if="active && open">
                <template v-for="(track, index) in playlist.tracks">
                    <li class="playlist__item"
                        :class="[ index === playlist.index ? 'playlist__item--selected' : '']"
                        :key="index"
                        @click="select(index)">
                        <span class="playlist__remove" @click.stop="removeTrack(index)">
                            <cross-icon viewBox="0 0 32 32"></cross-icon>
                        </span>
                        <span class="playlist__item-number">{{index+1}}.</span>
                        <span class="playlist__item-name">{{track.title}}</span>
                        <span class="playlist__item-time">{{track.playable.length}}</span>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    import MinimizeIcon from '../../svg/minimize.svg';
    import MaximizeIcon from '../../svg/maximize.svg';
    import CrossIcon from '../../svg/cross.svg';

    export default {
        props: ['playlist'],
        components: { MinimizeIcon, MaximizeIcon, CrossIcon },
        data() {
            return {
                open: false
            };
        },
        methods: {
            toggle() {
                this.open = !this.open;
            },
            clear() {
                this.open = false;
                this.playlist.clear();
            },
            select(index) {
                this.playlist.playThis(index);
            },
            removeTrack(index) {
                this.playlist.remove(index);
            }
        },
        computed: {
            active() {
                return !!this.playlist.tracks.length;
            }
        }
    };
</script>
