import Tokenizer from './tokenizer';
import { API } from '../constants/constants';

export default class RingtoneTokenizer extends Tokenizer {
    constructor(ringtoneId) {
        super({
            accepted: ['flac', 'wav', 'fla'],
            url: API.RINGTONE_AUDIO_TOKENS.replace('[id]', ringtoneId)
        });
    }
}
