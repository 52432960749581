import Bus from '../bus';
import Errors from './errors';
import GUID from '../helpers/guid';

function validate(field) {
    if (!field.validation) {
        return true;
    }

    if (field.validation.required) {
        const cleanValue = field.value.trim();

        return cleanValue !== '';
    }

    return true;
}

export default class Form {
    constructor(title, data) {
        this.id = GUID.get();
        this.title = title;
        this.items = JSON.parse(JSON.stringify(data));
        this.originalData = JSON.parse(JSON.stringify(data));
        this.errors = new Errors();
        this.isUpdating = false;
    }

    values() {
        return Object.keys(this.items)
            .reduce((total, key) => ({ ...total, [key]: this.items[key].value }), {});
    }

    save() {
        this.originalData = JSON.parse(JSON.stringify(this.items));
    }

    reset() {
        this.items = JSON.parse(JSON.stringify(this.originalData));
    }

    hasChanges() {
        const hasChanges = JSON.stringify(this.items) !== JSON.stringify(this.originalData);

        if (hasChanges) {
            Bus.$emit('forms.add.edited', this.id);
        } else {
            Bus.$emit('forms.remove.edited', this.id);
        }

        return hasChanges;
    }

    isValid() {
        let validElementsCount = 0;

        Object.keys(this.items).forEach((item) => {
            if (validate(this.items[item])) {
                validElementsCount += 1;
            }
        });

        return validElementsCount === Object.keys(this.items).length;
    }
}
