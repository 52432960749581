<template>
    <div class="date-picker">
        <div class="cform__label" @click="focus" v-if="label">{{label}}</div>
        <div class="cform__input-group cform__input-group--icon-right">
            <input type="text" ref="input" :value="value" :placeholder="placeholder">
            <div class="cform__input-group__icon" @click="clean">
                <cross-icon viewBox="0 0 32 32"></cross-icon>
            </div>
        </div>
    </div>

</template>

<script>
    import CrossIcon from '../../svg/cross.svg';
    import { $ } from '../globals';

    export default {
        props: ['label', 'placeholder', 'value', 'type'],
        components: { CrossIcon },
        mounted() {
            const $input = $(this.$refs.input);

            if (this.type === 'datepicker') {
                $input.datepicker({
                    changeYear: true,
                    changeMonth: true,
                    dateFormat: $.datepicker.ISO_8601,
                    onSelect: (value) => { this.selected(value); },
                    onClose: () => {}
                });
            } else if (this.type === 'monthpicker') {
                $input.MonthPicker({
                    ShowIcon: false,
                    OnAfterMenuClose: () => {
                        this.selected($input.val());
                    }
                });
            }
        },
        methods: {
            focus() {
                this.$refs.input.focus();
            },
            selected(value) {
                this.$emit('input', value);
            },
            clean() {
                this.$refs.input.value = '';
                this.$emit('input', '');
            }
        },
        computed: {}
    };
</script>
