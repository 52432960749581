<style lang="scss">
    .date-range {
        display: inline;

        &.date-range--right {
            float: right;
        }

        .cform__group {
            display: flex;
        }
    }
    .date-range__input {
        display: inline-block;
        margin-right: 0.5rem;
        width: 10.36rem;
    }
</style>

<template>
    <div class="date-range cform cform--small">
        <div class="cform__group">
            <date-picker class="date-range__input" :placeholder="messages.from"
                         v-model="date_from" :type="type"></date-picker>
            <date-picker class="date-range__input" :placeholder="messages.to"
                         v-model="date_to" :type="type"></date-picker>
        </div>
    </div>
</template>

<script>
    import DatePicker from './date-picker.vue';
    import { _T } from '../globals';

    export default {
        props: ['from', 'to', 'type'],
        components: { DatePicker },
        data() {
            return {
                messages: {
                    from: _T('from_to_from'),
                    to: _T('from_to_to')
                },
                date_from: this.from,
                date_to: this.to
            };
        },
        watch: {
            date_from() {
                this.search();
            },
            date_to() {
                this.search();
            }
        },
        methods: {
            search() {
                this.$emit('search', {
                    date: {
                        from: this.date_from,
                        to: this.date_to
                    }
                });
            }
        }
    };
</script>
