import Vue from 'vue';
import ProgressBar from './components/progress-bar.vue';

export default class Bar {
    constructor(element) {
        this.element = element;
    }

    load(percentageValue, cuteValue, failed) {
        return new Vue({
            el: this.element,
            template: `
                <progress-bar
                    :cute="cute"
                    :error="error"
                    :percentage="percentage"
                    @updateValue="updateValue"
                />`,
            data: {
                cute: cuteValue,
                error: failed,
                percentage: percentageValue
            },
            methods: {
                updateValue(newPercentage, failed) {
                    this.error = failed
                    this.percentage = newPercentage
                }
            },
            components: { ProgressBar }
        });
    }
}
