<style lang="scss">
    
    .tenant-branding-images {

        //TODO: Remove important, we are forcing the value in legacy
        label{
            // padding: 0 !important;
        }
    }

    .tenant-branding-images__block {
        display: block;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .tenant-branding-images__label {
        display: block;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .tenant-branding-images__wrapper {
        position: relative;
        display: inline-block;
        margin-bottom: 8px;
    }

    .tenant-branding-images__helper {
        display: block;
        font-size: 12px;
        font-style: italic;
    }

    .tenant-branding-images__error {
        display: block;
        margin-bottom: 8px;
        font-size: 12px;
        color: #B70000;
    }
</style>


<template>
    <div class="tenant-branding-images">
        <div class="tenant-branding-images__block"
             v-for="(model, index) in [logo, favicon]" :key="index">
            <label>
                <span class="tenant-branding-images__label">{{model.label}}</span>
                <span class="tenant-branding-images__wrapper"
                      @mouseenter="onMouseEnter(model)"
                      @mouseleave="onMouseLeave(model)">
                    <loading-overlay v-if="model.isLoading"
                                     :type="model.loaderType"></loading-overlay>
                    <image-upload :src="model.src" :size="model.size"
                                  :edit-cover="true"
                                  :delete-cover="true"
                                  @click="onClicked(model)"
                                  @change="($e) => { onChanged($e, model); }">
                    </image-upload>
                    <tooltip v-if="model.tooltipVisible"
                            :messages="model.info"></tooltip>
                </span>
            </label>
            <div v-if="model.errors.length">
                <span :key="index"
                      class="tenant-branding-images__error"
                      v-for="(error, index) in model.errors">
                    {{error}}
                </span>
            </div>
            <span class="tenant-branding-images__helper">{{messages.helper}}</span>
        </div>
    </div>
</template>

<script>
    import { _T } from '../../../globals';
    import Bus from '../../../bus';
    import ErrorsHelper from '../../../helpers/errors';
    import ImageUpload from '../../../components/image-upload.vue';
    import Tooltip from '../../../components/tooltip.vue';
    import LoadingOverlay from '../../../components/loading-overlay.vue';

    export default {
        props: ['tenantBranding'],
        components: { LoadingOverlay, Tooltip, ImageUpload },
        data() {
            return {
                logo: {
                    label: _T('logo'),
                    action: this.tenantBranding.saveLogo.bind(this.tenantBranding),
                    onDone() {
                        Bus.$emit('refresh.logo', this.tenantBranding.logo);
                    },
                    size: '300x100',
                    src: this.tenantBranding.logo,
                    // as translation is being handled in tooltip component, we just need to add the traslation key here, without _T(..)
                    info: [
                        'branding_logo_restrictions',
                        'changes_uploaded_immediately'
                    ],
                    errors: [],
                    isLoading: false,
                    tooltipVisible: false
                },
                favicon: {
                    label: _T('favicon'),
                    action: this.tenantBranding.saveFavicon.bind(this.tenantBranding),
                    onDone() {
                        Bus.$emit('refresh.favicon', this.tenantBranding.favicon);
                    },
                    size: '16x16',
                    src: this.tenantBranding.favicon,
                    // as translation is being handled in tooltip component, we just need to add the traslation key here, without _T(..)
                    info: [
                        'branding_ico_restrictions',
                        'changes_uploaded_immediately'
                    ],
                    errors: [],
                    isLoading: false,
                    loaderType: 'small',
                    tooltipVisible: false
                },
                messages: {
                    helper: _T('click image to upload picture'),
                    saved: _T('saved_and_updated')
                }
            };
        },
        methods: {
            onMouseEnter(model) {
                model.tooltipVisible = true;
            },
            onMouseLeave(model) {
                model.tooltipVisible = false;
            },
            onClicked(model) {
                model.errors = [];
            },
            onChanged({ target: { files } }, model) {
                model.isLoading = true;
                model.action(files[0])
                    .then(() => {
                        model.src = `${model.src}?refresh=${new Date().getTime()}`;
                        Bus.$emit('default.message', this.messages.saved);
                        model.onDone.bind(this)();
                        model.isLoading = false;
                    })
                    .catch((error) => {
                        model.errors = ErrorsHelper.toArray(error);
                        model.isLoading = false;
                    });
            }
        }
    };
</script>
