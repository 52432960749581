<template>
    <li v-if="listItem" class="ui-dialog__alert-list-item">
        {{ parsedError }}
    </li>
    <div v-else class="ui-dialog__alert alert--error">
        <img
            class="ui-dialog__alert-image"
            src="/images/default/error.png"
            alt="error"
        />
        <div class="ui-dialog__alert-text">
            {{ parsedError }}
        </div>
    </div>

</template>

<script>
    import { _T } from '../globals';

    export default {
        props: ['error', 'listItem'],
        computed: {
            parsedError() {
                return _T(this.error.message, this.error.params);
            }
        }
    };
</script>

<style lang="scss">
    .ui-dialog__alert {
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
