import Bus from '../bus';

const forms = [];

export default {
    init() {
        Bus.$on('forms.add.edited', (id) => {
            if (forms.indexOf(id) === -1) {
                forms.push(id);
            }
        });

        Bus.$on('forms.remove.edited', (id) => {
            const index = forms.indexOf(id);

            if (index > -1) {
                forms.splice(index, 1);
            }
        });

        window.onbeforeunload = () => {
            let message = null;

            if (forms.length) {
                message = 'You currently have unsaved changes, are you sure that you want to navigate away form the page?';
            }

            return message;
        };
    }
};
