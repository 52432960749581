import axios from 'axios';

export default {
    poll(url, condition, timeout, interval) {
        const totalEndTime = Number(new Date()) + (timeout);

        const checkCondition = (resolve, reject) => {
            const wait = Number(new Date()) + (interval);

            axios({
                url,
                cancelToken: null,
                method: 'get'
            }).then((response) => {
                if (!condition(response.data)) {
                    if (Number(new Date()) >= totalEndTime) {
                        reject();
                    } else {
                        const waitTime = wait - Number(new Date());

                        setTimeout(checkCondition, waitTime < 0 ? 0 : waitTime, resolve, reject);
                    }
                } else {
                    resolve();
                }
            });
        };

        return new Promise(checkCondition);
    },

    pollFile(url, timeout = 60000, interval = 2500) {

        const totalEndTime = Number(new Date()) + (timeout);

        const polling = (resolve, reject) => {

            const wait = Number(new Date()) + (interval);

            axios({
                url,
                cancelToken: null,
                method: 'get'
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                if (Number(new Date()) >= totalEndTime) {
                    return reject(error);
                }
                const waitTime = wait - Number(new Date());
                setTimeout(polling, waitTime < 0 ? 0 : waitTime, resolve, reject);
            });
        };

        return new Promise(polling);
    }
};
