import { render, staticRenderFns } from "./list.vue?vue&type=template&id=680237a7&"
var script = {}
import style0 from "./list.vue?vue&type=style&index=0&id=680237a7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports