import AudioFile from './audio-file';
import TrackTokenizer from '../tools/track-tokenizer';
import TrackUploader from '../tools/track-uploader';
import Bus from '../bus';
import { URLS } from '../constants/constants';

export default class Track extends AudioFile {
    constructor(data) {
        super({
            id: data.id,
            title: data.title,
            audio: data.audio,
            uploader: new TrackUploader({ id: data.id })
        });

        this.link = URLS.TRACK.replace('[id]', data.id);
        this.display_artist = data.display_artist;
        this.remix_or_version = data.remix_or_version;
    }

    upload(files) {
        return new Promise((resolve, reject) => {
            new TrackTokenizer(this.id).getToken(files).then((data) => {
                this.uploader.addToken(data.data[0].auth_code);
                this.uploader.addFile(files[0]);
                Bus.$emit('add.uploader', this.uploader);
                this.uploader.upload().then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }

    muteUpload() {
        this.uploader.toggleMute(true);
    }

    uploadWithToken(file, token) {
        this.uploader.addToken(token);
        this.uploader.setBusy();
        this.uploader.addFile(file);

        return this.uploader.upload();
    }

    addFile(file) {
        this.uploader.addFile(file);
        Bus.$emit('add.uploader', this.uploader);
    }
}
