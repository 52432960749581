<style lang="scss">
    .footer-links {
        display: flex;
        align-items: flex-start;
    }

    .footer-links .footer-links__links {
        display: flex;
        align-items: flex-start;
        gap: 72px;
    }

    .footer-links .footer-links__links .footer-links__links__group {
        display: flex;
        flex-direction: column;
    }

    .footer-links .footer-links__links .footer-links__links__group a {
        font-size: 12px;
    }

    .footer-links .footer-links__links .footer-links__links__group a:not(:last-child) {
        margin-bottom:  4px;
    }

    .footer-links .footer-links__links .footer-links__links__group .footer-links__links__group__title {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 16px;
    }
</style>

<template>
    <div class="footer-links">
        <div class="footer-links__links">
            <div class="footer-links__links__group" v-if="resourcesLinks.length > 0">
                <div class="footer-links__links__group__title">{{_T('resources')}}</div>
                <a v-for="(link, index) in resourcesLinks" :key="index" :href="link.href" v-bind:rel="link.rel">{{_T(link.text)}}</a>
            </div>

            <div class="footer-links__links__group" v-if="companyLinks.length > 0">
                <div class="footer-links__links__group__title">{{_T('company')}}</div>
                <a v-for="(link, index) in companyLinks" :key="index" :href="link.href" v-bind:rel="link.rel">{{_T(link.text)}}</a>
            </div>

            <div class="footer-links__links__group" v-if="legalLinks.length > 0">
                <div class="footer-links__links__group__title">{{_T('legal')}}</div>
                <a v-for="(link, index) in legalLinks" :key="index" :href="link.href" v-bind:rel="link.rel">{{_T(link.text)}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import Methods from '../mixins/methods';

    export default {
        mixins: [Methods],
        props: ['links', 'code'],
        data() {
            return {
                logoUrl: this.code,
                resourcesLinks: this.links.resources,
                companyLinks: this.links.company,
                legalLinks: this.links.legal
            };
        }
    }
</script>
