<style lang="scss">
    .chart-tooltip {
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #000000;
        border-radius: 3px;
        padding: 4px;
        font-size: 12px;
        background: #FFFFFF;
        white-space: nowrap;
        pointer-events: none;
    }
</style>

<template>
    <div class="chart-tooltip" v-if="tooltip.text" :style="styles">{{tooltip.text}}</div>
</template>

<script>
    export default {
        props: ['tooltip'],
        computed: {
            styles() {
                return {
                    left: `${this.tooltip.left}px`,
                    top: `${this.tooltip.top}px`,
                    borderColor: this.tooltip.color,
                    color: this.tooltip.color
                };
            }
        }
    };
</script>
