export default class ArtistsRoles {
    constructor(artists) {
        this.artists = artists;
    }

    roles() {
        let roles = [];

        this.artists.forEach((artist) => {
            artist.roles.forEach((role) => {
                if (!roles.includes(role)) {
                    roles = [...roles, role];
                }
            });
        });

        return roles;
    }

    namesByRoles() {
        const roles = this.roles();
        const rolesObject = roles.reduce((total, actual) => {
            total[actual] = [];

            return total;
        }, {});

        this.artists.forEach((artist) => {
            artist.roles.forEach((role) => {
                rolesObject[role].push(artist.name);
            });
        });

        return rolesObject;
    }
}
