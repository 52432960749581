<style lang="scss">
    .custom-button {
        border: 0;
        padding: 10px 12px;
        font-size: 12px;
        color: #777576;
        background: #FFFFFF;

        &:focus {
            outline: none;
        }

        &:hover,
        &.custom-button--selected {
            color: #000000;
            background: #C3C4BE;
        }
    }
</style>

<template>
    <button class="custom-button" :class="buttonClass" @click="onClick">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: ['selected'],
        methods: {
            onClick(e) {
                this.$emit('click', e);
            }
        },
        computed: {
            buttonClass() {
                return { 'custom-button--selected': this.selected };
            }
        }
    };
</script>
