import axios from 'axios';
import { API, RELEASE_TYPE } from '../../constants/constants';
import ReleaseMetadataAlbum from '../release-metadata-album/release-metadata-album';
import ReleaseMetadataRingtone from '../release-metadata-ringtone/release-metadata-ringtone';

export default {
    getMetadataById(id) {
        return axios.get(API.RELEASE_METADATA.replace('[id]', id)).then(({ data }) => {
            let metadata = {};

            switch (data.type) {
                case RELEASE_TYPE.ALBUM:
                    metadata = new ReleaseMetadataAlbum(data);
                    break;
                case RELEASE_TYPE.RINGTONE:
                    metadata = new ReleaseMetadataRingtone(data);
                    break;
                default:
                    break;
            }

            return metadata;
        });
    }
};
