import ErrorsHelper from '../helpers/errors';

export default class Errors {
    constructor() {
        this.errors = {};
    }

    has(field) {
        return Object.prototype.hasOwnProperty.call(this.errors, field);
    }

    any() {
        return Object.keys(this.errors).length !== 0;
    }

    get(field) {
        if (!this.errors[field]) {
            throw new Error(`Field '${field}' not found.`);
        }

        return this.errors[field];
    }

    getString(field) {
        return ErrorsHelper.toString(this.get(field));
    }

    clear(field) {
        delete this.errors[field];
    }

    clearGlobal() {
        this.clear('global');
    }

    clearAll() {
        this.errors = {};
    }

    record(data) {
        const errors = {};

        Object.keys(data).forEach((key) => {
            const error = data[key];
            const errorCode = error[Object.keys(error)[0]];

            errors[key] = errorCode[Object.keys(errorCode)[0]];
        });

        this.errors = errors;
    }

    add(errors) {
        this.errors = errors;
    }
}
