import { _T } from '../../globals';

function setExplicit(explicit) {
    return explicit;
}

export default class InheritedExplicitLanguage {
    constructor(explicit) {
        this.explicit = setExplicit(explicit);
    }

    translate() {
        return this.explicit ? _T('explicit') : _T('not_explicit');
    }
}
