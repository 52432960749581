/* eslint camelcase: "off" */

const {
    _T,
    mx2_config: MX2Config,
    $,
    prompt_line: PromptLine,
    loginAsUser,
    doReload,
    start_spin: startSpin,
    end_spin: endSpin,
    StripeCheckout,
    playReleaseAllTracks,
    moment
} = window;

export {
    _T,
    MX2Config,
    $,
    PromptLine,
    loginAsUser,
    doReload,
    startSpin,
    endSpin,
    StripeCheckout,
    playReleaseAllTracks,
    moment
};
