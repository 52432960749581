import axios from 'axios';
import FormsHelper from '../../helpers/forms';
import { API } from '../../constants/constants';

export default {
    getAllCovers(uuid) {
        return axios.get(API.ALBUM_COVER_INFO.replace('[id]', uuid)).then(response => response.data);
    },

    getCover(uuid, size) {
        return Promise.resolve(API.ALBUM_COVER.replace('[id]', uuid).replace('[size]', size));
    },

    uploadCover(uuid, file) {
        return axios.post(API.ALBUM_COVER.replace('[id]', uuid).replace('[size]', ''), FormsHelper.filesToFormData([file]));
    },

    deleteCover(uuid) {
        return axios.delete(API.ALBUM_COVER.replace('[id]', uuid).replace('[size]', ''));
    }
};
