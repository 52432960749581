<style lang="scss">
    @import '../../scss/settings';

    .inline-menu__item {
        display: inline-block;
        margin-right: $spacing*2;

        &:last-child {
            margin-right: 0;
        }
    }

    .inline-menu__link {
        font-size: 0.875rem;
        color: $darkGray;

        &:hover {
            color: $darkGray;
        }
    }
</style>

<template>
    <ul class="inline-menu">
        <li class="inline-menu__item" v-for="(link, index) in links" :key="index">
            <a class="inline-menu__link"
               :href="link.href" :rel="isRel(link.rel)">{{link.text}}</a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: ['links'],
        methods: {
            isRel(rel) {
                return rel || false;
            }
        }
    };
</script>
