import { SERVER_ERRORS } from '../constants/constants';

export default class Response {
    constructor(data) {
        this.original = data.original !== undefined ? data.original : data.data;
        this.data = data.data;
        this.status = data.status;
        this.statusText = data.statusText;
        this.info = data.info;

        this.parse();
    }

    parse() {
        if (this.status === SERVER_ERRORS.BAD_REQUEST.STATUS) {
            this.updateDataInfoWithError();
        } else if (this.data.indexOf('Fatal error') > -1) {
            this.status = SERVER_ERRORS.INTERNAL_ERROR.STATUS;
            this.statusText = 'Error';
        }
    }

    updateDataInfoWithError() {
        const first = Object.keys(this.original)[0];
        const second = Object.keys(this.original[first])[0];
        const [errorValue] = Object.values(this.original[first][second]);

        this.data = errorValue;
        this.info = this.data;
    }

    isValid() {
        return this.status !== SERVER_ERRORS.BAD_REQUEST.STATUS
            && this.status !== SERVER_ERRORS.INTERNAL_ERROR.STATUS;
    }
}
