<style lang="scss">
    .player {
        position: fixed;
        left: 0;
        bottom: 0;
        border-top: 1px solid #d8d8d8;
        width: 100%;
        background: #f2f2f2;
        box-sizing: border-box;
        text-align: right;
    }

    .player * {
        box-sizing: border-box;
    }

    .player__wrapper {
        position: relative;
        margin: 0 auto;
        width: 960px;
        padding: 10px;
        text-align: center;
    }

    .player__controls {
        display: inline-block;
        vertical-align: middle;
    }

    .player__control {
        display: inline-block;
        width: 32px;
        height: 32px;
        cursor: pointer;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .player__control svg {
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    .player__control.player__control--small {
        width: 20px;
        height: 20px;
    }

    .player__control.player__control--loading {
        animation: spinner 1s infinite steps(8);
    }

    .player__info {
        display: inline-block;
        padding: 0 0 0 10px;
        vertical-align: middle;
    }

    .player__range {
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
    }

    .player__time {
        display: inline-block;
        padding: 0 10px;
        vertical-align: middle;
    }

    .player__name {
        display: inline-block;
        padding: 0 10px;
        vertical-align: middle;
    }
</style>

<template>
    <div class="player" v-if="isActive">
        <playlist :playlist="playlist" @clear="clear"></playlist>
        <div class="player__wrapper">
            <div class="player__control player__control--loading" v-if="isLoading">
                <spinner-icon viewBox="0 0 32 32"></spinner-icon>
            </div>
            <template v-if="isPlaying || isPaused">
                <div class="player__controls">
                    <span class="player__control player__control--small" @click="prev">
                        <previous-icon viewBox="0 0 32 32"></previous-icon>
                    </span>
                    <span class="player__control" @click="toggle" v-if="isPaused">
                        <play-icon viewBox="0 0 32 32"></play-icon>
                    </span>
                    <span class="player__control" @click="toggle" v-if="isPlaying">
                        <pause-icon viewBox="0 0 32 32"></pause-icon>
                    </span>
                    <span class="player__control player__control--small" @click="next">
                        <next-icon viewBox="0 0 32 32"></next-icon>
                    </span>
                </div>
                <div v-if="showInfo" class="player__info">
                    <range-slider :percentage="playlist.tracks[playlist.index].playable.percentage"
                                  class="player__range"
                                  @mousedown="togglePercentage"
                                  @mouseup="togglePercentage"
                                  @change="changePercentage"></range-slider>
                    <div class="player__time">{{showTime}}</div>
                    <div class="player__name">{{playlist.tracks[playlist.index].title}}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { STATUS } from '../constants/constants';
    import Playlist from './playlist.vue';
    import RangeSlider from './range-slider.vue';
    import PreviousIcon from '../../svg/previous.svg';
    import PlayIcon from '../../svg/play.svg';
    import PauseIcon from '../../svg/pause.svg';
    import SpinnerIcon from '../../svg/spinner.svg';
    import NextIcon from '../../svg/next.svg';

    export default {
        props: ['playlist'],
        components: {
            Playlist,
            RangeSlider,
            PreviousIcon,
            PlayIcon,
            PauseIcon,
            SpinnerIcon,
            NextIcon
        },
        methods: {
            toggle() {
                if (this.isPlaying) {
                    this.playlist.pause();
                } else {
                    this.playlist.play();
                }
            },
            clear() {
                this.$emit('clear');
            },
            prev() {
                this.playlist.prev();
            },
            next() {
                this.playlist.next();
            },
            togglePercentage() {
                this.playlist.tracks[this.playlist.index].playable.toggleUpdatePercentage();
            },
            changePercentage(percentage) {
                this.playlist.tracks[this.playlist.index].playable.playFromPercentage(percentage);
            }
        },
        computed: {
            isActive() {
                return this.playlist.status !== STATUS.CLEAN;
            },
            isLoading() {
                return this.playlist.status === STATUS.LOADING;
            },
            isPlaying() {
                return this.playlist.status === STATUS.PLAYING;
            },
            isPaused() {
                return this.playlist.status === STATUS.PAUSED;
            },
            showInfo() {
                return this.playlist.tracks[this.playlist.index]
                    && this.playlist.tracks[this.playlist.index].title
                    && this.playlist.tracks[this.playlist.index].playable
                    && this.playlist.tracks[this.playlist.index].playable.actual
                    && this.playlist.tracks[this.playlist.index].playable.length;
            },
            showTime() {
                return `${this.playlist.tracks[this.playlist.index].playable.actual} / ${this.playlist.tracks[this.playlist.index].playable.length}`;
            }
        }
    };
</script>
