<template>
    <div class="bulk-upload">
        <div class="bulk-upload__header">
            <div class="bulk-upload__heading">
                <div class="bulk-upload__title">
                    {{ title }}
                </div>
                <div class="bulk-upload__subtitle">
                    {{ subtitle }}
                </div>
            </div>
            <a
                class="bulk-upload__template-file"
                :href=href
            >
                <download-icon
                    class="bulk-upload__download-icon"
                    :aria-label="download"
                />
                <div class="bulk-upload__download-text">
                    {{ download }}
                </div>
            </a>
        </div>
        <drag-drop
            component="file-upload"
            @dropFile="dropFile"
        >
            <file-upload
                accept=".csv"
                id="bulk-upload-input"
                :browseText="browseText"
                :dragDropText="dragDropText"
                @browseFile="browseFile"
            />
        </drag-drop>
        <div class="bulk-upload__upload-zone">
            <div
                v-if="fileIsAttached()"
                class="bulk-upload__file"
            >
                <div class="bulk-upload__file-name">
                    {{ this.fileMetadata.name }}
                    <span class="bulk-upload__file-size">
                        {{ this.fileMetadata.size }}
                    </span>
                </div>
                <button
                    class="bulk-upload__button--remove"
                    @click="resetFile()"
                >
                    <trash-icon :aria-label="removeFile"/>
                </button>
            </div>
            <div
                v-if="fileIsUploading()"
                class="bulk-upload__progress"
            >
                <img
                    :alt="alt"
                    role="progressbar"
                    src="/images/default/process-dots.gif"
                    class="bulk-upload__progress--image"
                >
                <div class="bulk-upload__file-uploading">
                    {{ uploadStarted }}
                </div>
            </div>
            <div
                v-if="fileIsUploaded()"
                class="bulk-upload__alert"
            >
                <div class="ui-dialog__alert alert--info">
                    <div class="ui-dialog__alert-text" :uploaded="onUploadEnd()">
                        <img
                            class="ui-dialog__alert-image"
                            src="/images/default/info.png"
                            alt="info"
                        />
                        {{ uploadFinished }}
                    </div>
                    <button
                        class="ui-dialog__alert__icon-close"
                        @click="closeAlert"
                    >
                        <cross-small :aria-label="modal.button" />
                    </button>
                </div>
            </div>
            <button
                data-phoenix
                class="input-button bulk-upload__button"
                :disabled="!bulk.isEmpty() || fileIsUploading()"
                @click="submit"
            >
                {{ upload }}
            </button>
        </div>
        <modal v-if="error">
            <div
                slot="header"
                class="modal__heading"
            >
                <div>{{ modal.title }}</div>
                <button
                    class="modal__icon-close"
                    @click="closeModal"
                >
                    <cross-large :aria-label="modal.button"/>
                </button>
            </div>
            <main
                slot="body"
                class="modal__content"
            >
                <div
                    v-if="modal.rejectedItem"
                    class="modal__error-item"
                >{{ modal.rejectedItem }}</div>
                <div class="ui-dialog__alert alert--error">
                    <img
                        class="ui-dialog__alert-image"
                        src="/images/default/error.png"
                        alt="error"
                    />
                    <div class="ui-dialog__alert-text">
                        {{ modal.message }}</div>
                </div>
            </main>
            <footer slot="footer">
                <input-button
                    class="ui-button--close"
                    :text="modal.button"
                    :no-margin="true"
                    @click="closeModal"
                />
            </footer>
        </modal>
    </div>
</template>

<script>
    import DragDrop from './drag-drop.vue';
    import InputButton from './form-elements/input-button.vue';
    import FileUpload from './file-upload.vue';
    import Modal from './modal.vue';
    import CrossLarge from '../../svg/cross-large.svg';
    import CrossSmall from '../../svg/cross-small.svg';
    import DownloadIcon from '../../svg/download-small.svg';
    import TrashIcon from '../../svg/trash.svg';

    import BulkUpload from '../models/bulk-upload';
    import Bus from '../bus';
    import { bytesFormatter } from '../helpers/bytes-formatter';
    import { _T } from '../globals';

    export default {
        name: 'BulkUpload',
        components: {
            DragDrop,
            CrossLarge,
            CrossSmall,
            DownloadIcon,
            InputButton,
            FileUpload,
            Modal,
            TrashIcon
        },
        props: {
            alt: {
                type: String,
                default: _T('persistor processing...')
            },
            browseText: {
                type: String,
                default: _T('browse')
            },
            download: {
                type: String,
                default:  _T('bulk_upload_download_template')
            },
            dragDropText: {
                type: String,
                default: _T('bulk_upload_form_dragDrop')
            },
            files: {
                type: Array,
                default: () => []
            },
            href: {
                type: String,
                default: 'bulkupload/CSV_Audio_Album_Metadata_Template_Sample.csv'
            },
            title: {
                type: String,
                default: _T('bulk_upload_form_title')
            },
            removeFile: {
                type: String,
                default: _T('delete')
            },
            subtitle: {
                type: String,
                default: _T('bulk_upload_form_info')
            },
            upload: {
                type: String,
                default: _T('service_upload_only')
            },
            uploadFinished: {
                type: String,
                default: _T('bulk_upload_form_file_uploaded')
            }
        },
        data: () => ({
            bulk: new BulkUpload(),
            error: false,
            fileMetadata: {},
            fileUploaded: false,
            modal: {
                button: _T('close'),
                message: '',
                rejectedItem: '',
                title: _T('error')
            }
        }),
        computed: {
            /**
             * Awares user that the file upload has started
             */
            uploadStarted () {
                return _T('bulk_upload_form_file_in_progress', [this.fileMetadata.name])
            }
        },
        methods: {
            /**
             * Checks if file is attached
             */
            fileIsAttached () {
                return this.fileMetadata.name && this.bulk.isEmpty() && !this.fileIsUploading();
            },
            /**
             * Checks if file is being uploaded
             */
            fileIsUploading () {
                return this.bulk.isUploading();
            },
            /**
             * Checks if file is uploaded to BU list queue */
            fileIsUploaded () {
                return this.fileUploaded && !this.bulk.isEmpty() && !this.fileIsUploading()
            },
            /**
             * Hides UI uploaded alert info message
            */
            onUploadEnd () {
                setTimeout(() => {
                    this.fileUploaded = false
                }, 8000);
            },
            /**
             * Closes Info Alert
             */
            closeAlert () {
                this.fileUploaded = false
            },
            /**
             * Browse file using button
             */
            browseFile (files, $event) {
                this.resetFile()

                Array.from(files).forEach(item => {
                    if (this.checkMimeType(item.type)) {
                        [this.bulk.file] = $event.target.files
                        this.extractMetadata(item)
                    } else {
                        this.error = true
                        this.rejectFile(item.name, item.type)
                    }
                })
            },
            /**
             * Keeps track of dropped content
             */
            dropFile ($event) {
                this.resetFile()
                const files = [...$event.dataTransfer.items].map(item => item.getAsFile())

                files.forEach((file) => {
                    if (this.checkMimeType(file.type)) {
                        this.extractMetadata(file)
                        this.bulk.file = file
                    } else {
                        this.error = true
                        this.rejectFile(file.name, file.type)
                    }
                })
            },
            checkMimeType(mimeType) {
                let validMimeTypes = [
                    'text/csv',
                    'application/vnd.ms-excel'
                ];

                if (validMimeTypes.indexOf(mimeType) < 0) {
                    return false;
                }
                else return true;
            },
            /**
             * Updates fileMetadata values
             */
            extractMetadata (item) {
                const name = item.name
                const length = name.length
                const fileName = name.replace(
                    name.slice(length-3, length),
                    name.slice(length-3, length).toUpperCase()
                )

                this.fileMetadata = {
                    name: fileName,
                    type: item.type,
                    size: bytesFormatter(item.size)
                }
            },
            /**
             * Uploads the file to the BU list
             */
            submit () {
                this.bulk.upload()
                    .then(() => {
                        this.fileUploaded = true
                        this.$emit('uploaded')
                        Bus.$emit('fileUploaded', this.fileUploaded)
                    })
                    .catch(() => {
                        this.fileUploaded = false
                        this.error = true
                        this.modal.rejectedItem = ''
                        this.modal.message = `${_T('bulk_upload_form_error_alert')}`
                        this.resetFile()
                    });
            },
            /**
             * Resets bulk.file object by removing file from instance
             */
            resetFile () {
                if (this.bulk.file) {
                    this.bulk.file = null
                    this.fileMetadata = {}
                    this.fileUploaded = false
                }
            },
            /**
             * Closes Error Modal
             */
            closeModal () {
                this.error = false
            },
            /**
             * Rejects file and displays a modal if format is not valid
             */
            rejectFile (fileName, fileType) {
                this.modal.rejectedItem = fileName
                this.modal.message = fileType ?
                    `${fileType} ${_T('bulk_upload_form_error_type_not_supported')}`
                    : `${_T('bulk_upload_form_error_entry_not_supported')}`
            }
        }
    };
</script>

<style lang="scss">
    .bulk-upload {
        display: flex;
        flex-flow: column nowrap;
        margin: 1.5rem 0 2.5rem 0;

        &__header {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-end;
        }

        &__heading,
        &__template-file {
            margin-bottom: 1rem;
        }

        &__title {
            font-size: 1.375rem;
            font-weight: 600;
            margin-bottom: 0.25rem;
        }

        &__subtitle,
        &__template-file {
            font-size: 0.875rem;
            font-weight: 400;
        }

        &__template-file {
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
        }

        &__download-icon {
            margin-right: 0.375rem;
        }

        &__download-text {
            cursor: pointer;
            color: #000;

            &:hover {
                opacity: 1;
                text-decoration: underline;
            }
        }

        &__upload-zone {
            display: flex;
            flex-flow: row wrap;
            margin-top: 0.5rem;
        }

        &__file,
        &__progress {
            display: flex;
            align-content: center;
            padding: 0.75rem 1.5rem;
            margin-bottom: 0.5rem;
            background-color: #f8f8f8;
            width: 100%;
        }

        &__file {
            justify-content: space-between;
        }

        &__progress {
            justify-content: flex-start;

            &--image {
                width: 1.5rem;
                height: 0.5rem;
                align-self: center;
                margin-right: 0.5rem;
            }
        }

        &__alert {
            width: 100%;
            margin-bottom: 0.5rem;
        }

        &__file-name {
            font-weight: 500;
            margin-right: 0.1875rem;
        }

        &__file-name,
        &__file-uploading {
            font-size: 0.875rem;
            line-height: 1.225rem;
        }

        &__file-uploading {
            font-weight: 400;
            align-self: center;
        }

        &__file-size {
            color: #828282;
        }

        &__button,
        .input-button {
            padding: 0.75rem 1rem;
            margin-bottom: 0;
            border-radius: 0.1875rem;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.4rem;

            &:hover {
                opacity: 0.8;
            }

            &:disabled {
               color: #FFFFFF;
               background: rgba(#4a4a4a, 0.20);
                &:hover {
                    opacity: 1;
                }
            }

            &--remove {
                use {
                    &:hover {
                        fill: #000;
                    }
                }
            }
        }

        .modal {
            &__box {
                width: 34.375rem;
            }

            &__heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &__icon-close {
                line-height: 0.8;

                &:hover {
                    opacity: 0.5;
                }
            }

            &__content {
                padding: 1rem 0.875rem 1.5rem;
            }

            &__error-item {
                line-height: 1.6rem;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .ui-dialog__alert {
            &.alert--error {
                flex-flow: row nowrap;
                margin-top: 0.875rem;
            }

            &.alert--info {
                display: flex;
                justify-content: space-between;
                margin-top: 0;
                padding: 0.75rem 1.5rem;
            }

            &__icon-close {
                align-self: center;
            }
        }
    }
</style>

