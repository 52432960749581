<template>
    <div class="view-more">
        <div
            :error="error"
            :class="setClass"
            @click="toggleOpen"
        >
            <span class="view-more__title">{{ title }}</span>
            <button
                :aria-expanded="ariaExpanded"
                :id="dropdownId"
                class="view-more__icon"
            >
                <arrow-drop-down :aria-label="ariaLabel" :class="iconClass"/>
            </button>

        </div>
        <transition name="fade">
            <div class="view-more__content" :aria-labelledby="dropdownId" v-if="isOpen">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    import ArrowDropDown from '../../svg/arrow-drop-down.svg'

    export default {
        name: 'ViewMore',
        components: { 
            ArrowDropDown 
        },
        props: {
            error: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            isOpen: false
        }),
        computed: {
            ariaLabel () {
                return this.isOpen ? 'Close' : 'Use the dropdown to view more details'
            },
            ariaExpanded () {
                return this.isOpen ? true : false
            },
            dropdownId () {
                return `dropdownViewMoreButton-${Math.ceil(Math.random() * 1000)}`
            },
            setClass () {
                return this.error ? 'view-more__head view-more__head--error' : 'view-more__head'
            },
            iconClass () {
                return this.isOpen ? 'view-more__icon--close' : 'view-more__icon--open'
            }
        },
        methods: {
            toggleOpen () {
                this.isOpen = !this.isOpen;
            }
        }
    };
</script>

<style lang="scss">
    .view-more {
        position: relative;
        border: 0.0625rem solid #d7d7d7;
        border-radius: 0.1875rem 0.1875rem 0 0;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        &__head {
            display: flex;
            align-items: center;
            padding: 0.875rem 1.5rem;
            cursor: pointer;

            // if errors are displayed in view-more__content
            &--error {
                border-left: 0.25rem solid #FF5350;
                border-radius: 0.1875rem 0;
            }
        }

        &__title {
            flex: 1;
            margin-right: 1rem;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.225rem;
            color: #000;
        }

        &__icon {
            &--open {
                transition: transform 0.5s;
                path {
                    fill: #363636;
                }
                &:hover {
                    path {
                        fill: #d7d7d7;
                    }
                }
            }
            &--close {
                transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transition: transform 0.5s;
                path {
                    fill: #d7d7d7;
                }
                &:hover {
                    path {
                        fill: #363636;
                    }
                }
            }
        }

        &__content {
            border-top: 0.0625rem solid #d7d7d7;
            padding: 1rem;
        }
    }
</style>
