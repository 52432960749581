export default {
    pickField(data, field) {
        return data[field] || {};
    },

    pickFields(data, fields) {
        return fields.reduce((total, current) => {
            const copy = Object.assign({}, total);

            if (data[current] !== undefined) {
                copy[current] = data[current];
            }

            return copy;
        }, {});
    },

    omitField(data, field) {
        return this.omitFields(data, [field]);
    },

    omitFields(data, fields) {
        return Object.keys(data)
            .reduce((total, current) => {
                const copy = Object.assign({}, total);

                if (!fields.includes(current)) {
                    copy[current] = data[current];
                }

                return copy;
            }, {});
    },

    groupBy(array, key) {
        const result = {};
        array.forEach((item) => {
            if (!result[item[key]]) {
                result[item[key]] = [];
            }
            result[item[key]].push(item);
        });
        return result;
    },
    /**
     * Simple is object check.
     * @param item
     * @returns {boolean}
     */
    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
    }
};
