import Vue from 'vue';
import BulkUploadDetail from './components/bulk-upload-detail.vue';

export default class Detail {
    constructor(element) {
        this.element = element;
    }

    load(bulkUploadUUId) {
        return new Vue({
            el: this.element,
            template: `<bulk-upload-detail :uuid="uuid" @close="onClose" @loadEnd="onLoad"></bulk-upload-detail>`,
            data: {
                uuid: bulkUploadUUId
            },
            components: { BulkUploadDetail },
            methods: {
                onClose() {
                    this.onClosed();
                },
                onLoad() {
                    this.onLoaded();
                },
                onClosed() {},
                onLoaded() {}
            }
        });
    }
}
