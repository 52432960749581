import { UPLOADER_STATUS } from './constants/constants';

export default class Uploader {
    constructor() {
        this.status = UPLOADER_STATUS.IDLE;
    }

    setBusy() {
        this.status = UPLOADER_STATUS.BUSY;
    }

    setIdle() {
        this.status = UPLOADER_STATUS.IDLE;
    }

    hasActivity() {
        return this.status === UPLOADER_STATUS.BUSY;
    }
}
