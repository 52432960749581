import axios from 'axios';
import PromiseQueue from '../tools/promise-queue';
import AlbumTokenizer from '../tools/album-tokenizer';
import EmptyTrack from './empty-track';
import Track from './track';
import { API } from '../constants/constants';

export default class Album {
    constructor(data) {
        this.id = data.id;
        this.tracks = [];

        this.onTracksAdded = () => {};
        this.onTracksUploadError = () => {};
        this.tracksAdded = () => { this.onTracksAdded(); };
        this.tracksUploadError = () => { this.onTracksUploadError(); };
    }

    getTracks() {
        return new Promise((resolve, reject) => {
            axios.get(API.ALBUM_TRACKS.replace('[id]', this.id))
                .then((response) => {
                    this.tracks = response.data.map(element => new Track(element.track));
                    resolve();
                })
                .catch(() => { reject(); });
        });
    }

    sortTracks(oldIndex, newIndex) {
        return new Promise((resolve, reject) => {
            const cachedTracks = this.tracks.slice();
            const oldTrack = cachedTracks[oldIndex];

            cachedTracks.splice(oldIndex, 1);
            cachedTracks.splice(newIndex, 0, oldTrack);

            this.postTracks(cachedTracks)
                .then(() => {
                    this.tracks = cachedTracks;
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    addTrack(track) {
        return this.addTracks([track]);
    }

    addTracks(tracks) {
        if (tracks.length === 0) {
            this.tracksAdded();
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            let cachedTracks = this.tracks.slice();

            cachedTracks = cachedTracks.concat(tracks);

            this.postTracks(cachedTracks)
                .then(() => {
                    this.tracks = cachedTracks;
                    this.tracksAdded();
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    removeTrack(index) {
        return new Promise((resolve, reject) => {
            const cachedTracks = this.tracks.slice();

            cachedTracks.splice(index, 1);

            this.postTracks(cachedTracks)
                .then(() => {
                    this.tracks = cachedTracks;
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    postTracks(tracks) {
        const ids = tracks.map(track => track.id);

        return axios.post(API.ALBUM_TRACKS.replace('[id]', this.id), ids);
    }

    uploadTracks(files) {
        const albumTokenizer = new AlbumTokenizer(this.id);
        const promiseQueue = new PromiseQueue();

        albumTokenizer.getToken(files)
            .then((response) => {
                const tracks = [];

                response.data.forEach((data) => {
                    const track = new EmptyTrack({
                        id: data.track_id,
                        title: data.file.name.replace(/\.[^/.]+$/, '')
                    });

                    tracks.push(track);
                    track.addFile(data.file);
                    track.muteUpload();

                    promiseQueue.push(() => track.uploadWithToken(data.file, data.auth_code));
                });

                this.addTracks(tracks)
                    .then(() => {
                        promiseQueue.start();
                    });
            }).catch(() => {
                this.tracksUploadError();
            });
    }
}
