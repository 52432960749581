const Polling = function (url, pollingTime = 1000) {
    this.url = url;
    this.pollingTime = pollingTime;
    this.lastId = null;
    this.init();
}

Polling.prototype = {
    init: function () {
        fetch(this.url)
            .then(response => response.json())
            .then(data =>{
                this.lastId = this.getIdOrNull(data)
            });
    },
    poll: function (cb) {
        fetch(this.url)
            .then(response => response.json())
            .then(data =>{
                let currentId = this.getIdOrNull(data)
                if (currentId !== this.lastId) {
                    this.lastId = currentId;
                    if (typeof cb === 'function') {
                        cb();
                    }
                } else {
                    setTimeout(() => this.poll(cb), this.pollingTime);
                }
            });
    },
    getIdOrNull: function(data) {
        return data.items.length ? data.items[0].id : null;
    }
}

export default Polling;
