import Vue from 'vue';
import checkoutPayment from './components/checkout-payment.vue';

export default {
    load() {
        new Vue({
            el: '#checkoutApp',
            components: { checkoutPayment }
        });
    }
};
