<template>
    <modal v-if="content">
        <header slot="header">{{messages.title}}</header>
        <span slot="sub-header">{{messages.subtitle}}</span>
        <main slot="body" class="static-page content__padded" v-html="content"></main>
        <footer slot="footer">
            <input-button :text="messages.accept" :no-margin="true" @click="accept"></input-button>
        </footer>
    </modal>
</template>

<script>
    import UserRepository from '~phoenix/js/models/user/user-repository';
    import Terms from '~phoenix/js/models/terms';
    import InputButton from '~phoenix/js/components/form-elements/input-button.vue';
    import Modal from '~phoenix/js/components/modal.vue';
    import { _T } from '~phoenix/js/globals';

    export default {
        components: {
            InputButton,
            Modal
        },
        data() {
            return {
                content: null,
                messages: {
                    title: _T('terms_title'),
                    subtitle: _T('terms_subtitle'),
                    accept: _T('terms_accept')
                }
            };
        },
        created() {
            UserRepository.showTerms()
                .then((content) => {
                    this.content = content;
                });
        },
        methods: {
            accept() {
                Terms.accept();
            }
        }
    };
</script>
