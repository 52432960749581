<template>
    <v-flex xs12 md12 d-block>
        <v-flex xs12 md12 d-block>
            <h2>{{getMessages.title}}</h2>
            <clip-loader :loading="getSpinner" :color="color"></clip-loader>
            <v-data-table
                    :headers="getHeaders"
                    :items="groupedTaxRules"
                    hide-actions
                    class="elevation-1"
            >
                <template slot="items" slot-scope="props">
                    <td class="text-xs-center" v-bind:key="taxRule[el]" v-for="(taxRule, el) in props.item">
                        <div class="taxCountries" v-if="Array.isArray(taxRule)">
                            <truncate :clamp="'...'" :length="50" :less="'less'"
                                      :text="`${taxRule.join()}`"></truncate>
                        </div>
                        <div v-else-if="typeof taxRule === 'boolean'">
                            <v-icon v-if="(el === 'company' && !taxRule ) || (el !== 'company' && props.item['company'])">
                                clear
                            </v-icon>
                            <v-icon v-else color="green">check_circle</v-icon>
                        </div>
                        <div v-else-if="el && typeof taxRule === 'object'">
                            <v-icon
                                    small
                                    @click="deleteItem(props.item)"
                            >
                                {{taxRule.icon}}
                            </v-icon>
                        </div>
                        <div v-else-if="el !== 'id'">
                            <span v-if="taxRule > 0"> +{{ taxRule }}</span>
                            <span v-else> {{ taxRule }}</span>
                        </div>
                        <div v-else></div>
                    </td>
                </template>
            </v-data-table>
        </v-flex>
        <v-dialog
                v-model="dialog"
                max-width="290"
        >
            <v-card>
                <v-card-title class="headline">{{getMessages.dialogTitle}}</v-card-title>
                <v-card-text>
                    {{getMessages.dialogDescription}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            class="vue-btn-success"
                            color="success"
                            flat="flat"
                            @click="removeTaxRule"
                    >
                        {{getMessages.confirm}}
                    </v-btn>
                    <v-btn
                            class="vue-btn-default"
                            lat="flat"
                            @click="dialog = false"
                    >
                        {{getMessages.form.clear}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
</template>
<script>
    import truncate from 'vue-truncate-collapsed/dist/vue-truncate-collapsed';
    import ClipLoader from '../../components/clip-loader.vue';
    import store from '../../store/taxes/store';

    export default {
        props: ['territories', 'tenant'],
        data() {
            return {
                dialog: false,
                color: 'orange',
                options: {
                    color: 'primary',
                    width: 290,
                    zIndex: 200
                },
                dropTaxRule: {}
            };
        },
        components: {
            truncate,
            // eslint-disable-next-line vue/no-unused-components
            store,
            ClipLoader
        },
        mounted() {
            store.commit('setSpinner', true);
            store.commit('setTerritories', this.territories);
            store.commit('setTenant', this.tenant);
            store.dispatch('FETCH_TAX_RULES');
        },
        methods: {
            /**
             * Remove Tax Rule by tax_group_id
             * @param item
             */
            deleteItem(item) {
                this.dialog = true;
                this.dropTaxRule = item;
            },
            /**
             * Remove Tax Rule Store call
             */
            removeTaxRule() {
                store.commit('setSpinner', true);
                store.dispatch('DELETE_TAX_RULE', this.dropTaxRule.id);
                this.dialog = false;
            }
        },
        filters: {
            /**
             * Display dots and "Show more" text if it's too long
             */
            truncate: ((value, limit) => {
                let val = value;
                if (value.length > limit) {
                    val = `${val.substring(0, (limit - 3))} ...`;
                }
                return val;
            })
        },
        computed: {
            getMessages() {
                return store.getters.getMessages;
            },
            getHeaders() {
                return store.getters.getHeaders;
            },
            groupedTaxRules() {
                return store.getters.getGroupedTaxRules;
            },
            getSpinner() {
                return store.getters.getSpinner;
            }
        }
    };
</script>
