<style lang="scss">
    .track-catalog {
        float: left;
        position: relative;
        border: 1px solid #C3C4BE;
        border-radius: 3px;
        width: 100%;
        min-height: 284px;
        padding: 10px;
    }

    .track-catalog__close {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 16px;
        height: 16px;
        fill: #C3C4BE;
        cursor: pointer;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .track-catalog__list {
        float: left;
        width: 100%;
        margin-top: 10px;
    }
</style>

<template>
    <div class="track-catalog">
        <h2>{{messages.title}}</h2>
        <search-box @search="search" class="search-box--full"></search-box>
        <span class="track-catalog__close" @click="close">
            <cross-icon viewBox="0 0 32 32"></cross-icon>
        </span>
            <transition-group name="fade-out" tag="ul" class="track-catalog__list">
                <track-item v-for="(track, index) in tracks"
                            :track="track" :key="index+0" :types="trackTypes" :disabledactiontracks="disabledactiontracks"
                            @add="add"></track-item>
            </transition-group>

        <pagination :data="pagination" @page="searchByPage" v-if="showPagination"></pagination>
        <loading-overlay v-if="isLoading"></loading-overlay>
    </div>
</template>

<script>
    import SearchBox from './search-box.vue';
    import TrackItem from './track-item.vue';
    import LoadingOverlay from './loading-overlay.vue';
    import Pagination from './pagination.vue';
    import CrossIcon from '../../svg/cross.svg';
    import Searcher from '../services/searcher';
    import Bus from '../bus';
    import { TRACK_TYPE } from '../constants/constants';
    import { _T } from '../globals';
    import Track from '../models/track';

    const searcher = new Searcher();

    export default {
        props: ['album', 'disabledactiontracks'],
        data() {
            return {
                tracks: [],
                trackTypes: [TRACK_TYPE.APPENDABLE],
                isLoading: false,
                pagination: {
                    page: 0,
                    total: 0,
                    total_items: 0
                },
                query: '',
                messages: {
                    title: _T('your_catalog')
                }
            };
        },
        components: {
            SearchBox,
            TrackItem,
            LoadingOverlay,
            Pagination,
            CrossIcon
        },
        mounted() {
            this.query = '';
            this.search(this.query);

            Bus.$on('album.updated', () => {
                this.search(this.query, this.pagination.page);
            });
        },
        methods: {
            search(query, page = 1) {
                this.isLoading = true;
                this.query = query;

                searcher.getTracks(this.album.id, this.query, page).then((response) => {
                    this.tracks = [];

                    response.data.items.forEach((track) => {
                        this.tracks.push(new Track(track));
                    });
                    this.pagination = response.data.info;
                    this.isLoading = false;
                });
            },
            searchByPage(page) {
                this.search(this.query, page);
            },
            add(track) {
                this.isLoading = true;
                this.$emit('add', track);
            },
            close() {
                this.$emit('close');
            }
        },
        computed: {
            showPagination() {
                return this.pagination.page > 0;
            }
        }
    };
</script>
