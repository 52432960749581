<style lang="scss">
.artificial-streaming-report-upload {
  position: relative;
}

.artificial-streaming-report-upload__label {
  float: left;
  margin-bottom: 10px;
  width: 100%;

  span {
    float: left;
    width: 15%;
  }

  .cform__input-group {
    float: left;
    width: 85%;
  }
}
</style>

<template>
    <form class="artificial-streaming-report-upload cform cform--small">
        <loading-overlay v-if="loading"></loading-overlay>
        <h2>{{ messages.title }}</h2>
        <label class="artificial-streaming-report-upload__label">
            <span>{{ messages.date }}</span>
            <date-picker
                class="date-range__input"
                :placeholder="messages.date"
                v-model="report_date"
                :type="type">
            </date-picker>
        </label>

        <label class="artificial-streaming-report-upload__label">
            <span>{{ messages.dsp }}</span>
            <select ref="seleccionado">
                <option value="Spo">Spo</option>
                <option value="Tik">Tik</option>
            </select>
        </label>

         <label class="artificial-streaming-report-upload__label">
          <span>{{ messages.description }}</span>
          <span class="cform__input-group">
                <input type="text" v-model="report.description">
          </span>
        </label>
        <label class="artificial-streaming-report-upload__label">
            <span>{{ messages.file }}</span>
            <input type="file" @change="addFile($event.target.files)" ref="file">
        </label>
        <input-button :text="messages.submit"
                      :class="'input-button--small'" @click="submit">
        </input-button>
      </form>
</template>

<script>
import InputButton from './form-elements/input-button.vue';
import LoadingOverlay from './loading-overlay.vue';
import artificialStreamingReport from '../models/artificial-streaming-report';
import DatePicker from './date-picker.vue';
import { _T } from '../globals';

export default {
    props: ['date', 'type'],
    components: { InputButton, LoadingOverlay, DatePicker },
    data() {
        return {
            messages: {
                title: _T('artificial_streaming_upload_report'),
                dsp: _T('dsp'),
                description: _T('description'),
                file: _T('report_to_upload'),
                submit:  _T('service_upload_only'),
                timeAlert: _T('artificial streaming import will take some time, please wait'),
                finished: _T('artificial streaming import finished'),
                error: _T('some error happened during artificial streaming upload or import'),
                date: _T('artificial_streaming_report_date')
            },
            loading: false,
            report: new artificialStreamingReport(),
            report_date: this.date
        };
    },
    methods: {
        addFile(files) {
            const [file] = files;
            this.report.file = file;
        },
        submit() {
            this.loading = true;
            alert(this.messages.timeAlert);
            this.report.report_date = this.report_date;
            this.report.dsp = this.$refs.seleccionado.value || 'Spo';
            this.report.upload()
                .then(() => {
                    this.$refs.file.value = null;
                    this.loading = false;
                    alert(this.messages.finished);
                    this.$emit('uploaded');
                })
                .catch((e) => {
                    this.$refs.file.value = null;
                    this.loading = false;
                    alert(e.response.data[0] || e);
                });
        }
    }
};
</script>
