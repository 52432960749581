import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import InputLabel from '../../components/form-elements/input-label.vue';

Vue.use(Vue2TouchEvents, {
    disableClick: true
})

export default {
    load() {
        return new Vue({
            el: '#videosInfoPage',
            name: 'videosInfoPage',
            components: { InputLabel }
        });
    }
};