import {_T} from '../globals';

export default {
    htmlOptions: {
        root: document.querySelector('#release'),
        placeholderText: _T('release')
    },
    onOptionSelect(item){

        const releaseHidden = document.querySelector('#release_id');
        releaseHidden.value = item.uuid;
    },
    renderOption(item) {
        const title = item.title;
        const ean13 = item.ean13 || '-';
        return `<span/>${title} (${ean13})</span>`;
    },
    getInputValue(item) {
        return item.title;
    },
    async fetchData(value) {
        const labelSelector = document.querySelector('#label_id');
        let url = '/api/1.0/releases?pager[offset]=1&pager[limit]=250&sort=title&filter[' +
            encodeURIComponent('+title') + ']=' +  encodeURIComponent('%' + value + '%') + '&filter[' +
            encodeURIComponent('+ean13') + ']=' +  encodeURIComponent('%' + value + '%');

        if (labelSelector !== null && labelSelector.value) {
            url += '&filter[' + encodeURIComponent('label_uuid') + ']=' + encodeURIComponent(labelSelector.value);
        }

        try {
            const response = await fetch(url);
            const data = await response.json();

            return data.items;
        } catch (e) {
            alert('Could not fetch details');
            throw e;
        }

    }
};

