import {_T} from '../globals';

export default {
    htmlOptions: {
        root: document.querySelector('#track'),
        placeholderText: _T('track')
    },
    onOptionSelect(item){
        const trackHidden = document.querySelector('#track_id');
        trackHidden.value = item.uuid;
    },
    renderOption(item) {
        const title = item.title;
        const isrc = item.isrc || '-';
        return `<span/>${title} (${isrc})</span>`;
    },
    getInputValue(item) {
        return item.title;
    },
    async fetchData(value) {

        try {
            const url = '/api/1.0/tracks?pager[offset]=1&pager[limit]=250&sort=title&filter[' +
                encodeURIComponent('+title') + ']=' + encodeURIComponent('%' + value + '%') +
                '&filter[' + encodeURIComponent('+isrc') + ']=' + encodeURIComponent('%' + value + '%');

            const response = await fetch(url);
            const data = await response.json();

            return data.items;
        } catch (e) {
            alert('Could not fetch details');
            throw e;
        }

    }
};

