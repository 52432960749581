import axios from 'axios';
import { _T } from '../globals';
import Bus from '../bus';
import GUID from '../helpers/guid';

export default class Tokenizer {
    constructor(options) {
        this.accepted = options.accepted;
        this.url = options.url;
        this.messages = {
            notAccepted: _T('uploader_file_not_accepted')
        };
    }

    getToken(files) {
        return new Promise((resolve, reject) => {
            const validFiles = this.getValidFiles(files);

            if (validFiles.length === 0) {
                reject();
            } else {
                this.doServerRequest(validFiles)
                    .then((response) => {
                        const newResponse = Object.assign({}, response);

                        newResponse.data = response.data.map((data, index) => {
                            const newData = Object.assign({}, data);

                            newData.file = validFiles[index];

                            return newData;
                        });

                        resolve(newResponse);
                        return newResponse;
                    }).catch((e) => {
                        reject(e);
                        return e;
                    });
            }
        });
    }

    getValidFiles(files) {
        const qttFiles = files.length;
        const validFiles = [];

        for (let i = 0; i < qttFiles; i += 1) {
            if (this.isAccepted(files[i])) {
                validFiles.push(files[i]);
            }
        }

        return validFiles;
    }

    doServerRequest(files) {
        const cmd = files.map(file => ({ id: GUID.get(), name: file.name }));

        return axios({
            method: 'post',
            url: this.url,
            data: JSON.stringify(cmd)
        });
    }

    isAccepted(file) {
        const accepted = this.accepted.includes(file.name.split('.').pop());

        if (!accepted) {
            const message = this.messages.notAccepted.replace('%s', file.name).replace('%s', this.accepted.join(', '));

            Bus.$emit('error.message', message);
        }

        return accepted;
    }
}
