<style lang="scss">
    .dropdown {
        position: relative;
        float: left;
        margin: 0 4px;
        cursor: pointer;
    }

    .dropdown__selected {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0;
    }

    .dropdown__selected span {
        display: inline-block;
        vertical-align: middle;
    }

    .dropdown__selected .dropdown__selected__text {
        font-size: 15px;
        padding-left: 8px;
        max-width: 74px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;
        color: #1e1e1e;
    }

    .dropdown__selected .dropdown__chevron {
        display: inline-block;
        max-width: initial;
        overflow: initial;
        width: 24px;
        height: 24px;
        transition: transform 0.2s;
    }

    .dropdown--open .dropdown__selected .dropdown__chevron {
        transform: rotate(-180deg);
    }

    .dropdown__selected .dropdown__chevron svg {
        width: 24px;
        height: 24px;
    }

    .dropdown__menu {
        position: absolute;
        border: none;
        margin: 4px 0 0 0;
        width: auto;
        background: #ffffff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 0;
        right: 0;
    }

    .dropdown__menu li {
        width: 100%;
        font-size: 15px;
        white-space: nowrap;
        padding: 8px 16px;
        color: #1e1e1e;
    }

    .dropdown__menu li:hover {
        background-color: rgba(49, 119, 251, 0.1);
    }
</style>

<template>
    <div class="dropdown" v-bind:class="{'dropdown--open': open}" @click="toggle">
        <div class="dropdown__selected">
            <slot name="icon"></slot>
            <span v-if="selected && selected.name" class="dropdown__selected__text" :title="selected.name">{{selected.name}}</span>
            <span class="dropdown__chevron"><chevron viewBox="0 0 24 24"></chevron></span>
        </div>
        <ul class="dropdown__menu" v-if="open">
            <li v-for="(option, index) in options" :key="index"
                @click="doAction(option)">{{option.name}}</li>
        </ul>
    </div>
</template>

<script>
    import Chevron from '../../svg/icon-chevron-down.svg';

    export default {
        props: ['selected', 'options'],
        components: { Chevron },
        data() {
            return {
                open: false
            };
        },
        methods: {
            toggle() {
                this.open = !this.open;
            },
            doAction(option) {
                this.$emit('optionSelected', option);
            }
        }
    };
</script>
