<template>
    <component :is="modal"></component>
</template>

<script>
    import Bus from '~phoenix/js/bus';
    import Terms from '~phoenix/js/modals/terms.vue';
    import TermsEnd from '~phoenix/js/modals/terms-end.vue';

    export default {
        components: {
            Terms,
            TermsEnd
        },
        data() {
            return {
                modal: null
            };
        },
        created() {
            Bus.$on('open.global.modal', this.openModal);
            Bus.$on('close.global.modal', this.closeModal);
        },
        methods: {
            openModal(name) {
                this.modal = name;
            },
            closeModal() {
                this.modal = null;
            }
        }
    };
</script>
