export default {
    bytesToHuman(bytes) {
        const units = ['bytes', 'kB', 'MB', 'GB', 'TB'];
        let size = bytes;
        let value = 0;

        units.forEach((unit) => {
            if (size > 1024) {
                size /= 1024.0;
            } else if (!value) {
                value = `${this.round(size, 2)} ${unit}`;
            }
        });

        return value;
    },
    round(number, digits) {
        const multiple = Math.pow(10, digits);

        return (Math.round(number * multiple) / multiple);
    }
};
