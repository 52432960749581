<style lang="scss">
    @import '../../scss/settings';

    .user-menu {
        border-top: 0.125rem solid $primary;
        min-width: 9.5rem;
        background: $white;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.07);
    }

    .user-menu__item {
        border-bottom: 0.0625rem solid $softGray;

        &:last-child {
            border-bottom: 0;
        }
    }

    .user-menu__link {
        position: relative;
        display: block;
        padding: $spacing;
        color: $darkGray;

        &:hover {
            color: $darkGray;
            background: rgba($gray, 0.1);
        }

        &.user-menu__link--active {
            &.user-menu__link--arrow:after {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: none;
                border-bottom: 5px solid $darkGray;
            }
        }

        &.user-menu__link--arrow {
            &:after {
                position: absolute;
                top: 50%;
                right: $spacing;
                margin-top: -2px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $darkGray;
                content: '';
            }
        }
    }

    .user-menu__submenu {
        border-top: 0.0625rem solid $softGray;
        padding: $spacing;
        background: $softerGray;
    }

    .user-menu__submenu-link {
        line-height: 22px;
        color: $darkGray;

        &.user-menu__submenu-link--active,
        &:hover {
            color: $darkGray;
            font-weight: 700;
        }
    }
</style>

<template>
    <ul class="user-menu">
        <li class="user-menu__item">
            <a class="user-menu__link"
               :href="links.myAccount"
               :title="titles.myAccount">{{titles.myAccount}}</a>
        </li>
        <li class="user-menu__item">
            <a class="user-menu__link user-menu__link--arrow"
               :class="{'user-menu__link--active': areLanguagesVisible}"
               @click.prevent="toggleLanguageSubmenu"
               href="#" :title="titles.language">{{titles.language}}</a>
            <ul class="user-menu__submenu" v-if="areLanguagesVisible">
                <li v-for="language in languages" :key="language.locale">
                    <a class="user-menu__submenu-link"
                       :class="{'user-menu__submenu-link--active': language.selected}"
                       @click.prevent="changeLanguage(language.locale)"
                       href="#">{{language.name}}</a>
                </li>
            </ul>
        </li>
        <li class="user-menu__item">
            <a class="user-menu__link"
               :href="links.logout"
               :title="titles.logout">{{titles.logout}}</a>
        </li>
    </ul>
</template>

<script>
    import Language from '../models/language';
    import { _T, doReload } from '../globals';

    export default {
        props: ['languages'],
        data() {
            return {
                areLanguagesVisible: false,
                titles: {
                    myAccount: _T('nav_account'),
                    language: _T('language'),
                    logout: _T('logout')
                },
                links: {
                    myAccount: '/user/profile',
                    logout: '/logout'
                }
            };
        },
        methods: {
            toggleLanguageSubmenu() {
                this.areLanguagesVisible = !this.areLanguagesVisible;
            },
            changeLanguage(locale) {
                document.body.style.cursor = 'wait';

                Language.change(locale)
                    .then(() => {
                        doReload();
                        document.body.style.cursor = 'default';
                    })
                    .catch(() => {
                        document.body.style.cursor = 'default';
                    });
            }
        }
    };
</script>
