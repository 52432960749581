<style lang="scss">
    .chart-legend {
        float: left;
        margin: 0;
        padding: 0 6px 6px 6px;
        width: 100%;
        list-style: none;
        font-size: 11px;
        background: #FFFFFF;
    }
    .chart-legend__item {
        position: relative;
        float: left;
        margin: 5px 10px;
        padding-left: 14px;
        margin-bottom: 6px;
    }
    .chart-legend__item:last-child {
        margin-bottom: 0;
    }
    .chart-legend__bubble {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
</style>

<template>
    <ul class="chart-legend" :style="{ paddingLeft: left + 'px'}">
        <li v-for="(item, index) in items" class="chart-legend__item" :key="index">
            <span class="chart-legend__bubble" :style="{ background: item.color }"></span>
            <span>{{item.values[0].name}}</span>
        </li>
    </ul>
</template>

<script>
    export default {
        props: ['items', 'left']
    };
</script>
