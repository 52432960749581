<style lang="scss">
    .uploader {
        float: left;
        width: 100%;
        padding: 15px 50px;

        .input-file {
            font-weight: 600;
        }
    }
    .uploader__button {
        display: inline-block;
        border-radius: 3px;
        padding: 8px 12px;
        background: #F5001D;
        font-size: 12px;
        color: #FFFFFF;
        font-weight: 600;
        cursor: pointer;
        clear: both;
    }
    .uploader__disclaimer {
        float: left;
        width: 100%;
        font-size: 12px;
    }
</style>

<template>
    <div class="uploader">
        <div v-if="uploader.isIdle()">
            <input-file
                    :text="messages.upload"
                    @change="upload($event.target.files)">
            </input-file>
            <span class="uploader__disclaimer" v-html="messages.restrictions"></span>
        </div>
        <div v-if="uploader.isBusy()">
            <alert :message="alert.uploading"></alert>
            <progress-bar :percentage="uploader.percentage"></progress-bar>
            <div class="uploader__button" @click="cancel" v-html="messages.cancel"></div>
        </div>
    </div>
</template>

<script>
    import Alert from './alert.vue';
    import InputFile from './input-file.vue';
    import Message from '../models/message';
    import { MESSAGE_TYPE } from '../constants/constants';
    import ProgressBar from './progress-bar.vue';
    import { _T } from '../globals';

    export default {
        props: ['uploader', 'restrictions'],
        components: { Alert, InputFile, ProgressBar },
        data() {
            return {
                messages: {
                    upload: _T('upload_audio_button'),
                    restrictions: this.restrictions,
                    cancel: _T('upload_audio_cancel')
                },
                alert: {
                    uploading: new Message(_T('upload_processing'), MESSAGE_TYPE.DEFAULT)
                }
            };
        },
        methods: {
            cancel() {
                this.$emit('cancel');
            },
            upload(files) {
                this.$emit('upload', files);
            }
        }
    };
</script>
