import Vue from 'vue';
import SearchBoxAmount from './components/search-box-amount.vue';

export default class BoxAmount {
    constructor(options) {
        return this.constructor.load(options);
    }

    static load(options) {
        return new Vue({
            el: '#searchBoxAmountApp',
            data: {
                title: options.title
            },
            components: { SearchBoxAmount },
            methods: {
                search(query, filters) {
                    this.onSearched(query, filters);
                },
                onSearched() {}
            }
        });
    }
}
