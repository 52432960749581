import axios from 'axios';
import Polling from '../helpers/polling';
import { PROCESSOR_STATUS } from '../constants/constants';

export default class Processor {
    constructor(options) {
        this.urls = {
            process: options.urls.process,
            remove: options.urls.remove
        };
        this.condition = options.condition;
        this.status = PROCESSOR_STATUS.IDLE;
        this.onProcessed = () => {};
        this.onProcessing = () => {};
        this.onUnprocessed = () => {};
        this.onRemoved = () => {};
        this.processed = () => { this.onProcessed(); };
        this.processing = (data) => { this.onProcessing(data); };
        this.unprocessed = () => { this.onUnprocessed(); };
        this.removed = () => { this.onRemoved(); };
    }

    process() {
        this.setStatusProcessing();

        Polling.poll(this.urls.process, (data) => {
            this.processing(data);
            return this.condition(data);
        }, 60000, 2500)
            .then(() => {
                this.setStatusIdle();
                this.processed();
            })
            .catch(() => {
                this.setStatusIdle();
                this.unprocessed();
            });
    }

    remove() {
        this.setStatusRemoving();

        axios({
            cancelToken: null,
            method: 'delete',
            url: this.urls.remove
        }).then(() => { this.setStatusIdle(); this.removed(); });
    }

    setStatusIdle() {
        this.status = PROCESSOR_STATUS.IDLE;
    }

    setStatusProcessing() {
        this.status = PROCESSOR_STATUS.PROCESSING;
    }

    setStatusRemoving() {
        this.status = PROCESSOR_STATUS.REMOVING;
    }

    isProcessing() {
        return this.status === PROCESSOR_STATUS.PROCESSING;
    }

    isRemoving() {
        return this.status === PROCESSOR_STATUS.REMOVING;
    }
}
