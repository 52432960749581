<style lang="scss">
    .notifier {
        position: fixed;
        top: 0;
        right: 0;
        padding: 10px;
        box-sizing: border-box;
        z-index: 100000;
    }
    .notifier__message {
        position: relative;
        float: right;
        margin-bottom: 0.625rem;
        border-radius: 0.25rem;
        max-width: 250px;
        padding: 22px;
        color: #FFFFFF;
        background: rgba(0, 0, 0, 0.75);
        font-size: 14px;
        text-align: center;
        clear: both;
    }
    .notifier__message.notifier__message--error {
        background: rgba(183, 0, 1, 0.75);
    }
</style>

<template>
    <div class="notifier">
        <div class="notifier__message"
             v-for="(message, index) in messages" :key="index"
             :class="{'notifier__message--error': message.isError()}">{{message.text}}</div>
        <notifier-uploader></notifier-uploader>
    </div>
</template>

<script>
    import NotifierUploader from './notifier-uploader.vue';
    import Bus from '../bus';
    import { MESSAGE_TYPE } from '../constants/constants';
    import Message from '../models/message';

    const delay = 3000;

    export default {
        components: { NotifierUploader },
        data() {
            return {
                messages: []
            };
        },
        created() {
            Bus.$on('default.message', (text) => { this.showMessage(text, MESSAGE_TYPE.DEFAULT); });
            Bus.$on('error.message', (text) => { this.showMessage(text, MESSAGE_TYPE.ERROR); });
        },
        methods: {
            showMessage(text, type) {
                const message = new Message(text, type);

                this.messages.push(message);
                setTimeout(() => { this.removeMessage(message.id); }, delay);
            },
            removeMessage(id) {
                let removeIndex = false;

                this.messages.forEach((message, index) => {
                    if (message.id === id) {
                        removeIndex = index;
                    }
                });

                if (removeIndex !== false) {
                    this.messages.splice(removeIndex, 1);
                }
            }
        }
    };
</script>
