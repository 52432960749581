import Vue from 'vue';
import CheckboxMulti from '../../components/form-elements/checkbox-multi.vue';
import LogoUpload from '../../components/logo-upload.vue';
import IconUpload from '../../components/icon-upload.vue';
import ChannelRepository from '../../models/channel/channel-repository';

export default {
    load(channel) {
        return new Vue({
            el: '#channelsInfoPage',
            components: { CheckboxMulti, LogoUpload, IconUpload },
            data: {
                logoModel: {
                    id: channel.id,
                    logo: channel.logo,
                    repository: ChannelRepository
                },
                iconModel: {
                    id: channel.id,
                    icon: channel.icon,
                    repository: ChannelRepository
                }
            }
        });
    }
};
