import Vue from 'vue';
import AudioFile from './models/audio-file';
import Player from './components/player.vue';
import Playlist from './models/playlist';
import Bus from './bus';
import { STATUS } from './constants/constants';

export default {
    load() {
        if (!document.getElementById('player')) {
            return false;
        }

        return new Vue({
            el: '#player',
            template: `<player :playlist="playlist"></player>`,
            data: {
                playlist: new Playlist()
            },
            components: { Player },
            created() {
                Bus.$on('play.track', this.addTrack);
            },
            methods: {
                addTrack(data) {
                    const track = new AudioFile(data);

                    if (track.isPlayable()) {
                        this.playlist.add(track);
                    }
                },
                removeTrack(id) {
                    this.playlist.removeIfExist(id);
                },
                addAlbum(album) {
                    const tracks = [];

                    album.forEach((data) => {
                        const track = new AudioFile(data);

                        if (track.isPlayable()) {
                            tracks.push(track);
                        }
                    });

                    this.playlist.addMultiple(tracks);
                },
                load() {
                    this.playlist.setStatus(STATUS.LOADING);
                },
                hasActivity() {
                    return this.playlist.status !== STATUS.CLEAN;
                }
            }
        });
    }
};
