import Vue from 'vue';
import TopMenu from './top-menu.vue';
import Bus from '../bus';

export default {
    load(options) {
        return new Vue({
            el: '#top-menu',
            template: `<top-menu :options="options" :isMyAccountActive="isMyAccountActive"></top-menu>`,
            data: {
                options,
                isMyAccountActive: false
            },
            components: { TopMenu },
            created() {
                Bus.$on('my.account.set.active', () => {
                    this.isMyAccountActive = true;
                });
            }
        });
    }
};
