import Vue from 'vue';
import FooterLinks from '../components/footer-links.vue';

export default {
    load(code, links) {
        return new Vue({
            el: '#new-footer',
            data: {
                code,
                links
            },
            components: { FooterLinks }
        });
    }
};
