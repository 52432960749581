<template>
  <div class="file-upload">
    <FileIcon class="file-upload__file-icon" />
    <label :for="id" class="file-upload__label">
        {{ dragDropText }}
    </label>
    <a
        role="button"
        class="file-upload__button"
        @click="$refs.file.click()"
    >
      {{ browseText }}
    </a>
    <input
        class="file-btn"
        ref="file"
        type="file"
        :accept="accept"
        :id="id"
        @click="clickIt"
        @change="browseFile"
    />
  </div>
</template>

<script>
import FileIcon from '../../svg/file.svg';

export default {
    name: 'FileUpload',
    components: { FileIcon },
    props: {
        accept: {
            type: String,
            default: ''
        },
        browseText: {
            type: String,
            default: ''
        },
        dragDropText: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        }
    },
    computed: {
        /**
     * Gets uploaded files using button
     */
        file: {
            cache: false,
            get () {
                return this.$refs.file
            }
        }
    },
    methods: {
        /**
       * Browse file using button
       */
        browseFile ($event) {
            const files = this.file.files ?? [];
            this.$emit('browseFile', files, $event);

        },
        removeSelectedFile() {
            this.$refs.file.value = null;
        },
        clickIt() {
            this.removeSelectedFile();
        }
    }
}
</script>

<style lang="scss" scoped>
.file-upload {
  display: inline-flex;

  &__file-icon {
    margin-right: 0.5rem;
  }

  &__label {
    padding-left: 0;
  }

  &__label,
  &__button {
      font-size: 1rem;
  }

  &__button {
      padding: 0 0.35rem;
      text-decoration: underline;

      &:hover {
          opacity: 0.8;
      }
  }
}
input.file-btn {
  display: none;
}
</style>
