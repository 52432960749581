import Vue from 'vue';
import SubNavigation from './sub-navigation.vue';

export default {
    load(links) {
        if (!document.getElementById('sub-navigation')) {
            return false;
        }

        return new Vue({
            el: '#sub-navigation',
            template: `<sub-navigation :links="links"></sub-navigation>`,
            data: { links },
            components: { SubNavigation }
        });
    }
};
