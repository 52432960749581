import Vue from 'vue';
import SearchBoxDateAmount from './components/search-box-date-amount.vue';

export default class BoxDateAmount {
    constructor() {
        return this.constructor.load();
    }

    static load() {
        return new Vue({
            el: '#searchBoxDateAmountApp',
            components: { SearchBoxDateAmount },
            methods: {
                search(query, filters) {
                    this.onSearched(query, filters);
                },
                onSearched() {}
            }
        });
    }
}
