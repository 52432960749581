<style lang="scss">
    .multicheck {
        &__label {
            padding-left: 0;
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;

            > input {
                margin-right: 0.25rem;
            }
        }
    }
</style>

<template>
    <div :id="'multicheck-' + model">
        <label class="multicheck__label" :for="'all' + model + 'Checkbox'">
            <input type="checkbox" :id="'all' + model + 'Checkbox'"
                   v-model="allSelected" @change="allCheckboxChange" /> All {{model}}
        </label>

        <br />

        <template v-for="(option, index) in options">
            <div class="multicheck" :key="index">
                <label class="multicheck__label" :for="model + '-' + option[textField]">
                    <input @change="callUpdate" :id="model + '-' + option[textField]"
                           type="checkbox" :value="option[textField]"
                           v-model="selected" /> {{ option[textField] }}
                </label>
            </div>
        </template>
    </div>
</template>

<script>
import { $ } from '../../globals';

export default {
    props: {
        model: { type: String, required: true },
        textField: { type: String, required: true },
        allSelectedAtStart: { type: Boolean },
        selectedAtStart: { type: Array }
    },
    data() {
        return {
            options: {},
            selected: [],
            allSelected: false
        };
    },
    created() {
        this.allSelected = this.allSelectedAtStart;

        const that = this;
        $.get(`/api/1.0/${this.model}`, (data) => {
            that.options = data.items;

            const itemsToSelect = data.items.filter(o => that.selectedAtStart.indexOf(o.id) !== -1);

            that.selected = itemsToSelect.reduce((result, value) => {
                result.push(value[that.textField]);
                return result;
            }, []);

            that.updateValues();
        }).fail(() => {});
    },
    methods: {
        callUpdate() {
            if (this.selected.length) {
                this.allSelected = false;
            }
            this.updateValues();
        },
        allCheckboxChange() {
            if (this.allSelected) {
                this.selected = [];
            }
            this.updateValues();
        },
        updateValues() {
            $(`#hiddenAll-${this.model}`).val(JSON.stringify(this.allSelected));
            $(`#hiddenSelected-${this.model}`).val(JSON.stringify(this.selected)).trigger('change');
        }
    }
};
</script>
