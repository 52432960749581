<style lang="scss">
    .input-button {
        position: relative;
        display: inline-block;
        border-radius: 3px;
        margin-bottom: 15px;
        padding: 10px 15px;
        font-size: 16px;
        cursor: pointer;
        clear: both;

        &.input-button--cancel {
            //TODO: add cancel color using scss variables, the color is set in the tenant.css now.
        }

        &.input-button--no-margin {
            margin-bottom: 0;
        }

        &.input-button--small {
            font-size: 12px;
            padding: 8px 12px;
        }
    }
</style>

<template>
    <span class="input-button" :class="classObject" @click="click">{{text}}</span>
</template>

<script>
    export default {
        props: ['text', 'cancel', 'no-margin', 'small'],
        methods: {
            click() {
                this.$emit('click');
            }
        },
        computed: {
            classObject() {
                return {
                    'button__primary--ghost': this.cancel,
                    'input-button--no-margin': this.noMargin,
                    'input-button--small': this.small
                };
            }
        }
    };
</script>
