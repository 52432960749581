import axios from 'axios';
import { API } from '../constants/constants';

function urlBuilder(data) {
    let url = data.baseUrl;

    if (data.filters) {
        const filters = Object.assign({}, data.filters);

        url += '/filter';
        url = Object.keys(filters).reduce((total, key) => {
            const value = key.charAt(0) === '+' ? `%${filters[key]}%` : `${filters[key]}`;

            return `${total}/${encodeURIComponent(key)}/${encodeURIComponent(value)}`;
        }, url);
    }

    if (data.order) {
        const orderBy = Object.keys(data.order)[0];

        url += `/order/${orderBy}/${data.order[orderBy]}`;
    }

    if (data.pager) {
        url += `?pager[offset]=${data.pager.offset}&pager[limit]=${data.pager.limit}`;
    }

    return url;
}

export default class Searcher {
    constructor(page = 1, limit = 5) {
        this.page = page;
        this.limit = limit;
    }

    getTracks(albumId, query, page = 1) {
        const text = query.trim();

        this.page = page;

        const url = urlBuilder({
            baseUrl: API.TRACKS_PAGER,
            filters: {
                in_album: `!${albumId}`,
                '+title': text,
                '+remix_or_version': text,
                '+display_artist': text,
                '+isrc': text.replace(/-/g, ''),
                '+email': text
            },
            order: { created_at: 'desc' },
            pager: { offset: ((this.limit * (this.page - 1)) + 1), limit: this.limit }
        });

        return axios.get(url);
    }
}
