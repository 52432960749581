import Vue from 'vue';
import FooterLogo from '../components/footer-logo.vue';

export default {
    load(code) {
        return new Vue({
            el: '#old-footer',
            data: {
                code
            },
            components: { FooterLogo }
        });
    }
};
