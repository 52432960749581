
export default class SalesReportDescriptionResolver {

    constructor() {
        this.mapping = [];

        this.mapping['AKAZOO'] = 'AKAZOO';
        this.mapping['AKAZOOMERLIN'] = 'AKAZOO';
        this.mapping['ALIBABAMERLIN'] = 'ALIBABA';
        this.mapping['AMZ'] = 'AMZ';
        this.mapping['AMZADS1'] = 'AMZADS';
        this.mapping['AMZADS2'] = 'AMZADS';
        this.mapping['AMZJP'] = 'AMZJP';
        this.mapping['AMZPRM1'] = 'AMZPRM';
        this.mapping['AMZPRM2'] = 'AMZPRM';
        this.mapping['AMZPRM3'] = 'AMZPRM';
        this.mapping['AMZPRM4'] = 'AMZPRM';
        this.mapping['AMZPRM5'] = 'AMZPRM';
        this.mapping['AMZUNL1'] = 'AMZUNL';
        this.mapping['AMZUNL2'] = 'AMZUNL';
        this.mapping['AMZUNL3'] = 'AMZUNL';
        this.mapping['AMZUNL4'] = 'AMZUNL';
        this.mapping['AMZUNL5'] = 'AMZUNL';
        this.mapping['AMZUNL6'] = 'AMZUNL';
        this.mapping['ANGHAMI2MERLIN'] = 'ANGHAMI';
        this.mapping['ANGHAMIMERLIN'] = 'ANGHAMI';
        this.mapping['APPLM1'] = 'APPLM';
        this.mapping['APPLM2'] = 'APPLM';
        this.mapping['APPLM3'] = 'APPLM';
        this.mapping['APPLM4'] = 'APPLM';
        this.mapping['AWA'] = 'AWA';
        this.mapping['AWA2MERLIN'] = 'AWA';
        this.mapping['AWAMERLIN'] = 'AWA';
        this.mapping['BEA'] = 'BEA';
        this.mapping['BOOMPLAY'] = 'BOOMPLAY';
        this.mapping['BOOMPLAYMERLIN'] = 'BOOMPLAY';
        this.mapping['CLARO'] = 'CLARO';
        this.mapping['DEE'] = 'DEE';
        this.mapping['DEE2'] = 'DEE';
        this.mapping['DEEMERLIN'] = 'DEE';
        this.mapping['DIG'] = 'DIG';
        this.mapping['FBRMY1MERLIN'] = 'FBRM_y1';
        this.mapping['FBRMY2MERLIN'] = 'FBRM_y2';
        this.mapping['FBRMY3MERLIN'] = 'FBRM_y3';
        this.mapping['FBRMY4CONSMERLIN'] = 'FBRM_y4';
        this.mapping['FBRMY4PRODMERLIN'] = 'FBRM_y4';
        this.mapping['GOOL'] = 'GOOL';
        this.mapping['GOOLMERLIN'] = 'GOOL';
        this.mapping['GOOP'] = 'GOOP';
        this.mapping['GOOS'] = 'GOOS';
        this.mapping['GOOSMERLIN'] = 'GOOS';
        this.mapping['GOOT'] = 'GOOT';
        this.mapping['GOOTMERLIN'] = 'GOOT';
        this.mapping['GROOVED'] = 'GROOVED';
        this.mapping['GROOVES'] = 'GROOVES';
        this.mapping['IHEARTMERLIN'] = 'IHEART';
        this.mapping['ITM'] = 'ITM';
        this.mapping['JAXSTAMERLIN'] = 'JAXSTA';
        this.mapping['JIOSAAVNMERLIN'] = 'JIOSAAVN';
        this.mapping['JNO'] = 'JNO';
        this.mapping['JOOXMERLIN'] = 'JOOX';
        this.mapping['KDM1'] = 'KDM';
        this.mapping['KDM2'] = 'KDM';
        this.mapping['KKBOX2MERLIN'] = 'KKBOX';
        this.mapping['KKBOXMERLIN'] = 'KKBOX';
        this.mapping['MIXCLOUD1MERLIN'] = 'MIXCLOUD';
        this.mapping['MIXCLOUD2MERLIN'] = 'MIXCLOUD';
        this.mapping['MONKME'] = 'MONKME';
        this.mapping['NETE2MERLIN'] = 'NETE';
        this.mapping['NETEMERLIN'] = 'NETE';
        this.mapping['PAND211FAMMERLIN'] = 'PAND_fam';
        this.mapping['PAND211T1MERLIN'] = 'PAND_t1';
        this.mapping['PAND211T2MERLIN'] = 'PAND_t2';
        this.mapping['PAND211T3MERLIN'] = 'PAND_t3';
        this.mapping['PAND21FAM'] = 'PAND_fam';
        this.mapping['PAND21FAMMERLIN'] = 'PAND_fam';
        this.mapping['PAND21T1'] = 'PAND_t1';
        this.mapping['PAND21T1MERLIN'] = 'PAND_t1';
        this.mapping['PAND21T2'] = 'PAND_t2';
        this.mapping['PAND21T2MERLIN'] = 'PAND_t2';
        this.mapping['PAND21T3'] = 'PAND_t3';
        this.mapping['PAND21T3MERLIN'] = 'PAND_t3';
        this.mapping['PELOTONMERLIN'] = 'PELOTON';
        this.mapping['RESSOMERLIN'] = 'RESSO';
        this.mapping['RHP'] = 'RHP';
        this.mapping['ROXIMERLIN'] = 'ROXI';
        this.mapping['RSHEX'] = 'RSHEX';
        this.mapping['SBERZVUKMERLIN'] = 'SBERZVUK';
        this.mapping['SCLOUD'] = 'SCLOUD';
        this.mapping['SCLOUDMERLIN'] = 'SCLOUD';
        this.mapping['SNAPCHATMERLIN'] = 'SNAPCHAT';
        this.mapping['SONOSUITE1'] = 'SONOSUITE';
        this.mapping['SOUNDX'] = 'SOUNDX';
        this.mapping['SPO21MERLIN'] = 'SPO';
        this.mapping['SPO2MERLIN'] = 'SPO';
        this.mapping['SPO5'] = 'SPO';
        this.mapping['SPO51'] = 'SPO';
        this.mapping['TENCENT2MERLIN'] = 'TENCENT';
        this.mapping['TENCENTMERLIN'] = 'TENCENT';
        this.mapping['TIDAL'] = 'TIDAL';
        this.mapping['TIDALD'] = 'TIDALD';
        this.mapping['TIDALV'] = 'TIDALV';
        this.mapping['TIKTOK2MERLIN'] = 'TIKTOK';
        this.mapping['TIKTOKMERLIN'] = 'TIKTOK';
        this.mapping['TRAX'] = 'TRAX';
        this.mapping['TREBELMERLIN'] = 'TREBEL';
        this.mapping['UMA2MERLIN'] = 'UMA';
        this.mapping['UMAMERLIN'] = 'UMA';
        this.mapping['VEVO'] = 'VEVO';
        this.mapping['VEVO2MERLIN'] = 'VEVO';
        this.mapping['VEVO3MERLIN'] = 'VEVO';
        this.mapping['VEVOMERLIN'] = 'VEVO';
        this.mapping['XIAMI2MERLIN'] = 'XIAMI';
        this.mapping['XIAMIMERLIN'] = 'XIAMI';
        this.mapping['YANDEX2MERLIN'] = 'YANDEX';
        this.mapping['YANDEX3MERLIN'] = 'YANDEX';
        this.mapping['YANDEXMERLIN'] = 'YANDEX';
        this.mapping['YOUADS01'] = 'YOUADS';
        this.mapping['YOUADS10'] = 'YOUADS10';
        this.mapping['YOUATADSVIDEOCLAIM10'] = 'YOUAT_adsvideoclaim';
        this.mapping['YOUATVIDEO11'] = 'YOUAT_video';
        this.mapping['YOUCRED'] = 'YOUC_redvideo';
        this.mapping['YOUCVIDEOCLAIM'] = 'YOUC_videoclaim';
        this.mapping['YOUCVIDEOCLAIM10'] = 'YOUC_videoclaim10';
        this.mapping['YOUREDVIDEO11'] = 'YOU_video';
        this.mapping['YOUVCADS10'] = 'YOUV_ads10';
        this.mapping['YOUVCREDADS11'] = 'YOUV_ads';
        this.mapping['YOUVREDVIDEO11'] = 'YOUVRED_video11';
        this.mapping['YOUVVIDEOCLAIM10'] = 'YOUV_videoclaim10';
    }

    resolve(filename) {
        if (!filename) {
            return '';
        }

        let chunks = filename.split('.');

        let date = chunks[0].split('_')[0];

        let conversor = chunks[0].split('_')[1];

        if (!this.mapping[conversor]) {
            return '';
        }

        chunks.splice(0, 1); // Remove initial chunk (date + conversor)
        chunks.splice(chunks.length - 4, 4); // Remove las fixed chunks ({tenant}.v1.transformed.tsv)

        chunks = chunks.map(chunk => {
            return chunk.toLowerCase();
        });

        return date + '_'+ this.mapping[conversor] + (chunks.length > 0 ? '_' + chunks.join('_') : '');
    }
}
