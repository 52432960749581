<style lang="scss" scoped>
.assets-box {
    height: 100%;
    text-align: center;

    &__cover {
        position: relative;
        display: inline-block;
        border: 1px solid #C3C4BE;
        margin-bottom: 10px;
        min-width: 356px;
        min-height: 356px;
        padding: 2px;

        img {
            display: block;
            max-width: 100%;
        }
    }

    &__cover-placeholder {
        position: relative;
        display: block;
        width: 350px;
        height: 350px;
        padding: 50%;
        fill: #C3C4BE;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -16px;
            margin-left: -16px;
            width: 32px;
            height: 32px;
        }
    }

    &__play {
        position: absolute;
        bottom: 6px;
        right: 6px;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        fill: #FFFFFF;
        background: darken(#C3C4BE, 10%);
        cursor: pointer;

        svg {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -8px;
            margin-left: -6px;
            width: 16px;
            height: 16px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__buttons-title {
        margin-right: 10px;
    }

    &__action-buttons {
      display: flex;;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    &__icon {
      display: flex;
      align-self: center;
      justify-content: center;

      &--download {
        align-items: center;
        width: 2rem;
        height: 2rem;
        background: #fff;
        border-radius: 0.25rem;
        cursor: pointer;
        z-index: 2;
      }
    }
}
</style>

<template>
    <div class="assets-box">
        <div class="assets-box__cover">
            <img :src="cover" v-if="cover" @error="imageError">
            <div class="assets-box__cover-placeholder" v-else>
                <warning-icon viewBox="0 0 32 32" v-if="isCoverLoaded"></warning-icon>
            </div>
            <div class="assets-box__action-buttons">
              <a v-if="coverSrc" :href="coverSrc" class="assets-box__icon assets-box__icon--download" target="_self">
                <download-icon aria-label="download" />
              </a>
            </div>
            <div class="assets-box__play" @click="play" v-if="hasTracks && hasMultipleTracks">
                <play-icon viewBox="0 0 32 32"></play-icon>
            </div>
        </div>
        <div class="assets-box__buttons" v-if="hasTracks">
            <span class="assets-box__buttons-title">{{messages.viewTypeLabel}}:</span>
            <button-group>
                <custom-button @click="selectView(0)"
                               :selected="trackViewIndex === 0">
                    {{messages.viewTypeBasic}}
                </custom-button>
                <custom-button @click="selectView(1)"
                               :selected="trackViewIndex === 1">
                    {{messages.viewTypeExtended}}
                </custom-button>
            </button-group>
        </div>
        <asset-track v-for="(releaseMetadataTrack, index) in assets.tracks"
                     :releaseMetadataTrack="releaseMetadataTrack"
                     :index="index + 1" :key="index" :extended="trackViewIndex === 1"></asset-track>
    </div>
</template>

<script>
    import AssetTrack from './asset-track.vue';
    import ButtonGroup from './button-group.vue';
    import CustomButton from './custom-button.vue';
    import PlayIcon from '../../svg/play.svg';
    import WarningIcon from '../../svg/warning.svg';
    import { _T, playReleaseAllTracks } from '../globals';
    import DownloadIcon from '../../svg/download-small.svg';

    export default {
        props: ['release', 'assets'],
        components: {
            AssetTrack,
            ButtonGroup,
            CustomButton,
            PlayIcon,
            WarningIcon,
            DownloadIcon
        },
        data() {
            return {
                cover: null,
                coverSrc: null,
                isCoverLoaded: false,
                trackViewIndex: 0,
                messages: {
                    viewTypeLabel: _T('view_type_label'),
                    viewTypeBasic: _T('view_type_basic'),
                    viewTypeExtended: _T('view_type_extended')
                }
            };
        },
        mounted() {
            this.release.loadCover()
                .then((data) => {
                    this.cover = data[350].href;
                    this.coverSrc = data.original.href;
                    this.isCoverLoaded = true;
                })
                .catch(() => {
                    this.cover = null;
                    this.coverSrc = null;
                    this.isCoverLoaded = true;
                });
        },
        methods: {
            play() {
                playReleaseAllTracks(this.release.original.uuid);
            },
            imageError() {
                this.cover = null;
                this.coverSrc = null;
            },
            selectView(index) {
                this.trackViewIndex = index;
            }
        },
        computed: {
            hasTracks() {
                return this.assets.tracks ? !!this.assets.tracks.length : false;
            },

            hasMultipleTracks() {
                return this.hasTracks && this.assets.tracks.length > 1;
            }
        }
    };
</script>
