<template>
    <div>
        <form v-for="(form, index) in forms" :key="index"
              class="cform">
            <loading-overlay v-if="form.isUpdating"></loading-overlay>
            <h2>{{form.title}}</h2>
            <div class="cform__group" v-for="(item, key) in form.items" :key="key">
                <label class="cform__label" :for="key">{{item.label}}</label>
                <div class="grid">
                    <div class="col-1-4">
                        <div class="cform__input-group">
                            <input-color :id="key" v-model="item.value"
                                         @focus="() => { onFocus(form, key); }"></input-color>
                        </div>
                    </div>
                </div>
                <span class="cform__feedback"
                      v-if="form.errors.has(key)" v-text="form.errors.get(key)"></span>
            </div>
            <div class="box__buttons" v-if="form.hasChanges()">
                <input-button :text="buttons.submit" no-margin="true"
                              @click="() => { onSubmit(form); }"></input-button>
                <input-button :text="buttons.reset" no-margin="true" cancel="true"
                              @click="() => { form.reset(); }"></input-button>
            </div>
        </form>
    </div>
</template>

<script>
    import Form from '../../../services/form';
    import InputButton from '../../../components/form-elements/input-button.vue';
    import InputColor from '../../../components/form-elements/input-color.vue';
    import LoadingOverlay from '../../../components/loading-overlay.vue';
    import { _T } from '../../../globals';
    import Bus from '../../../bus';

    export default {
        props: ['tenantBranding'],
        components: { InputButton, InputColor, LoadingOverlay },
        data() {
            return {
                forms: null,
                buttons: {
                    submit: _T('submit'),
                    reset: _T('Cancel')
                },
                messages: {
                    saved: _T('saved_and_updated')
                }
            };
        },
        created() {
            const forms = this.tenantBranding.colors;

            this.forms = Object.keys(forms).map(key => new Form(key, forms[key]));
        },
        methods: {
            onSubmit(form) {
                const data = form.values();

                form.isUpdating = true;
                form.errors.clearAll();

                this.tenantBranding.save(data)
                    .then(() => {
                        form.save();
                        Bus.$emit('default.message', this.messages.saved);
                        Bus.$emit('refresh.tenant.css');
                        form.isUpdating = false;
                    })
                    .catch((error) => {
                        form.errors.record(error.response.data);
                        form.isUpdating = false;
                    });
            },
            onFocus(form, key) {
                form.errors.clear(key);
                this.$forceUpdate();
            }
        }
    };
</script>
