import Vue from 'vue';
import GlobalModals from './components/global-modals.vue';

export default {
    load() {
        if (!document.getElementById('global-modals')) {
            return false;
        }

        return new Vue({
            el: '#global-modals',
            template: '<global-modals></global-modals>',
            data: {},
            components: { GlobalModals }
        });
    }
};
