import Vue from 'vue';
import Vuex from 'vuex';
import { _T } from '../../globals';
import ObjectFilter from '../../helpers/object-filter';
import TaxRulesRepository from '../../models/tax-rules/tax-rules';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        alert: {
            type: 'success',
            show: false,
            message: '',
            dismissible: true
        },
        alertErrors: {
            type: 'error',
            show: false,
            message: '',
            dismissible: true
        },
        displayAlert: false,
        groupedTaxRules: [],
        headers: [
            {
                align: 'center',
                sortable: false,
                text: _T('tax_rule_name'),
                value: 'tax_name'
            },
            {
                align: 'center',
                sortable: false,
                text: _T('tax_rule_countries'),
                value: 'territory'
            },
            {
                align: 'center',
                sortable: false,
                text: _T('tax_rule_individual'),
                value: 'individual'
            },
            {
                align: 'center',
                sortable: false,
                text: _T('tax_rule_company'),
                value: 'company'
            },
            {
                align: 'center',
                sortable: false,
                text: `${_T('tax_rule_rate')} %`,
                value: 'rate'
            },
            {
                align: 'center',
                sortable: false,
                text: '',
                value: 'actions'
            }
        ],
        keys: [],
        messages: {
            applicableCountries: _T('tax_rule_countries'),
            confirm: _T('tax_rule_delete'),
            dialogDescription: _T('tax_rule_delete_confirm_content'),
            dialogTitle: _T('tax_rule_delete_confirm_title'),
            form: {
                clear: _T('Cancel'),
                company: _T('tax_rule_company'),
                countries: _T('tax_rule_condition'),
                create: _T('tax_rule_create'),
                createErrors: _T('tax_rule_create_ko_multiple'),
                error: _T('tax_rule_action_ko'),
                individual: _T('tax_rule_individual'),
                created: _T('tax_rule_create_ok'),
                rate: _T('tax_rule_rate'),
                removed: _T('tax_rule_delete_ok'),
                title: _T('tax_rule_create'),
                type: _T('tax_rule_apply'),
                selectAll: _T('tax_rule_all_countries'),
                selectOnlyEu: _T('tax_rule_eu_countries'),
                clearAll: _T('tax_rule_clear_all_countries')
            },
            showLess: _T('show_less'),
            showMore: _T('show_more'),
            taxName: _T('tax_rule_name'),
            title: _T('tax_rule_list_title')
        },
        spinner: false,
        taxRule: {},
        taxRules: [],
        tenant: {},
        territories: []
    },
    mutations: {
        /**
         * Define alert status messages
         * @param state
         * @param data
         */
        setAlert(state, data) {
            state.alert = data;
        },
        /**
         * Define errors in case some promises in array (not all) generated errors
         * @param state
         * @param data
         */
        setAlertErrors(state, data) {
            state.alertErrors = data;
        },
        /**
         * Set Grouped Tax Rule by tax_group_id
         * @param state
         * @param data
         */
        setGroupedTaxRules(state, data) {
            state.groupedTaxRules = data;
        },
        /**
         * Set keys of object to display in list
         * @param state
         * @param keys
         */
        setKeys(state, keys) {
            state.keys = keys;
        },
        /**
         * Define displaying spinner value
         * @param state
         * @param value
         */
        setSpinner(state, value) {
            state.spinner = value;
        },
        /**
         * Set all Tax Rules (no grouped by tax_group_id)
         * @param state
         * @param data
         */
        setTaxRules(state, data) {
            state.taxRules = data;
        },
        /**
         * Set all available territories
         * @param state
         * @param territories
         */
        setTerritories(state, territories) {
            state.territories = territories;
        },
        /**
         * Set Tenant_id
         * @param state
         * @param tenant
         */
        setTenant(state, tenant) {
            state.tenant = tenant;
        }
    },
    actions: {
        /**
         * API Call get all Tax Rules by tenant_id
         * @param commit
         * @param state
         * @returns {Promise<void>}
         * @constructor
         */
        async FETCH_TAX_RULES({ commit, state }) {
            await TaxRulesRepository.getTaxRulesByTenant(state.tenant)
                .then((response) => {
                    let taxRules = response.data;
                    const groupedTaxRules = [];
                    commit('setTaxRules', taxRules);
                    // Then we extract all tax_rules grouped by tax_group_id
                    taxRules = ObjectFilter.groupBy(response.data, 'tax_group_id');
                    taxRules = Object.values(taxRules);
                    // Extract all headers keys to display on table list
                    const keys = state.headers.map(item => item.value);
                    commit('setKeys', keys);
                    // Set related territories of every tax_group_id
                    taxRules.forEach((taxRule, idx) => {
                        let allTerritories = [...new Set(taxRule.map(element => element.territory))];
                        allTerritories = [...new Set(allTerritories.map(item => (item.name)))];
                        const obj = {};
                        keys.forEach((key) => {
                            obj[key] = taxRules[idx][0][key] ? taxRules[idx][0][key] : false;
                        });
                        obj.id = taxRules[idx][0].id;
                        groupedTaxRules.push(obj);
                        // Define action and id in button
                        groupedTaxRules[idx].actions = {
                            icon: 'delete',
                            id: taxRule[idx] ? taxRule[idx].id : '',
                            tax_group_id: taxRules[idx][0].tax_group_id ? taxRules[idx][0].tax_group_id : ''
                        };
                        groupedTaxRules[idx].territory = allTerritories;
                    });
                    commit('setGroupedTaxRules', groupedTaxRules);
                })
                .catch((() => {
                    commit('setAlert', {
                        type: 'error',
                        show: true,
                        message: state.messages.form.error,
                        dismissible: true
                    });
                }))
                .finally(() => commit('setSpinner', false));
        },
        /**
         * Remove tax rule by tax_group_id
         * @param dispatch
         * @param commit
         * @param state
         * @param id
         * @returns {Promise<void>}
         * @constructor
         */
        async DELETE_TAX_RULE({ dispatch, commit, state }, id) {
            await TaxRulesRepository.deleteTaxRules(id)
                .then(() => {
                    commit('setSpinner', true);
                    dispatch('FETCH_TAX_RULES');
                    commit('setAlert', {
                        type: 'success',
                        show: true,
                        message: state.messages.form.removed,
                        dismissible: true
                    });
                })
                .catch(() => {
                    commit('setAlertErrors', {
                        show: true,
                        message: state.messages.form.error,
                        dismissible: true
                    });
                });
        },
        /**
         * Create tax rule by tax_group_id
         * @param dispatch
         * @param commit
         * @param state
         * @param newTaxRule
         * @returns {Promise<void>}
         * @constructor
         */
        async CREATE_TAX_RULE({ dispatch, commit, state }, newTaxRule) {
            await TaxRulesRepository.createTaxRules(newTaxRule)
                .then(() => {
                    commit('setSpinner', true);
                    dispatch('FETCH_TAX_RULES');
                    commit('setAlert', {
                        type: 'success',
                        show: true,
                        message: state.messages.form.created,
                        dismissible: true
                    });
                }, () => {
                    commit('setAlertErrors', {
                        show: true,
                        message: state.messages.form.createErrors,
                        dismissible: true
                    });
                });
        }
    },
    getters: {
        getAlert: state => state.alert,
        getAlertErrors: state => state.alertErrors,
        getGroupedTaxRules: state => state.groupedTaxRules,
        getHeaders: state => state.headers,
        getMessages: state => state.messages,
        getSpinner: state => state.spinner
    }
});
export default store;
