import { STATUS } from '../constants/constants';

export default class Playlist {
    constructor() {
        this.tracks = [];
        this.index = null;
        this.status = STATUS.CLEAN;
    }

    add(track) {
        this.setStatus(STATUS.LOADING);
        this.loadTrack(track);

        this.stop();
        this.index = null;
        this.tracks.unshift(track);

        if (this.tracks.length > 0) {
            this.playThis(0);
        } else {
            this.setStatus(STATUS.CLEAN);
        }
    }

    addMultiple(tracks) {
        this.setStatus(STATUS.LOADING);

        tracks.forEach((track) => {
            this.loadTrack(track);
        });

        this.stop();
        this.index = null;
        this.tracks = tracks.concat(this.tracks);

        if (this.tracks.length > 0) {
            this.playThis(0);
        } else {
            this.setStatus(STATUS.CLEAN);
        }
    }

    loadTrack(track) {
        const { playable } = track;

        this.removeIfExist(track.id);
        this.setStatus(STATUS.LOADING);
        playable.load();
        playable.onLoaded = (playableAudio) => { this.trackLoaded(playableAudio); };
        playable.onStarted = () => { this.setStatus(STATUS.PLAYING); };
        playable.onEnded = () => { this.trackEnded(); };
    }

    trackLoaded(playableAudio) {
        if (playableAudio.id === this.tracks[this.index].playable.id) {
            this.setStatus(STATUS.PLAYING);
        }
    }

    trackEnded() {
        this.setStatus(STATUS.PAUSED);
        this.next();
    }

    getTrackById(id) {
        let found = null;

        this.tracks.forEach((track, index) => {
            if (track.id === id) {
                found = {
                    track,
                    index
                };
            }
        });

        return found;
    }

    removeIfExist(id) {
        const track = this.getTrackById(id);

        if (track) {
            this.remove(track.index);
        }
    }

    remove(index) {
        this.tracks[index].playable.stop();
        this.tracks[index].playable.clear();
        this.tracks.splice(index, 1);

        if (this.tracks.length === 0) {
            this.clear();
        } else if (index === this.index) {
            this.removePlaying();
        } else if (index < this.index) {
            this.index -= 1;
        }
    }

    removePlaying() {
        if (this.index >= this.tracks.length) {
            this.index = 0;
        }

        this.playThis(this.index, true);
    }

    playThis(index, force = false) {
        if (force || this.index !== index) {
            this.stop();
            this.setStatus(STATUS.LOADING);
            this.index = index;
            this.play();
        }
    }

    prev() {
        if (this.index > 0) {
            this.playThis(this.index - 1);
        }
    }

    next() {
        if (this.index < (this.tracks.length - 1)) {
            this.playThis(this.index + 1);
        }
    }

    play() {
        this.tracks[this.index].playable.play();
    }

    pause() {
        this.tracks[this.index].playable.pause();
        this.setStatus(STATUS.PAUSED);
    }

    stop() {
        if (this.index !== null) {
            this.setStatus(STATUS.PAUSED);
            this.tracks[this.index].playable.stop();
        }
    }

    clear() {
        this.tracks.forEach((track) => { track.playable.stop(); track.playable.clear(); });
        this.tracks = [];
        this.index = null;
        this.status = STATUS.CLEAN;
    }

    setStatus(status) {
        this.status = status;
    }
}
