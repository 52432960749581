import axios from 'axios';
import { _T } from '../globals';
import Bus from '../bus';
import GuID from '../helpers/guid';
import Response from '../models/response';
import { UPLOADER_STATUS } from '../constants/constants';

export default class Uploader {
    constructor(options) {
        this.id = GuID.get();
        this.url = options.url;
        this.percentage = 0;
        this.source = axios.CancelToken.source();
        this.status = UPLOADER_STATUS.IDLE;
        this.messages = {
            uploadCancelled: _T('uploader_cancelled_by_user'),
            uploadError: _T('uploader_upload_error')
        };
        this.mute = false;
        this.isCanceled = false;
        this.onCanceled = () => {};
        this.onUploadEnded = () => {};
        this.canceled = () => { this.onCanceled(); };
        this.uploadEnd = () => { this.onUploadEnded(); };
    }

    addFile(file) {
        this.file = file;
    }

    upload() {
        const data = new FormData();

        data.append('name', this.file.name);
        data.append('file', this.file);

        this.setBusy();

        return new Promise((resolve, reject) => {
            if (this.isCanceled) {
                this.onError(this.messages.uploadCancelled);
                reject(this.messages.uploadCancelled);
            } else {
                axios({
                    data,
                    cancelToken: this.source.token,
                    method: 'post',
                    url: this.url,
                    onUploadProgress: this.onProgress.bind(this)
                }).then((response) => {
                    this.onSuccess(response);
                    resolve();
                }).catch((error) => {
                    this.onServerError(error);
                    reject(error);
                });
            }
        });
    }

    cancelUpload() {
        this.onError(this.messages.uploadCancelled);
        this.isCanceled = true;
        this.source.cancel(this.messages.uploadCancelled);
        this.source = axios.CancelToken.source();
        this.canceled();
    }

    onProgress(progressEvent) {
        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    }

    onSuccess(data) {
        const response = new Response(data);

        if (!response.isValid()) {
            throw new Error(this.messages.uploadError);
        } else {
            this.uploadFinished();
        }
    }

    onServerError(error) {
        let response;

        if (axios.isCancel(error)) {
            response = error.message;
        } else if (error.response) {
            response = new Response(error.response).info;
        } else {
            response = error.message;
        }

        this.onError(response);
    }

    onError(response) {
        this.percentage = 0;
        this.setIdle();

        if (!this.isCanceled) {
            Bus.$emit('remove.uploader', this.id);

            if (!this.mute) {
                Bus.$emit('error.message', response);
            }
        }

        this.isCanceled = false;
    }

    uploadFinished() {
        this.percentage = 0;
        this.setIdle();
        Bus.$emit('remove.uploader', this.id);
        this.uploadEnd();
    }

    isIdle() {
        return this.status === UPLOADER_STATUS.IDLE;
    }

    isBusy() {
        return this.status === UPLOADER_STATUS.BUSY;
    }

    setIdle() {
        this.status = UPLOADER_STATUS.IDLE;
    }

    setBusy() {
        this.status = UPLOADER_STATUS.BUSY;
    }

    toggleMute(isMute = false) {
        this.mute = isMute;
    }
}
