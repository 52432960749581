import ObjectFilter from '../../helpers/object-filter';
import ExplicitLanguage from '../explicit-language/explicit-language';
import ArtistsRoles from '../artists-roles';

function setBasic(data, info, artists) {
    const basic = {
        info: {},
        artists: {},
        audio: {}
    };

    basic.info = ObjectFilter.pickFields(Object.assign({}, data, info), [
        'is_cover',
        'is_approved_cover',
        'created_by',
        'display_artist',
        'isrc',
        'language',
        'explicit_language'
    ]);

    basic.artists = artists;

    return basic;
}

function setExtended(info, artists, audio) {
    return { info, artists, audio };
}

export default class ReleaseMetadataTrack {
    constructor(data, repository) {
        const audio = ObjectFilter.omitFields(ObjectFilter.pickField(data, 'audio'), ['uuid']);
        const info = ObjectFilter.omitFields(data, ['artists', 'uuid', 'title', 'audio']);
        const artistRoles = new ArtistsRoles(data.artists);
        const artists = artistRoles.namesByRoles();

        if (info.explicit_language !== undefined) {
            info.explicit_language = new ExplicitLanguage(info.explicit_language).translate();
        }

        this.basic = setBasic(data, info, artists);
        this.extended = setExtended(info, artists, audio);
        this.uuid = ObjectFilter.pickField(data, 'uuid');
        this.original = data;
        this.repository = repository;
    }

    getTrack() {
        return this.repository.findByUUId(this.uuid);
    }
}
