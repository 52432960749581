<style lang="scss">
.report-upload {
  position: relative;
  margin-bottom: 20px;
}

.report-upload__label {
  display: block;
  margin-bottom: 15px;
  width: 100%;

  &.label_error {
    .date-picker,
    select,
    .select2-container--default .select2-selection--single {
      border: 1px solid #f00;
    }
  }

  .label_title {
    display: inline-block;
    vertical-align: top;
    width: 15%;
  }
  .label_content {
    display: inline-block;
    vertical-align: top;
    width: 84%;
  }
}
</style>

<template>
  <form class="report-upload cform cform--small">
    <loading-overlay v-if="loading"></loading-overlay>
    <h2>{{ messages.title }}</h2><br><br>

    <label class="report-upload__label">
      <span class="label_title">INGESTION V2 (BETA)</span>
      <div class="label_content">
        <input type="checkbox" value="" ref="queued" v-model="queuedChecked">
      </div>
    </label>

    <label class="report-upload__label">
      <span class="label_title">Report File</span>
      <div class="label_content">
        <input type="file" @change="addFile($event.target.files)" ref="file" id="report-file">
      </div>
    </label>

    <label class="report-upload__label">
      <span class="label_title">{{ messages.description }}</span>
      <div class="label_content">
        <span class="cform__input-group">
            <input type="text" v-model="report.description" ref="file_description">
        </span>
      </div>
    </label>
    <br>

    <input-button :text="messages.submit"
                  :class="'input-button--small'" @click="submit"></input-button>
  </form>
</template>
<script>
import SalesReportDescriptionResolver from '../models/sales-report-description-resolver';
import InputButton from './form-elements/input-button.vue';
import LoadingOverlay from './loading-overlay.vue';
import SalesReport from '../models/sales-report';
import {_T,$} from '../globals';

export default {
    components: { InputButton, LoadingOverlay },
    data() {
        return {
            messages: {
                title: _T('upload_report'),
                description: _T('description'),
                file: _T('report_to_upload'),
                submit: _T('upload_report'),
                timeAlert: _T('channel sales import will take some time, please wait'),
                finished: _T('channel sales import finished'),
                error: _T('some error happened during channel sales upload or import')
            },
            loading: false,
            report: new SalesReport(),
            queuedChecked: false,
            descriptionResolver: new SalesReportDescriptionResolver()
        };
    },
    watch: {
        queuedChecked() {
            this.report.queued = this.queuedChecked;
        }
    },
    methods: {
        addFile(files) {
            const [file] = files;
            this.report.addFile(file);
            if(this.queuedChecked) {
                this.$refs.file_description.value = this.report.suggestDescription();
            }
        },
        submit() {
            $('.label_error').removeClass('label_error');
            if(null !== this.report.file){
                alert(this.messages.timeAlert);
                this.reportUpload();
            } else {
                alert('Report File is required');
            }
        },
        reportUpload: function (){
            this.loading = true;
            this.report.upload()
                .then(() => {
                    if(this.queuedChecked){
                        alert('☕ Your sales report are validating, take a look slack for see the progress.');
                    }
                    this.$refs.file.value = null;
                    this.loading = false;
                    alert(this.messages.finished);
                    this.$emit('uploaded');
                }).catch(() => {
                    this.$refs.file.value = null;
                    this.loading = false;
                    alert(this.messages.error);
                });
        }
    }
};
</script>
