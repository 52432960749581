<style lang="scss">
    .notifier-uploader {
        position: relative;
        float: right;
        margin-bottom: 0.625rem;
        border: 1px solid #c3c4be;
        border-radius: 0.25rem;
        max-width: 250px;
        padding: 0.625rem 0.625rem 0 0.625rem;
        color: black;
        background: #FFFFFF;
        font-size: 12px;
        clear: both;
        box-shadow: 2px 2px 5px rgba(0,0,0, 0.25);
    }

    h3.notifier-uploader__title {
        color: black;
        font-size: 12px;
    }

    .notifier-uploader__button {
        text-align: center;
    }
</style>

<template>
    <div class="notifier-uploader" v-if="uploaders.length">
        <h3 class="notifier-uploader__title">{{filesUpdating}}</h3>
        <ul>
            <li v-for="(uploader, index) in uploaders" :key="index">
                <span class="notifier-uploader__info">
                    {{uploader.file.name}} ({{getFileSize(uploader.file)}})
                </span>
                <progress-bar :percentage="uploader.percentage"></progress-bar>
                <div class="notifier-uploader__button">
                    <input-button :text="messages.cancel" cancel="true" small="true"
                                  @click="cancel(uploader)"></input-button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import InputButton from './form-elements/input-button.vue';
    import ProgressBar from './progress-bar.vue';
    import Bus from '../bus';
    import { _T } from '../globals';
    import Storage from '../helpers/storage';

    export default {
        components: { InputButton, ProgressBar },
        data() {
            return {
                uploaders: [],
                messages: {
                    uploading: _T('%s_files_uploading'),
                    cancel: _T('cancel')
                }
            };
        },
        created() {
            Bus.$on('add.uploader', (uploader) => { this.addUploader(uploader); });
            Bus.$on('remove.uploader', (uploaderId) => { this.removeUploader(uploaderId); });
        },
        methods: {
            addUploader(uploader) {
                if (this.uploaders.length === 0) {
                    Bus.$emit('upload.start');
                }

                this.uploaders.push(uploader);
            },
            removeUploader(id) {
                const index = this.uploaders.map(uploader => uploader.id).indexOf(id);

                this.uploaders.splice(index, 1);

                if (this.uploaders.length === 0) {
                    Bus.$emit('upload.end');
                }
            },
            getFileSize(file) {
                return Storage.bytesToHuman(file.size);
            },
            cancel(uploader) {
                uploader.cancelUpload();
            }
        },
        computed: {
            filesUpdating() {
                return this.messages.uploading.replace('%s', this.uploaders.length);
            }
        }
    };
</script>
