export const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

/**
 * Get bytes by size
 * @param size
 * @returns {string}
 */
 export const bytesFormatter = (size) => {
     let length = 0
     let number = parseInt(size, 10) || 0

     while (number >= 1024 && ++length) {
         number = number / 1024
     }
     // include a decimal point and a tenths-place digit if presenting
     // less than ten of KB or greater units
     return (number.toFixed(number < 10 && length > 0 ? 1 : 0) + ' ' + units[length])
 }
