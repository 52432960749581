import Tokenizer from './tokenizer';
import { API } from '../constants/constants';

export default class AlbumTokenizer extends Tokenizer {
    constructor(albumId) {
        super({
            accepted: ['flac', 'wav', 'fla'],
            url: API.ALBUM_AUDIO_TOKENS.replace('[id]', albumId)
        });
    }
}
