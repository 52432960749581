export default {
    filesToFormData(files) {
        const data = new FormData();

        files.forEach((file) => {
            data.append('file', file);
        });

        return data;
    },

    objectToFormData(object) {
        const data = new FormData();

        Object.keys(object).forEach((key) => {
            data.append(key, object[key]);
        });

        return data;
    }
};
