import Time from '../helpers/time';
import Storage from '../helpers/storage';
import { AUDIO_TYPE } from '../constants/constants';
import { _T } from '../globals';

export default class PlayableAudio {
    constructor(data) {
        this.id = data.id;
        this.url = data.url;
        this.metadata = data.metadata;
        this.time = data.time;
        this.bits_per_sample = data.bits_per_sample;
        this.sample_rate = data.sample_rate;
        this.type = Number(data.type);
        this.created_at = data.created_at;
        this.audio_type = this.type === AUDIO_TYPE.ORIGINAL ? 'original' : 'full';
        this.audio = new Audio();
        this.actual = Time.formatSeconds(0);
        this.length = Time.formatSeconds(0);
        this.percentage = 0;
        this.updatePercentage = true;
        this.state = data.state;
        this.analysis = data.analysis;
        this.onLoaded = () => {};
        this.onStarted = () => {};
        this.onEnded = () => {};
        this.loaded = (playableAudio) => { this.onLoaded(playableAudio); };
        this.started = () => { this.onStarted(); };
        this.ended = () => { this.onEnded(); };
    }

    load() {
        this.audio.src = this.url;

        this.audio.onloadeddata = () => {
            this.length = Time.formatSeconds(this.audio.duration);
            this.loaded(this);
        };

        this.audio.onplay = () => {
            if (this.audio.readyState === 4) {
                this.started();
            }
        };

        this.audio.onplaying = () => {
            this.started();
        };

        this.audio.ontimeupdate = () => {
            this.actual = Time.formatSeconds(this.audio.currentTime);

            if (this.updatePercentage) {
                this.percentage = this.calculatePercentage();
            }

            if (this.audio.currentTime >= this.audio.duration) {
                this.stop();
                this.ended();
            }
        };

        this.audio.load();
    }

    play() {
        if (process.env.NODE_ENV !== 'production') {
            this.audio.crossOrigin = 'anonymous';
        }
        this.audio.play().catch(() => {});
    }

    pause() {
        this.audio.pause();
    }

    stop() {
        this.pause();
        this.audio.currentTime = 0;
    }

    playFromPercentage(percentage) {
        this.audio.currentTime = (this.audio.duration * percentage) / 100;
    }

    calculatePercentage() {
        const duration = Number.isNaN(this.audio.duration) ? 0.1 : this.audio.duration;

        return Math.floor((this.audio.currentTime / duration) * 100);
    }

    toggleUpdatePercentage() {
        this.updatePercentage = !this.updatePercentage;
    }

    clear() {
        this.audio.url = '';
        this.audio.load();
        this.audio.onloadeddata = () => {};
        this.audio.onplay = () => {};
        this.audio.onplaying = () => {};
        this.audio.ontimeupdate = () => {};
        this.audio = new Audio();
    }

    metadataToString() {
        let str = `${this.metadata.format_name} ${Time.formatMilliseconds(this.time)} (${Storage.bytesToHuman(this.metadata.filesize)}),`;

        if (this.type === AUDIO_TYPE.ORIGINAL) {
            str += ` ${this.bits_per_sample} bits ${this.sample_rate} Hz ${this.metadata.channelmode.toUpperCase()}`;
        }

        str += ` ${Math.round(this.metadata.avg_bit_rate / 1024)} kbps ${this.metadata.bitrate_mode.toUpperCase()}`;

        if (this.type === AUDIO_TYPE.ORIGINAL) {
            str += ` ${_T('uploaded at %s', this.created_at)}`;
        }

        return str;
    }
}
