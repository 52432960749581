<style lang="scss">
    .input-file-release {
        position: relative;
        display: inline-block;
        border-radius: 3px;
        margin-bottom: 15px;
        /* TODO: Remove important, forced to add because of legacy */
        padding: 10px 15px !important;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        clear: both;
    }
    .input-file-release.input-file-release--small {
        margin-bottom: 0;
        /* TODO: Remove important, forced to add because of legacy */
        padding: 8px 12px !important;
        font-size: 12px;
        font-weight: 600;
    }
    .input-file-release input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 1%;
        height: 1%;
        z-index: -1;
    }
</style>

<template>
    <label class="input-file-release button__make-up ui-button ui-corner-all ui-widget" v-bind:class="{'input-file-release--small': type === 'small'}">
        <span v-html="text"></span>
        <input type="file" ref="input"
               :multiple="multiple" @change="change" @click="clean">
    </label>
</template>

<script>
    export default {
        props: ['text', 'type', 'multiple'],
        methods: {
            clean() {
                this.$refs.input.value = null;
            },
            change($event) {
                this.$emit('change', $event);
            }
        }
    };
</script>
