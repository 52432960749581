<style lang="scss">
    .user-navigation {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        z-index: 2;
    }

    .user-navigation * {
        box-sizing: border-box;
    }

    .user-navigation .dropdown {
        margin: 0 8px;
        // border: 1px solid #1E1E1E;
        border-radius: 999px;
        padding: 5px 8px;
    }
</style>

<template>
    <div class="user-navigation" v-if="userMenu.options">
        <dropdown-user :selected="userMenu.selected"
                       :options="userMenu.options"></dropdown-user>
        <dropdown-language :selected="languageMenu.selected"
                           :options="languageMenu.options"></dropdown-language>
    </div>
</template>

<script>
    import DropdownLanguage from './dropdown-language.vue';
    import DropdownUser from './dropdown-user.vue';

    export default {
        props: ['userMenu', 'languageMenu'],
        components: { DropdownLanguage, DropdownUser }
    };
</script>
