import { MESSAGE_TYPE } from '../constants/constants';
import GUID from '../helpers/guid';

export default class Message {
    constructor(text, type) {
        this.id = GUID.get();
        this.text = text;
        this.type = type;
    }

    isError() {
        return this.type === MESSAGE_TYPE.ERROR;
    }
}
