import Vue from 'vue';
import BulkUpload from './components/bulk-upload.vue';

export default {
    load() {
        if (!document.getElementById('bulk-upload')) {
            return false;
        }

        return new Vue({
            el: '#bulk-upload',
            template: `<bulk-upload @uploaded="uploaded"></bulk-upload>`,
            components: { BulkUpload },
            methods: {
                uploaded() {
                    this.onUploaded();
                },
                onUploaded() {}
            }
        });
    }
};
