<style type="scss">
    @import 'https://fonts.googleapis.com/css?family=Material+Icons';

    .alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
    .alert-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
    .alert {
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: .25rem;
    }
    .vue-btn-success {
        color: #fff;
        font-weight: 400;
        background-color: #28a745;
        border-color: #28a745;
    }
    .countriesList{
        max-height: 100px;
        overflow-y: auto;
        margin-bottom: 20px !important;
    }

    .margin-bottom-70 {
        margin-bottom: 70px ;
    }

</style>
<template>
    <div class="margin-bottom-70">
        <v-flex xs12 sm6 offset-sm3 d-flex>
            <v-form ref="form" v-model="valid" data-app class="vue-form" lazy-validation>
                <v-alert
                        v-model="getAlert.show"
                        :dismissible="getAlert.dismissible"
                        :type="getAlert.type"
                        v-bind:class="getAlert.type === 'success' ? 'mb-3 alert alert-success success' : 'alert alert-danger error'"
                >
                    {{getAlert.message}}
                </v-alert>
                <v-alert
                        v-model="getAlertErrors.show"
                        :dismissible="getAlertErrors.dismissible"
                        :type="getAlertErrors.type"
                        class="alert alert-danger error"
                >
                    {{getAlertErrors.message}}
                </v-alert>
                <h2>{{getMessages.form.title}}</h2>
                <v-flex xs12 sm12 d-block text-xs-left>
                    <p>{{getMessages.taxName}}</p>
                    <v-text-field
                            solo
                            v-model="taxRule.tax_name"
                            :rules="nameRules"
                            :counter="30"
                            required
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-block text-xs-left>
                    <p>{{getMessages.applicableCountries}}</p>
                    <v-autocomplete
                            class="countriesList"
                            v-model="territory"
                            :items="territories"
                            :filter="customFilter"
                            item-text="name"
                            item-value="id"
                            :rules="territoryRules"
                            :deletable-chips="true"
                            :autofocus="true"
                            hide-no-data
                            hide-selected
                            chips
                            solo
                            multiple
                            required
                    >
                    </v-autocomplete>
                    {{getMessages.form.countries}}
                    <v-btn small class="vue-btn-default" @click="selectAll">
                        {{getMessages.form.selectAll}}
                    </v-btn>
                    <v-btn small class="vue-btn-default" @click="selectEU">
                        {{getMessages.form.selectOnlyEu}}
                    </v-btn>
                    <span v-show="territory.length > 0">
                    <v-btn small class="vue-btn-default" @click="clearAll" >
                        {{getMessages.form.clearAll}}
                    </v-btn>
                </span>

                </v-flex>
                <v-flex xs12 sm12 d-block text-xs-left>
                    <p>{{getMessages.form.type}}</p>
                    <v-radio-group v-model="taxRuleType"
                                   :rules="taxRuleTypeRules"
                                   :mandatory="true"
                                   v-on:change="updateTaxRuleType"
                    >
                        <v-radio :label="getMessages.form.individual" value="individual"></v-radio>
                        <v-radio :label="getMessages.form.company" value="company"></v-radio>
                    </v-radio-group>
                </v-flex>
                <v-flex xs12 md12 d-flex text-xs-left>
                    <p>{{getMessages.form.rate}}</p>
                    <v-flex xs4 md4 d-inline>
                        <v-select solo
                                  v-model="taxRuleSign"
                                  :items="taxRuleSigns"
                                  target="#dropdown-example"
                        ></v-select>
                    </v-flex>
                    <v-flex xs6 md6 d-inline>
                        <v-text-field solo v-model="taxRule.rate"
                                      :min="0.00"
                                      :max="100.00"
                                      :step=".1"
                                      type="number"
                                      v-on:change="updateTaxRuleRate"
                                      :rules="rateRules"
                                      prefix="%"
                                      reverse>
                        </v-text-field>
                    </v-flex>
                </v-flex>
                <v-flex xs12 md12 d-inline>
                <span id="tenant-branding-container">
                    <v-btn v-bind:style="{ backgroundColor : brandingColor }" class="ui-button"  :disabled="!valid" @click="createTaxRule">
                        {{getMessages.form.create}}
                    </v-btn>
                </span>
                    <v-btn class="vue-btn-default" @click="clear">{{getMessages.form.clear}}</v-btn>
                </v-flex>
            </v-form>
        </v-flex>
    </div>
</template>
<script>
    import uuidv1 from 'uuid/v1';
    import jQuery from 'jquery';
    import store from '../../store/taxes/store';
    import { _T } from '../../globals';

    let $ = jQuery;

    export default {

        props: ['territories'],

        data() {
            return {
                valid: false,
                taxRuleSigns: ['+', '-'],
                taxRuleSign:  '+',
                searchInput: '',
                taxRule: {
                    tax_name: '',
                    territory_id: '',
                    individual: false,
                    company: false,
                    rate: 0
                },
                taxRuleType: '',
                territory: [],
                nameRules: [
                    v => !!v || _T('tax_rule_name_required'),
                    v => (v && v.length < 30) || _T('tax_rule_length_error')
                ],
                territoryRules: [
                    v => v.length > 0 || _T('territory_required')
                ],
                rateRules: [
                    v => !!v || _T('tax_rule_rate_required')
                ],
                taxRuleTypeRules: [
                    v => !!v || _T('tax_rule_type_required')
                ],
                isEditing: false,
                model: null,
                brandingColor: null
            };
        },

        computed: {
            getMessages() {
                return store.getters.getMessages;
            },
            getHeaders() {
                return store.getters.getHeaders;
            },
            groupedTaxRules() {
                return store.getters.getGroupedTaxRules;
            },
            getAlert() {
                return store.getters.getAlert;
            },
            getAlertErrors() {
                return store.getters.getAlertErrors;
            }
        },

        mounted() {
            this.getBrandingColor();
        },

        methods: {
            /**
             * Check max rate value in input and sign (- or +)
             * @param value
             * @returns {boolean}
             */
            updateTaxRuleRate(value) {
                if (value > 100) {
                    this.taxRule.rate = Math.abs(100);
                } else {
                    this.taxRule.rate = Math.abs(value);
                }
            },
            /**
             * Update individual/company selector
             * @param value
             * @returns {boolean}
             */
            updateTaxRuleType(value) {
                if (value === 'individual') {
                    this.taxRule.individual = true;
                    this.taxRule.company = false;
                } else {
                    this.taxRule.company = true;
                    this.taxRule.individual = false;
                }
            },
            /**
             * Custom filter on text to look for countries in autocomplete
             * @param item
             * @param queryText
             * @returns {boolean}
             */
            customFilter(item, queryText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1;
            },
            /**
             * Create tax rules action
             * @returns {boolean}
             */
            createTaxRule() {
                this.show = false;
                /**
                 * If form is valid, then generate single uuid and create an array
                 * of tax-rules by territory_id to call API
                 */
                if (this.$refs.form.validate()) {
                    this.taxRule.tax_group_id = uuidv1()
                        .replace(/-/g, '');

                    const newTaxRules = [];
                    this.territory.forEach((val) => {
                        if (this.taxRuleSign === '-') {
                            this.taxRule.rate = -Math.abs(this.taxRule.rate);
                        }
                        this.taxRule.rate = parseFloat(this.taxRule.rate);
                        newTaxRules.push({
                            company: this.taxRule.company,
                            individual: this.taxRule.individual,
                            rate: this.taxRule.rate,
                            tax_group_id: this.taxRule.tax_group_id,
                            tax_name: this.taxRule.tax_name,
                            territory_id: val
                        });
                    });
                    store.commit('setSpinner', true);
                    store.dispatch('CREATE_TAX_RULE', newTaxRules);
                    this.taxRule.rate = Math.abs(this.taxRule.rate);
                    store.dispatch('FETCH_TAX_RULES');
                    this.clear();
                }
            },
            /**
             * Select all Countries action
             */
            selectAll() {
                // Copy all v-select's items in territories array
                this.territory = this.territories.map(el => el.id);
            },

            /**
             * Select Only EU countries
             */
            selectEU() {
                this.territory = this.filterByEu().map(el => el.id);
            },

            /**
             * Remove all countries action
             */
            clearAll() {
                this.territory = [];
            },

            /**
             * Filter only countries on EU
             * */
            filterByEu() {
                return  this.territories.filter(el => el.is_eu);
            },

            /**
             * Reset form and set '+' sign default on select
             */
            clear() {
                this.valid = false;
                this.$refs.form.reset();
                this.taxRuleSign = this.taxRuleSigns[0];
            },

            /**
             * Workaround to be able to get tenant branding color.
             * */
            getBrandingColor() {
                this.brandingColor =  $('#tenant-branding-container > .ui-button').css('border-top-color');
            }
        }
    };
</script>
