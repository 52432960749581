import Tokenizer from './tokenizer';
import { API } from '../constants/constants';

export default class TrackTokenizer extends Tokenizer {
    constructor(trackId) {
        super({
            accepted: ['flac', 'wav', 'fla', 'WAV', 'FLAC', 'FLA'],
            url: API.TRACK_AUDIO_TOKENS.replace('[id]', trackId)
        });
    }
}
