<template>
    <div :class="cover ? 'image-upload-icon cover' : 'image-upload-icon'">
        <template>
            <img
                v-if="src"
                :src="src"
                :style="imageStyle"
                class="image-upload-icon__image"
                @mouseenter="toggleHover"
            />
            <template v-if="cover">
                <div
                    v-if="!src"
                    class="image-upload-icon__placeholder-container"
                    :style="imageStyle"
                >
                    <upload-icon class="image-upload-icon__icon image-upload-icon__icon--upload"/>
                    <div class="image-upload-icon__text image-upload-icon__text--placeholder">
                        {{ uploadImage }}
                    </div>
                </div>
                <div v-else>
                    <div v-if="hover"
                        :class="containerClass"
                        @mouseleave="toggleHover"
                    >
                        <div class="image-upload-icon__action-buttons">
                            <a 
                                v-if="originalSrc"
                                :href="originalSrc"
                                class="image-upload-icon__icon image-upload-icon__icon--download"
                                target="_self"
                            >
                                <download-icon aria-label="download" />
                            </a>
                            <div
                                v-if="deleteCover"
                                class="image-upload-icon__icon image-upload-icon__icon--trash"
                                @click="onDelete"
                            >
                                <trash-icon aria-label="delete"/>
                            </div>
                        </div>
                        <div
                            v-if="editCover"
                            class="image-upload-icon__text"
                        >
                            {{ uploadImage }}
                        </div>
                    </div>
                </div>
            </template>
            <input
                v-if="editCover"
                accept="image/*"
                type="file"
                id="image-upload-icon"
                class="image-upload-icon__input"
                ref="input"
                @change="onChange"
                @click="onClick"
            />
        </template>
    </div>
</template>

<script>
    import UploadIcon from '../../svg/upload.svg';
    import DownloadIcon from '../../svg/download-small.svg';
    import TrashIcon from '../../svg/trash.svg';
    import {_T} from '../globals';

    export default {
        props: {
            originalSrc: {
                type: String,
                default: ''
            },
            src: {
                type: String,
                default: ''
            },
            size: {
                type: String,
                default: ''
            },
            cover: {
                type: Boolean,
                default: false
            },
            editCover: {
                type: Boolean,
                default: false
            },
            deleteCover: {
                type: Boolean,
                default: false
            },
            uploadImage: {
                type: String,
                default: _T( 'Upload icon' )
            }

        },
        components: { DownloadIcon, UploadIcon, TrashIcon },
        data: () => ({
            hover: false
        }),
        computed: {
            containerClass() {
                return this.editCover ? 'image-upload-icon__hover-container' : 'image-upload-icon__hover-container no-pointer'
            },
            imageStyle() {
                const size = this.size.split('x');
                return {
                    width: `${parseInt(size[0], 10)}px`,
                    height: `${parseInt(size[1], 10)}px`
                };
            }
        },
        methods: {
            onChange($event) {
                this.$emit('change', $event);
            },
            onClick($event) {
                this.$refs.input.value = null;
                this.$emit('click', $event);
            },
            onDelete($event) {
                this.$emit('onDelete', $event);
            },
            toggleHover() {
                this.hover = !this.hover;
            }
        }
    };
</script>

<style lang="scss">
.image-upload-icon {
    display: block;
    position: relative;
    border-radius: 0.3125rem;
    border: 1px solid #d7d7d7;

    &.cover {
        border: none;
    }

    &__input {
        display: none;
    }

    &__hover-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        padding: 1rem;
        width: 100%;
        height: 100%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 0.3125rem;
        text-align: center;
        font-size: 0.5rem;
        z-index: 1;
        cursor: pointer;

        &.no-pointer {
            cursor: default;
        }
    }

    &__text {
        margin: 0rem 0 0rem 0;
        width: 100%;
        color: #fff;
        text-align: center;
        font-size: 0.7rem;

        &--placeholder {
            margin-top: 0.2rem;
            color: #000;
            background-color: transparent;
        }
    }

    &__image {
        display: block;
        border-radius: 0.3125rem;
        cursor: pointer;
    }

    &__placeholder-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: 0rem;
        border: 0.125rem dashed #B5B5B5;
        border-radius: 0.3125rem;
        width: 100%;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    &__action-buttons {
        display: flex;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    &__icon {
        display: flex;
        align-self: center;
        justify-content: center;
        
        &--upload {
            width: 3rem;
            height: auto;
        }
        
        &--trash,
        &--download {
            align-items: center;
            width: 2rem;
            height: 2rem;
            background: #fff;
            border-radius: 0.25rem;
            cursor: pointer;
            z-index: 2;
        }

        &--trash {
            margin-left: 0.5rem;
        }
    }
}
</style>
