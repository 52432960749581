import axios from 'axios';
import BulkUploadDetail from './bulk-upload-detail';
import { API } from '../../constants/constants';

export default {
    findByUUId(uuid) {
        return axios.get(API.BULK_UPLOAD_DETAIL.replace('[uuid]', uuid))
            .then(response => new BulkUploadDetail(response.data));
    }
};
