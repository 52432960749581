import axios from 'axios';
import { API } from '../../constants/constants';
import Ringtone from '../ringtone';
import FormsHelper from '../../helpers/forms';

export default {
    findByUUId(uuid) {
        return axios.get(API.RINGTONE.replace('[id]', uuid)).then(response => new Ringtone(response.data));
    },

    getAllCovers(uuid) {
        return axios.get(API.RINGTONE_COVER_INFO.replace('[id]', uuid)).then(response => response.data);
    },

    getCover(uuid, size) {
        return Promise.resolve(API.RINGTONE_COVER.replace('[id]', uuid).replace('[size]', size));
    },

    uploadCover(uuid, file) {
        return axios.post(API.RINGTONE_COVER.replace('[id]', uuid).replace('[size]', ''), FormsHelper.filesToFormData([file]));
    },

    deleteCover(uuid) {
        return axios.delete(API.RINGTONE_COVER.replace('[id]', uuid).replace('[size]', ''));
    }
};
