<template>
    <div
        :class="getContainerClass"
        :completed="cute ? completed : null"
        :cute="cute"
        :error="cute ? error : null"
        v-on="cute ? { updateValue: updateValue } : {}"
    >
        <div class="progress__bar">
            <div
                :class="cute ? `upload${getStatusClass}` : 'progress__inner'"
                :style="{ width: getPercentage }"
            />
        </div>
        <div :class="cute ? `progress__text${getStatusClass}` : 'progress__text'">
            <icon-error
                v-if="cute && completed && error"
                class="progress__icon"
            />
            <icon-success 
                v-if="cute && completed && !error"
                class="progress__icon"
            />
            {{ getPercentageText }}
        </div>
    </div>
</template>

<script>
    import { _T } from '../globals';
    import IconError from '../../svg/error.svg';
    import IconSuccess from '../../svg/success.svg';


    export default {
        name: 'ProgressBar',
        components: {
            IconError,
            IconSuccess
        },
        props: {
            /**
             * If set to true, implements the progress bar inside a list, use case developed for new BulkUpload UI
             */
            cute: {
                type: Boolean,
                default: false
            },
            /**
             * Upload progress percentage value. It gets updated via its updateValue method in BU uploads list, but when used in tracks and ringtones, its update is handled in an uploader Vue parent component
             */
            percentage: {
                type: [Number, String],
                default: '0'
            }
        },
        data: () => ({
            messages: {
                uploading: _T('percentage_uploaded')
            },
            localPercentage: 0,
            error: false
        }),
        created() {
            this.localPercentage = this.percentage
        },
        computed: {
            getPercentage () {
                if (this.cute) {
                    return `${this.localPercentage}%`
                }
                /** 
                 * Tracks & Ringtones progress bar percentage value 
                 * (as its update is handled in the uploader not in the progress bar itself) 
                */
                return `${this.percentage}%`
            },
            getPercentageText () {
                if (this.cute) {
                    return this.completed ?
                        this.error ? _T('error_ocurred')
                            : _T('uploading_complete')
                        : this.messages.uploading.replace('%s', this.getPercentage);
                } else {
                    // Tracks & Ringtones progress bar render text
                    return this.messages.uploading.replace('%s', this.getPercentage);
                }
                
            },
            completed () {
                return this.percentage.toString() === '100' ? true : false
            },
            getContainerClass () {
                return this.cute ? 'progress__cute' : 'progress'
            },
            getStatusClass () {
                return this.completed ?
                    this.error ? '--error'
                        : '--success'
                    : '--progress'
            }
        },
        methods: {
            updateValue (newPercentage, hasError) {
                this.error = hasError
                this.localPercentage = newPercentage
                this.$emit('updateValue', this.localPercentage, this.error)
            }
        }
    };
</script>

<style lang="scss">
    // Shared styles
    .progress,
    .progress__cute {
        width: 100%;

        &__bar {
            position: relative;
            display: block;
            width: 100%;
            overflow: hidden;
        }

        &__text {
            width: 100%;
        }
    }

    // Tracks & Ringtones progress bar
    .progress {
        display: block;
        margin: 10px 0;

        &__bar {
            margin-bottom: 10px;
            height: 10px;
            background: lightgrey;

            .progress__inner {
                position: absolute;
                width: 0;
                height: 10px;
                background: #3c763d;
            }
        }

        &__text {
            display: block;
            font-size: 12px;
            text-align: center;
            color: grey;
        }
    }

    // BU progress bar
    .progress__cute {
        display: flex;
        flex-flow: column-reverse;
        justify-content: flex-start;
        border-top: 0.0625rem solid #efefef;

        > .progress {
            &__text--progress,
            &__text--error,
            &__text--success {
                display: flex;
                margin: 0.625rem 0 0.25rem;
                color: #000;
                line-height: 1.1375rem;
                font-size: 0.8125rem;
                font-weight: 500;
                text-align: left;

                > .progress__icon {
                    margin-right: 0.25rem;
                }
            }

            &__bar {
                height: 0.25rem;
                border-radius: 0.125rem;
                background: #dfdfe0;
            }
        }

        .upload {
            &--progress,
            &--error,
            &--success {
                position: absolute;
                width: 0;
                height: 0.25rem;
            }

            &--progress {
                background: #00b0ff;
                border-radius: 100px 0 0 100px;
            }

            &--error,
            &--success {
                border-radius: 0.125rem;
            }

            &--error {
                border-radius: 0.125rem;
                background: #ff5350;
            }

            &--success {
                border-radius: 0.125rem;
                background: #2bac76;
            }
        }
    }
</style>
