import Vue from 'vue';
import Metadata from './metadata.vue';

export default {
    load(releaseId, userId) {

        Vue.filter('toString', (text) => {
            let newText = text;

            if (Array.isArray(newText)) {
                newText = newText.join(', ');
            }

            return newText;
        });

        return new Vue({
            el: '#metadataApp',
            template: `<metadata :releaseId="releaseId" :userId="userId"></metadata>`,
            components: { Metadata },
            data: {
                releaseId,
                userId
            }
        });
    }
};
