export default {
    toArray({ response: { data } }) {
        const errors = data[Object.keys(data)[0]];

        return Object.keys(errors).reduce((total, actual) => {
            total.push(Object.keys(errors[actual])[0]);

            return total;
        }, []);
    },

    toString(errors) {
        const separator = '; ';
        const items = errors.map(error => error.charAt(0).toUpperCase() + error.slice(1));

        return items.join(separator);
    }
};
