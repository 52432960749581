<style lang="scss">
    .pagination {
        float: left;
        margin-top: 10px;
        width: 100%;
    }

    .pagination__buttons {
        display: flex;
        float: left;
        width: 100%;
    }

    .pagination__button {
        margin: 0 1px;
        border: 1px solid #C3C4BE;
        border-radius: 3px;
        padding: 4px 6px;
        font-size: 12px;
        cursor: pointer;
        vertical-align: top;

        svg {
            display: block;
            width: 16px;
            height: 16px;
        }

        &:first-child {
            margin: 0 1px 0 0;
        }

        &:last-child {
            margin: 0 0 0 1px;
        }

        &.pagination__button--selected {
            background: lighten(#C3C4BE, 18%);
            cursor: default;
        }
    }

    .pagination__info {
        float: left;
        margin-top: 6px;
        width: 100%;
    }
</style>

<template>
    <div class="pagination">
        <div class="pagination__buttons">
            <span class="pagination__button" :class="firstButtonClass" @click="onFirstButtonClick">
                <first-icon viewBox="0 0 32 32"></first-icon>
            </span>
            <span class="pagination__button" :class="prevButtonClass" @click="onPrevButtonClick">
                <previous-icon viewBox="0 0 32 32"></previous-icon>
            </span>
            <span class="pagination__button" v-for="(page, index) in total" :key="index"
                  :class="{'pagination__button--selected': data.page === page}"
                  @click="data.page === page ? null: selected(page)">{{renderText(page)}}</span>
            <span class="pagination__button" :class="nextButtonClass" @click="onNextButtonClick">
                <next-icon viewBox="0 0 32 32"></next-icon>
            </span>
            <span class="pagination__button" :class="lastButtonClass" @click="onLastButtonClick">
                <last-icon viewBox="0 0 32 32"></last-icon>
            </span>
        </div>
        <div class="pagination__info">
            ({{resultText}})
        </div>
    </div>
</template>

<script>
    import FirstIcon from '../../svg/first.svg';
    import LastIcon from '../../svg/last.svg';
    import NextIcon from '../../svg/next.svg';
    import PreviousIcon from '../../svg/previous.svg';
    import { _T } from '../globals';

    const SIDE_ITEMS = 2;

    export default {
        props: ['data'],
        data() {
            return {
                init: 0,
                last: 0
            };
        },
        components: {
            FirstIcon,
            LastIcon,
            NextIcon,
            PreviousIcon
        },
        mounted() {
            this.renderPages();
        },
        updated() {
            this.renderPages();
        },
        methods: {
            selected(page) {
                this.$emit('page', page);
            },
            renderPages() {
                this.init = (this.data.page - SIDE_ITEMS < 1) ? 1 : this.data.page - SIDE_ITEMS;
                this.last = (this.data.page + SIDE_ITEMS > this.data.total) ?
                    this.data.total :
                    this.data.page + SIDE_ITEMS;
            },
            renderText(page) {
                let text = page;

                if (page === this.last && page < this.data.total) {
                    text = `${page}...`;
                }
                if (page === this.init && page > 1) {
                    text = `...${page}`;
                }

                return text;
            },
            onFirstButtonClick() {
                if (!(this.data.page === 1 || this.data.total === 0)) {
                    this.selected(1);
                }
            },
            onPrevButtonClick() {
                if (!(this.data.page === 1 || this.data.total === 0)) {
                    this.selected(this.data.page - 1);
                }
            },
            onNextButtonClick() {
                if (!(this.data.page === this.data.total || this.data.total === 0)) {
                    this.selected(this.data.page + 1);
                }
            },
            onLastButtonClick() {
                if (!(this.data.page === this.data.total || this.data.total === 0)) {
                    this.selected(this.data.total);
                }
            }
        },
        computed: {
            total() {
                const total = [];

                for (let i = this.init; i <= this.last; i += 1) {
                    total.push(i);
                }

                return total;
            },
            resultText() {
                return _T('%s records on %s pages', [this.data.total_items, this.data.total]);
            },
            firstButtonClass() {
                return {
                    'pagination__button--selected': this.data.page === 1 || this.data.total === 0
                };
            },
            prevButtonClass() {
                return {
                    'pagination__button--selected': this.data.page === 1 || this.data.total === 0
                };
            },
            nextButtonClass() {
                return {
                    'pagination__button--selected': this.data.page === this.data.total || this.data.total === 0
                };
            },
            lastButtonClass() {
                return {
                    'pagination__button--selected': this.data.page === this.data.total || this.data.total === 0
                };
            }
        }
    };
</script>
