import { _T } from '../../globals';

function setExplicit(explicit) {
    return explicit;
}

export default class ExplicitLanguage {
    constructor(explicit) {
        this.explicit = setExplicit(explicit);
    }

    translate() {
        return this.explicit ? _T(this.explicit) : _T('') ;
    }
}
