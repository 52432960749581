import axios from 'axios';
import { API } from '../constants/constants';

import SalesReportDescriptionResolver from './sales-report-description-resolver';

export default class SalesReport {
    constructor() {
        this.file = null;
        this.description = null;
        this.queued = false;
        this.descriptionResolver = new SalesReportDescriptionResolver();
    }

    addFile(file) {
        this.file = file;
        this.description = this.suggestDescription();
    }

    suggestDescription() {
        if (!this.file) {
            return '';
        }
        let fileNameUploaded = this.file.name;

        return fileNameUploaded.replace('.tsv', '');
        //return this.descriptionResolver.resolve(this.file.name);
    }

    cleanReportSidecarFields() {
        this.usage = null;
        this.source = null;
        this.start_date = null;
        this.end_date = null;
    }

    upload() {
        const data = new FormData();

        data.append('file', this.file);
        data.append('queued', this.queued);

        if (!this.file) {
            this.clean();
            return false;
        }

        if(!this.description){
            this.clean();
            return false
        }
        data.append('description', this.description);

        return this.uploadSalesReportCall(data);
    }

    uploadSalesReportCall(data) {
        return new Promise((resolve, reject) => {
            axios({
                data,
                method: 'post',
                url: API.CHANNEL_SALES_REPORT
            }).then(() => {
                this.clean();
                resolve();
            }).catch(() => {
                this.clean();
                reject();
            });
        });
    }

    clean() {
        this.description = '';
        this.file = null;
    }
}
