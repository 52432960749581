import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import AlbumRepository from '../../models/album/album-repository';
import CoverUploadRelease from '../../components/cover-upload-release.vue';
import InputText from '../../components/form-elements/input-text.vue';

Vue.use(Vue2TouchEvents, {
    disableClick: true
})

export default {
    load(album, editCover, deleteCover) {
        return new Vue({
            el: '#albumsInfoPage',
            name: 'albumsInfoPage',
            components: { CoverUploadRelease, InputText },
            data: {
                coverModel: {
                    uuid: album.uuid,
                    cover: album.cover,
                    repository: AlbumRepository,
                    editCover: editCover,
                    deleteCover: deleteCover
                }
            }
        });
    }
};
