<style lang="scss">
    .audio-upload {
        position: relative;
        float: left;
        border: 1px dashed #B5B5B5;
        background: #FFFFFF;
        text-align: center;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 16px;
    }

    .audio-upload * {
        box-sizing: border-box;
    }
</style>

<template>
    <div class="audio-upload">
        <uploader-release v-if="isUploaderVisible"
                  :uploader="config.file.uploader"
                  :restrictions="config.restrictions"
                  @upload="upload" @cancel="cancel"></uploader-release>
        <processor v-if="isProcessorVisible"
                   :processor="config.processor"
                   :file="config.file"
                   :delete-audiofile="deleteAudiofile"
                   :upload-audiofile="uploadAudiofile"
                   @remove="remove" @upload="upload"></processor>
    </div>
</template>

<script>
    import Processor from './processor.vue';
    import UploaderRelease from './uploader-release.vue';

    const COMPONENTS = {
        PROCESSOR: 'processor',
        UPLOADER: 'uploader'
    };

    export default {
        props: ['config', 'deleteAudiofile', 'uploadAudiofile'],
        components: { Processor, UploaderRelease },
        data() {
            return {
                visible: COMPONENTS.UPLOADER
            };
        },
        created() {
            this.enableProcessor();

            this.config.processor.onProcessing = (data) => {
                this.$emit('processing', data);
            };

            this.config.processor.onRemoved = () => {
                this.visible = COMPONENTS.UPLOADER;
                this.$emit('clean');
            };

            this.config.processor.onProcessed = () => {
                this.visible = this.config.file.hasAudio() ?
                    COMPONENTS.PROCESSOR :
                    COMPONENTS.UPLOADER;
            };

            this.config.processor.onUnprocessed = () => {
                window.location.reload();
            };
        },
        methods: {
            cancel() {
                this.config.file.uploader.cancelUpload();
            },
            remove() {
                this.config.processor.remove();
            },
            upload(files) {
                const actual = this.visible;

                this.visible = COMPONENTS.UPLOADER;
                this.config.file.uploader.setBusy();

                this.config.file.upload(files)
                    .then(() => {
                        this.$emit('clean');
                        this.visible = COMPONENTS.PROCESSOR;
                        this.startProcessor();
                    })
                    .catch(() => {
                        this.config.file.uploader.setIdle();
                        this.visible = actual;
                    });
            },
            enableProcessor() {
                if (this.config.file.isProcessing() || this.config.file.isProcessed()) {
                    this.visible = COMPONENTS.PROCESSOR;

                    if (this.config.file.isProcessing()) {
                        this.startProcessor();
                    }
                }
            },
            startProcessor() {
                this.visible = COMPONENTS.PROCESSOR;
                this.config.processor.process();
            }
        },
        computed: {
            isUploaderVisible() {
                return this.visible === COMPONENTS.UPLOADER;
            },
            isProcessorVisible() {
                return this.visible === COMPONENTS.PROCESSOR;
            }
        }
    };
</script>
