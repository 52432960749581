import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '~phoenix/scss/_forms.scss';
import TaxRulesForm from './TaxRulesForm.vue';
import TaxRulesList from './TaxRulesList.vue';

Vue.use(Vuetify, {
    iconfont: 'md'
}, Vuex);

export default {
    /**
     * Load Vue Instance into frontend side
     * @param territories list
     * @param tenant id
     * @returns {{territories: *, tenant: *}}
     */
    load(territories, tenant) {
        return new Vue({
            el: '#taxRulesPage',
            name: 'TaxRules',
            components: {
                Vuetify,
                Vuex,
                TaxRulesForm,
                TaxRulesList
            },
            data() {
                return {
                    territories,
                    tenant
                };
            }
        });
    }
};
