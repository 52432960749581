<style lang="scss">
    .alert-danger {
        color: #FF5722 !important;
        border-color: #FE5621 !important;
    }
    .alert {
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: .25rem;
    }
    .processor {
        position: relative;
        float: left;
        width: 100%;
        padding: 15px 50px;
    }
    .processor__loader {
        position: absolute;
        display: inline-block;
        right: 10px;
        top: 10px;
        width: 32px;
        height: 32px;
        animation: spinner 1s infinite steps(8);
    }
    .processor__files {
        float: left;
        width: 100%;
        margin: 15px 0;
    }
    .processor__controls {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }
    .processor__button {
        display: inline-block;
        border-radius: 3px;
        padding: 8px 12px;
        background: #F5001D;
        font-size: 12px;
        font-weight: 600;
        color: #FFFFFF;
        cursor: pointer;
        clear: both;
    }
</style>

<template>
    <div class="processor">
        <spinner-icon viewBox="0 0 32 32" v-if="processor.isProcessing() || processor.isRemoving()"
                      class="processor__loader"></spinner-icon>
        <alert :message="alert.uploading" v-if="!file.isProcessed()"></alert>
        <div class="processor__files" v-if="file.hasAudio()">
            <audio-file :audio="file.audio.original"
                        v-if="file.hasOriginalAudio()"></audio-file>
            <audio-file :audio="file.audio.full"
                        v-if="file.hasFullAudio()" @play="play"></audio-file>
        </div>
        <div class="processor__controls" v-if="file.isProcessed()">
            <template v-if="uploadAudiofile">
                <input-file
                    :disabled="false"
                    messages=""
                    :text="messages.upload"
                    type="small"
                    @change="upload($event.target.files)">
                </input-file>
            </template>
            <div v-if="deleteAudiofile"
                 class="processor__button"
                 @click="remove"
                 v-html="messages.remove">
            </div>
        </div>
        <template v-if="!deleteAudiofile && !uploadAudiofile">
            <v-alert
                text
                v-bind:class="'mb-3 alert alert-danger error'"
                color="orange lighten-5"
                v-model="messages.cantEditAudioFile"
                :dismissible="false"
            >
              {{messages.cantEditAudioFile}}
            </v-alert>
        </template>
        <template v-else-if="!uploadAudiofile && deleteAudiofile">
            <v-alert
                text
                v-bind:class="'mb-3 alert alert-danger error'"
                color="orange lighten-5"
                v-model="messages.cantUploadAudioFile"
                :dismissible="false"
            >
              {{messages.cantUploadAudioFile}}
            </v-alert>
        </template>
        <template v-else-if="!deleteAudiofile && uploadAudiofile">
          <v-alert
              text
              v-bind:class="'mb-3 alert alert-danger error'"
              color="orange lighten-5"
              v-model="messages.cantDeleteAudioFile"
              :dismissible="false"
          >
            {{messages.cantDeleteAudioFile}}
          </v-alert>
        </template>
    </div>
</template>

<script>
    import Alert from './alert.vue';
    import AudioFile from './audio-file.vue';
    import Bus from '../bus';
    import InputFile from './input-file.vue';
    import Message from '../models/message';
    import { MESSAGE_TYPE } from '../constants/constants';
    import { _T } from '../globals';
    import SpinnerIcon from '../../svg/spinner.svg';

    export default {
        props: ['processor', 'file', 'deleteAudiofile', 'uploadAudiofile'],
        components: {
            Alert,
            AudioFile,
            InputFile,
            SpinnerIcon
        },
        data() {
            return {
                messages: {
                    upload: _T('upload_audio_button'),
                    remove: _T('upload_audio_delete'),
                    confirmRemove: _T('upload_audio_delete_question'),
                    cantEditAudioFile:  _T('disabled_action_audiofile'),
                    cantDeleteAudioFile:  _T('disabled_delete_audiofile'),
                    cantUploadAudioFile: _T('disabled_upload_audiofile')
                },
                alert: {
                    uploading: new Message(_T('upload_processing'), MESSAGE_TYPE.DEFAULT)
                }
            };
        },
        methods: {
            play() {
                Bus.$emit('play.track', this.file);
            },
            upload(files) {
                this.$emit('upload', files);
            },
            remove() {
                if (window.confirm(this.messages.confirmRemove)) {
                    this.$emit('remove');
                }
            }
        }
    };
</script>
