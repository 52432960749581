<template>
    <modal>
        <header slot="header">{{messages.title}}</header>
        <main slot="body" class="static-page content__padded">
            <p>{{messages.text}}</p>
            <p class="center">
                <span v-for="(link, index) in links" :key="index">
                    <a :href="link.href" >{{link.text}}</a>
                    <span v-if="index !== links.length - 1"> | </span>
                </span>
            </p>
        </main>
        <footer slot="footer">
            <input-button :text="messages.close" :no-margin="true" @click="close"></input-button>
        </footer>
    </modal>
</template>

<script>
    import Bus from '~phoenix/js/bus';
    import InputButton from '~phoenix/js/components/form-elements/input-button.vue';
    import Modal from '~phoenix/js/components/modal.vue';
    import Link from '~phoenix/js/types/link';
    import { _T } from '~phoenix/js/globals';

    export default {
        components: {
            InputButton,
            Modal
        },
        data() {
            return {
                messages: {
                    title: _T('terms_end_title'),
                    text: _T('terms_end_text'),
                    close: _T('terms_end_close')
                },
                links: [
                    new Link(_T('terms of service'), '/user/contract'),
                    new Link(_T('privacy policy'), '/about/privacy_policy'),
                    new Link(_T('anti fraud policy'), '/about/anti_fraud')
                ]
            };
        },
        methods: {
            close() {
                Bus.$emit('close.global.modal');
            }
        }
    };
</script>
