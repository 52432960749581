<template>
    <label :for="fieldId" class="input-field__label-container" :class="error_code" :tooltip="hasTooltip"
        :optional="isOptional">
        <div class="text--label input-field__label" v-if="text">
            <span>
                {{ translate(text) }}
            </span>
            <span v-if="isOptional" class="text--label input-field__label--optional">
                {{ optional }}
            </span>
            <template v-if="hasTooltip">
                <div class="btn__tooltip" v-touch:tap="setTapActions" v-on="!mobile ? {
                    mouseenter: hoverTooltip,
                    mouseleave: hoverTooltip
                }
                    : {}">
                    <question-mark role="button" :class="setIconClass" :aria-label="ariaLabel" />
                    <tooltip v-if="isVisible" :messages="tooltip" />
                </div>
            </template>
        </div>
        <slot></slot>
    </label>
</template>

<script>
import QuestionMark from '../../../svg/question-mark.svg'
import Tooltip from '../tooltip.vue'
import Bus from '../../bus';
import { _T } from '../../globals';

export default {
    name: 'InputLabel',
    components: { QuestionMark, Tooltip },
    props: {
        ariaLabel: {
            type: String,
            default: 'Tap or hover for more information'
        },
        fieldId: {
            type: String,
            default: ''
        },
        hasTooltip: {
            type: Boolean,
            default: false
        },
        isOptional: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: ''
        },
        tooltip: {
            type: Array,
            default: () => []
        },
        optional: {
            type: String,
            default: '(optional)'
        },
        error_code: {
            type: String,
            default: ''
        }
    },

    data: () => ({
        isVisible: false,
        mobile: false
    }),

    computed: {
        setIconClass() {
            return this.isVisible ? 'btn__tooltip-icon--active' : 'btn__tooltip-icon'
        }
    },

    mounted() {
        Bus.$on(
            'closeVisible', () => {
                this.closeTooltip()
            }
        )
    },

    methods: {
        closeTooltip() {
            this.isVisible = false
        },
        hoverTooltip() {
            this.isVisible = !this.isVisible
        },
        setTapActions() {
            this.mobile = true
            !this.isVisible ? this.showTooltip() : this.closeTooltip()
        },
        showTooltip() {
            // Close visible tooltip and open the current tooltip tapped
            Bus.$emit('closeVisible')
            this.isVisible = true
        },
        translate(string) {
            return _T(string);
        }
    }
}
</script>

<style lang="scss">
.input-field {
    &__label-container {

        &#audio_upload_label,
        &#track_lyrics_label,
        &#track_notes_label,
        &#startpoint_time_label,
        &#artist_bio_label,
        &#label_description_label {
            flex-flow: row nowrap;
        }

        >.error:not(.input__error) {
            background-image: url('/images/default/error.svg');
            background-size: 18px;
            padding-left: 1.3rem;
            /* padding-top: 16px;
                background-position: left 16px; */
        }

        &#track_explicit_language_label>.error {
            margin-left: 0px;
        }

        #ringtone_label_name+.error {
            margin: 0.25rem 0.25rem 0.25rem 0rem;
        }

        >input.std-input,
        input.std-input.std-textfield,
        input.ui-autocomplete-input.std-input.std-textfield {

            &:focus {
                border: 1px solid #000;
            }

            &#track_startpoint_input {
                width: 120px;
                min-width: 120px;
            }

            #ringtone_label>span,
            #video_label>span {
                display: flex;
            }

            &#ringtone_label_input,
            &#video_label_input {
                width: unset;
                min-width: 100px;
                flex: 1 1 240px;
            }
        }

        input#item_title,
        input#track_title {
            height: 3rem;
            width: 76%;
            font-size: 18px;
        }

        input#artist_name,
        input#label_name {
            height: 3rem;
            width: 100%;
            font-size: 1.25rem;
        }

        input#album_c_year,
        input#album_p_year,
        input#track_p_year,
        input#ringtone_c_year,
        input#ringtone_p_year {
            width: 60px;
            min-width: 60px;
        }

        #isrc,
        #isrc_container {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
        }

        &#audio_upload_label,
        &#track_lyrics_label,
        &#track_notes_label,
        &#artist_bio_label,
        &#label_description_label {
            align-items: flex-start;
        }

        &.label--heading {
            font-size: 18px;
            width: 100%;
        }
    }

    &__checkbox {
        width: 100%;
        flex-flow: row-reverse nowrap;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 0 14rem;

        >.input-field__label {
            min-width: 65%;
            max-width: 100%;
        }

    }

    &__support-text {
        width: 100%;
        padding: 0.75rem 0;

        color: #6D6D6D;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.btn {
    &__tooltip {
        position: relative;
        margin: 0 0 0 0.25rem;
        width: 0.875rem;
        height: 0.875rem;

        // This will increase the tab area for tablet/mobile devices
        &:after {
            content: "";
            position: absolute;
            left: -0.25rem;
            top: -0.25rem;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__tooltip-icon {
        border-radius: 2rem;

        g {
            circle {
                fill: #fff;
            }

            >g {
                circle {
                    fill: #007AFF;
                }

                path {
                    stroke: #007AFF;
                }
            }
        }

        &--active {
            g {
                circle {
                    fill: #007AFF;
                }

                >g {
                    circle {
                        fill: #fff;
                    }

                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
}
</style>
