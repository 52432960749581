import axios from 'axios';
import { API } from '../../constants/constants';
import FormsHelper from '../../helpers/forms';

export default {
    getLogo(id, size) {
        return Promise.resolve(API.CHANNEL_LOGO.replace('[id]', id).replace('[size]', size));
    },

    uploadLogo(id, file) {
        return axios.post(API.CHANNEL_LOGO.replace('[id]', id).replace('[size]', ''), FormsHelper.filesToFormData([file]));
    },

    deleteLogo(id) {
        return axios.delete(API.CHANNEL_LOGO.replace('[id]', id).replace('[size]', ''));
    },

    getIcon(id, size) {
        return Promise.resolve(API.CHANNEL_ICON.replace('[id]', id).replace('[size]', size));
    },

    uploadIcon(id, file) {
        return axios.post(API.CHANNEL_ICON.replace('[id]', id).replace('[size]', ''), FormsHelper.filesToFormData([file]));
    },

    deleteIcon(id) {
        return axios.delete(API.CHANNEL_ICON.replace('[id]', id).replace('[size]', ''));
    }
};
