import axios from 'axios';
import Contract from '~phoenix/js/models/contract';
import { MX2Config } from '~phoenix/js/globals';
import { API, LOCAL_STORAGE } from '../../constants/constants';

export default {

    getUser(userId) {
        return axios.get(API.USER.replace('[id]', userId))
            .then(response => response.data);
    },
    getGlobalContract() {
        const { contract } = MX2Config;

        return new Contract(contract);
    },

    getContract(userUUId) {
        return axios.get(API.USER_CONTRACT.replace('[uuid]', userUUId))
            .then(response => new Contract(response.data));
    },
    showTerms() {
        return axios.get(API.USER_CONTRACT_ONLY, { headers: { 'x-mx2-layout': 'clean' } })
            .then(response => response.data);
    },
    acceptTerms() {
        return axios.get(API.USER_ACCEPT_TERMS).then((response) => {
            let contract = this.getGlobalContract();

            contract = contract.fromVersion(response.data);

            return contract;
        });
    },
    getStoredContract() {
        const contract = localStorage.getItem(LOCAL_STORAGE.ACCEPTED_CONTRACT_VERSION);

        return new Contract(JSON.parse(contract));
    },
    storeContract(contract) {
        localStorage.setItem(LOCAL_STORAGE.ACCEPTED_CONTRACT_VERSION, JSON.stringify(contract));
    }
};
