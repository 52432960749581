<style lang="scss">
#checkout-payment table {
    width: 100%;
}

#checkout-payment h2 {
    margin-bottom: 0.5em;
    margin-top: 1em;
}

#checkout-payment td {
    padding-bottom: 0.5em;
}

#checkout-payment td:first-child {
    width: 32px;
}
</style>

<template>
    <div id="checkout-payment">
        <div v-show="dataLoaded && !showPaymentForm">
            <h2>{{ "choose payment method" | t }}</h2>

            <table>
                <tbody>
                <tr v-if="alreadyPaid" id="already_paid">
                    <td>&nbsp;</td>
                    <td>{{ "amount already paid" | t }}</td>
                    <td class="amount">{{ alreadyPaid | currency(currency.symbol, currency.e) }}</td>
                </tr>

                <template v-if="userBalance > 0">
                    <tr class="payment-from-sales">
                        <td colspan="3">{{ "Payment from available sales" | t }}</td>
                    </tr>
                    <tr>
                        <td><input @change="checkIfExternalCostsRemain" type="checkbox" v-model="payFromSales" name="from_sales" id="from_sales" /></td>
                        <td>
                            <label for="from_sales">
                                {{ "payment from available sales" | t }} ({{ "available from sales" | t }}:
                                {{ userBalance | currency(currency.symbol, currency.e) }})
                            </label>
                        </td>
                        <td class="amount">{{ amountToPayFromSales | currency(currency.symbol, currency.e) }}</td>
                    </tr>
                    <tr :style="termsStyles">
                        <td><input v-model="payFromSalesUnderstood" id="sales_understood" name="sales_understood" type="checkbox" /></td>
                        <td><label for="sales_understood" v-html='_T("I confirm I have understood that the Payment from Available sales will be deducted from my available sales balance")'></label></td>
                        <td>&nbsp;</td>
                    </tr>
                </template>

                <tr>
                    <td colspan="3">{{ "3rd party services" | t }}</td>
                </tr>

                <tr v-for="(method, key) in paymentMethods" class="payment_external" :key="key">
                    <td>
                        <input v-model="paymentMethod" :id="'from_' + key" :value="key" name="payment_method" type="radio" :disabled="disablePaymentMethodChoice" />
                    </td>
                    <td>
                        <label :for="'from_' + key">
                            <img v-if="['paypal', 'stripe'].indexOf(key) !== -1" :src="'/images/thirdparty/payment_method_logo_' +  key + '.png'"
                                 style="width:253px;height:80px;vertical-align:middle;cursor:pointer;" :title="_T(key)" />
                            <span v-else>{{ key | t}}</span>
                        </label>

                        <span v-if="method.minimum_extra > 0">
                            <br />({{ _T("%s transaction costs will be charged for amounts pending below %s", [
                                        currency.symbol + (Number(method.minimum_extra).toFixed(currency.e)),
                                        currency.symbol + (Number(method.minimum_threshold).toFixed(currency.e))
                                    ]) }})
                        </span>
                    </td>
                    <td class="amount">
                        <span v-show="key == paymentMethod">
                            {{ amountToPayExternally | currency(currency.symbol, currency.e) }}
                        </span>
                        <span v-show="key != paymentMethod">
                            {{ 0 | currency(currency.symbol, currency.e) }}
                        </span>
                    </td>
                </tr>

                <tr v-show="currentTransactionCosts">
                    <td>&nbsp;</td>
                    <td>{{ "amount due before transaction costs" | t }}</td>
                    <td class="amount">{{ amount | currency(currency.symbol, currency.e) }}</td>
                </tr>
                <tr v-show="currentTransactionCosts">
                    <td>&nbsp;</td>
                    <td>{{ "extra charge for payment method (incl. taxes)" | t }}</td>
                    <td class="amount">{{ currentTransactionCosts | currency(currency.symbol, currency.e) }}</td>
                </tr>

                <tr>
                    <td>&nbsp;</td>
                    <td>{{ "total amount due" | t }}</td>
                    <td class="amount">{{ amountWithTransactionCosts | currency(currency.symbol, currency.e) }}</td>
                </tr>
                </tbody>
            </table>

            <button v-show="showPaymentButton" @click="goToPayment">{{ "pay" | t }}</button>
        </div>

        <div v-show="dataLoaded && showPaymentForm">
            <div v-if="paymentMethod == 'paypal'">
                <h1>{{ "payment with paypal" | t }}</h1>
                <p>{{ "payment will stay pending until paypal confirmation" | t }}</p>
                <p>{{ "you will be redirected to paypal" | t }}</p>
                <p>{{ "albums will be distributed when payment is completed" | t }}</p>

                <form :action="buttonData.form_action" method="post" name="paypalform" target="_self">
                    <input type="hidden" name="cmd" value="_xclick" />
                    <input type="hidden" name="shipping" value="0">

                    <input v-for="(config, key) in buttonData.config" type="hidden" :key="key" :name="key" :value='config' />

                    <input v-for="(userConfig, key) in buttonData.user_config" type="hidden" :key="key" :name="key" :value='userConfig' />

                    <label for="email">{{ "paypal account to use" | t }}</label>
                    <input type="text" name="email" size="75" id="email" :value="buttonData.user_paypal_email" /><br /><br />

                    <input type="image" name="submit" :src="buttonData.paypal_button" />
                </form>
            </div>

            <div v-if="paymentMethod == 'stripe'">
                <h1>{{ "payment with stripe" | t }}</h1>
                <p>{{ "payment will stay pending until stripe confirmation" | t }}</p>
                <p>{{ "you will be redirected to stripe" | t }}</p>
                <p>{{ "albums will be distributed when payment is completed" | t }}</p>

                <form @submit="stripePaymentSubmit" :action="'/pay_stripe/pay/' + buttonData.invoice_id + '/' + buttonData.payment_id" method="POST">
                    <button type="submit" class="std-button ui-state-default ui-corner-all">Pay with Card</button>
                    <p v-if="showError">We couldn't process payment at this moment, can you try in a few minutes</p>
                </form>
            </div>

            <div v-if="paymentMethod == 'test'">
                <h1>{{ "test payment" | t }}</h1>

                <form :action="'/pay_test/execute/' + buttonData.invoice_id + '/' + buttonData.payment_id" method="POST">
                    <input type="radio" name="option" value="confirmation" id="confirmation" checked="checked" /> <label for="confirmation">Payment Confirmation</label><br />
                    <input type="radio" name="option" value="not_completed" id="not_completed" /> <label for="not_completed">Payment not completed</label><br />
                    <input type="radio" name="option" value="failed" id="failed" /> <label for="failed">Payment failed</label><br />
                    <button type="submit" class="std-button ui-state-default ui-corner-all">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from '../mixins/filters';
    import Methods from '../mixins/methods';
    import { _T, $, startSpin, endSpin, StripeCheckout } from '../globals';

    export default {
        mixins: [Filters, Methods],
        props: {
            orderId: {
                // 0 represents the user's current order
                type: Number,
                default: 0
            }
        },
        data: function() {
            return {
                // Fetched from the API
                alreadyPaid: 0,
                amount: 0,
                currency: {
                    symbol: null,
                    e: null
                },
                order: null,
                paymentMethods: null,
                transactionCosts: null,
                minimumThresholds: null,
                userBalance: 0,

                // Models from forms
                paymentMethod: null,
                payFromSales: false,
                payFromSalesUnderstood: false,

                dataLoaded: false,
                showPaymentForm: false,
                showPaymentButton: true,
                termsStyles: {
                    backgroundColor: ''
                },
                disablePaymentMethodChoice: false,
                buttonData: {},
                showError: false
            };
        },
        computed: {
            amountToPayExternally: function() {
                return Math.max(0, this.amountWithTransactionCosts - this.alreadyPaid - (this.payFromSales ? this.userBalance : 0));
            },
            amountToPayFromSales: function() {
                return this.payFromSales ? Math.min(this.userBalance, this.amount) : 0;
            },
            allTermsAgreed: function() {
                return (
                    (!this.payFromSales || (this.payFromSales && this.payFromSalesUnderstood))
                );
            },
            currentTransactionCosts: function() {
                //Check if the tot amount is payed all with PayFromSales method and if there is not any amount already payed.
                if (this.isFullPayedWithPayFromSales && this.isAlreadyPayedEmpty) {
                    return 0;
                }

                if (this.paymentMethod) {
                    //Check if the minimum threshold value is greater or equal to the rest of amount (Paypal or Stripe methods).
                    if (this.minimumThresholds[this.paymentMethod] >= this.getRestOfAmountToPayed) {
                        return parseFloat(this.transactionCosts[this.paymentMethod]);
                    } else {
                        return 0;
                    }
                }

                return 0;
            },
            getRestOfAmountToPayed: function () {
                //Check if there is something already payed.
                if (this.amountToPayFromSales == 0) {
                    return this.amount - this.alreadyPaid
                }
                //Get the rest of amount to payed.
                return this.amount - this.amountToPayFromSales
            },
            isFullPayedWithPayFromSales: function () {
                return this.amount - this.amountToPayFromSales == 0;
            },
            isAlreadyPayedEmpty: function () {
                return this.alreadyPaid == 0;
            },
            amountWithTransactionCosts: function() {
                return parseFloat(this.amount + this.currentTransactionCosts);
            }
        },
        created: function() {
            var that = this;
            $.get('/components/checkoutpayment/' + (this.orderId === 0 ? '0' : this.orderId), function(data) {
                that.alreadyPaid = data.alreadyPaid;
                that.amount = Number(data.amount);
                that.currency = data.currency;
                that.order = data.order;
                that.paymentMethods = data.paymentMethods;
                that.transactionCosts = data.transactionCosts;
                that.minimumThresholds = data.minimumThresholds;
                that.userBalance = Number(data.userBalance);

                that.paymentMethod = Object.keys(data.paymentMethods).find((m) => {
                    return data.paymentMethods[m].default === true
                });

                that.dataLoaded = true;
            }).fail(function() {});
        },
        methods: {
            goToPayment: function() {
                this.termsStyles.backgroundColor = '';
                if (!this.allTermsAgreed && this.isFullPayedWithPayFromSales) {
                    alert(_T('You must agree to all terms'));
                    this.termsStyles.backgroundColor = 'red';
                    return;
                }
                
                if(!this.paymentMethod && !this.isFullPayedWithPayFromSales) {
                    alert(_T('You must select one payment method'));
                    return;
                }

                startSpin();
                this.showPaymentButton = false;

                var postData = {
                    payFromSales: this.payFromSales,
                    paymentMethod: this.paymentMethod
                };

                var that = this;
                $.post('/components/checkoutpayment/initiate/' + (this.orderId === 0 ? '0' : this.orderId), postData, function(data) {
                    if(data.action == 'ACTION_PAYMENT_BUTTON') {
                        that.buttonData = data.buttonData;
                        that.showPaymentForm = true;
                    } else {
                        var redirectLocation;
                        if(data.action == 'ACTION_REDIRECT_ZERO_COST') {
                            redirectLocation = '/payments/confirmation';
                        } else if(data.action == 'ACTION_REDIRECT_PAID_FROM_SALES') {
                            redirectLocation = '/payments/confirmation/' + data.invoiceId;
                        }

                        window.location = redirectLocation;
                    }

                    endSpin();
                }).fail(function() {});
            },
            stripePaymentSubmit: function(event) {
                event.preventDefault();

                this.showError = false
                var stripe = Stripe(this.buttonData.stripe);
                stripe.redirectToCheckout({
                    sessionId: this.buttonData.session
                }).then(function (result) {
                    this.showError = true
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                });
            },
            checkIfExternalCostsRemain: function() {
                this.disablePaymentMethodChoice = this.payFromSales && this.amountToPayExternally === 0;
            }
        }
    };
</script>
