import AudioFile from './audio-file';
import RingtoneTokenizer from '../tools/ringtone-tokenizer';
import RingtoneUploader from '../tools/ringtone-uploader';
import Bus from '../bus';
import { URLS } from '../constants/constants';

export default class Ringtone extends AudioFile {
    constructor(data) {
        super({
            id: data.id,
            title: data.title,
            audio: data.audio,
            uploader: new RingtoneUploader({ id: data.id })
        });

        this.link = URLS.RINGTONE.replace('[id]', data.id);
    }

    upload(files) {
        return new Promise((resolve, reject) => {
            new RingtoneTokenizer(this.id).getToken(files).then((data) => {
                this.uploader.addToken(data.data[0].auth_code);
                this.uploader.addFile(files[0]);
                Bus.$emit('add.uploader', this.uploader);
                this.uploader.upload().then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }
}
