import { extent as d3Extent, max as d3Max } from 'd3-array';
import { axisLeft as d3AxisLeft, axisBottom as d3AxisBottom } from 'd3-axis';
import { scaleTime as d3ScaleTime, scaleLinear as d3ScaleLinear } from 'd3-scale';
import { select as d3Selection } from 'd3-selection';
import { timeFormat as d3TimeFormat } from 'd3-time-format';
import { line as d3Line } from 'd3-shape';
import numbers from '../helpers/numbers';

export default class LineChart {
    constructor(currency) {
        this.width = 870;
        this.height = 340;
        this.margin = {
            top: 20,
            right: 30,
            bottom: 40,
            left: 60
        };
        this.x = d3ScaleTime().rangeRound([0, this.width]);
        this.y = d3ScaleLinear().rangeRound([this.height, 0]);
        this.currency = currency;
        this.total = 0;

        this.onMouseOver = () => {};
        this.onMouseOut = () => {};
        this.mouseOver = (tick) => { this.onMouseOver(tick); };
        this.mouseOut = (tick) => { this.onMouseOut(tick); };
    }

    build(container) {
        this.svg = this.createSVG(container);
    }

    update(data) {
        this.clean();
        this.createAxis(data.parsed, data.points);
        this.createLines(data.nested);
    }

    updateTotals(data) {
        this.clean();
        this.createAxis(data.totals, data.points);
        this.createLine(data.totals, data.totals[0].color);
    }

    clean() {
        this.svg.selectAll('*').remove();
    }

    createSVG(container) {
        return d3Selection(container)
            .attr('width', this.width + this.margin.left + this.margin.right)
            .attr('height', this.height + this.margin.top + this.margin.bottom)
            .append('g')
            .attr('class', 'main')
            .attr(
                'transform',
                `translate(${this.margin.left},${this.margin.top})`
            );
    }

    createAxis(data, points) {
        this.x.domain(d3Extent(data, d => d.date));
        this.y.domain([0, d3Max(data, d => d.value)]);

        this.svg.append('g')
            .attr('transform', `translate(0,${this.height})`)
            .call(d3AxisBottom(this.x).ticks(points.length).tickFormat(d3TimeFormat('%m/%Y')));

        this.svg.append('g').call(d3AxisLeft(this.y));
    }

    createLines(data) {
        data.forEach((d) => {
            this.createLine(d.values, d.color);
        });
    }

    createLine(data, color) {
        const group = this.svg.append('g');

        group.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1.5)
            .attr('d', this.line());

        data.forEach((tick) => {
            const newTick = {
                color,
                text: `${tick.name} - ${numbers.round(tick.value, 2)} ${this.currency.symbol}`,
                left: this.x(tick.date) + this.margin.left + 10,
                top: this.y(tick.value) + this.margin.top + 10
            };

            group.append('circle')
                .attr('r', 4)
                .attr('fill', '#FFFFFF')
                .attr('stroke', color)
                .attr(
                    'transform',
                    `translate(${this.x(tick.date)},${this.y(tick.value)})`
                )
                .on('mouseout', () => { this.mouseOut(); })
                .on('mouseover', () => { this.mouseOver(newTick); });
        });
    }

    line() {
        return d3Line()
            .x(d => this.x(d.date))
            .y(d => this.y(d.value));
    }
}
