<template>
  <div>
    <div class="cover-upload" style="padding: 0; margin-bottom: 16px">
      <input-label for="image-upload" :text="translate(label)" :is-optional="false" error_code="RELEASE_HAS_NO_COVER">
        <div class="col-7">
          <div class="col-1" style="align-self: flex-start">
            <drag-drop :isLoading="isLoading" component="image-upload" v-on="editCover ? { dropFile: dropFile } : {}">
              <image-upload size="240x240" :cover="true" :delete-cover="deleteCover" :edit-cover="editCover"
                :originalSrc="coverOriginalSrc" :src="coverSrc" :upload-image="translate('cover_drag_drop')"
                @click="onClick" @change="onChange" @onDelete="onDelete" />
            </drag-drop>
          </div>

          <div class="cover-upload__requirements col-6" style="padding: 0; align-self: flex-start">
            <div class="input__instruction input__instruction--bold">
              {{ translate(rulesTitle) }}
            </div>
            <ul class="input__instruction">
              <li v-for="(item, key) in rules" :key="key" class="cover-upload__requirements-list-item">
                {{ translate(item) }}
              </li>
            </ul>
            <div class="input__instruction">
              <span class="input__instruction input__instruction--bold">
                {{ translate(highlight_1) }}
              </span>
              {{ translate(warn_1) }}
            </div>
            <div class="input__instruction">
              <span class="input__instruction input__instruction--bold">
                {{ translate(highlight_2) }}
              </span>
              {{ translate(warn_2) }}
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="error input__error" style="display: none; margin: 0; margin-top: 10px"></div>

          <div v-if="errors.length" style="margin: 0; margin-top: 10px">
            <div class="input__error">
              {{ translate("upload_failed") }}
            </div>

            <div v-for="(error, index) in errors" :key="index" class="input__error">
              {{ error }}
            </div>
          </div>
        </div>
      </input-label>
    </div>
  </div>
</template>

<script>
import DragDrop from './drag-drop.vue';
import ImageUpload from './image-upload.vue';
import InputLabel from './form-elements/input-label.vue';

import ErrorsHelper from '../helpers/errors';
import Polling from '../helpers/polling';
import { _T } from '../globals';

export default {
    name: 'CoverUploadRelease',
    components: { DragDrop, ImageUpload, InputLabel },
    props: {
        model: {
            type: Object,
            default: () => { }
        },
        label: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        rulesTitle: {
            type: String,
            default: ''
        },
        highlight_1: {
            type: String,
            default: ''
        },
        highlight_2: {
            type: String,
            default: ''
        },
        warn_1: {
            type: String,
            default: ''
        },
        warn_2: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            coverSrc: this.getCover(350),
            coverOriginalSrc: this.getCover('original'),
            deleteCover: this.model.deleteCover,
            editCover: this.model.editCover,
            errors: [],
            isLoading: false
        };
    },
    methods: {
        getCover(param) {
            return this.model.cover.length !== 0
                ? this.model.cover[param].href
                : null;
        },
        onClick() {
            this.errors = [];
        },
        dropFile($event) {
            this.isLoading = true;
            const files = [...$event.dataTransfer.items].map((item) =>
                item.getAsFile()
            );
            this.uploadCover(files[0]);
        },
        onChange($event) {
            this.isLoading = true;
            this.uploadCover($event.target.files[0]);
        },
        uploadCover(files) {
            this.model.repository
                .uploadCover(this.model.uuid, files)
                .then(() => {
                    this.model.repository.getAllCovers(this.model.uuid).then((src) => {
                        Polling.pollFile(src[350].href).then(() => {
                            this.coverSrc = src[350].href;
                            this.coverOriginalSrc = src.original.href;
                            this.isLoading = false;
                        });
                    });
                })
                .catch((error) => {
                    this.errors = ErrorsHelper.toArray(error);
                    this.isLoading = false;
                });
        },
        onDelete($event) {
            this.isLoading = true;
            this.model.repository
                .deleteCover(this.model.uuid)
                .then(this.clean)
                .catch(this.clean);
            $event.preventDefault();
        },
        clean() {
            this.coverSrc = null;
            this.coverOriginalSrc = null;
            this.isLoading = false;
        },
        translate(string) {
            return _T(string);
        }
    }
};
</script>

<style lang="scss">
.cover-upload {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 0 0.75rem 0;
  width: 100%;
  flex-direction: column;

  >.input-field {
    &__label-container {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  &__requirements {
    padding: 1rem;
    padding-left: 0;
  }

  &__requirements-title {
    margin-bottom: 0.25rem;
    line-height: 1.225rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  &__requirements-list {
    line-height: 1.225rem;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  &__requirements-list-item:before {
    content: "\2022";
    margin: 0 0.25rem 0 0;
    line-height: 1;
    font-size: 1.25rem;
    font-weight: 400;
  }

  &__requirements-paragraph {
    margin: 0.5rem 0 0 0;
    line-height: 1rem;
    font-size: 0.75rem;

    &--bold {
      font-weight: 500;
    }
  }

  &__error-container {
    background-image: url("/images/default/error.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 14px;
    margin: 0.5rem 0 0.5rem 13.875rem;
    color: #f03d3d;
  }

  &__error {
    display: block;
    line-height: 16.8px;
    font-size: 12px;

    &--bold {
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      font-weight: 600;
      padding-left: 1.3rem;
    }
  }
}
</style>
